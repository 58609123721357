import React from 'react';

import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Loading from '../Loading/Loading';
import Toast from '../Toast/Toast';

const WidgetSurveyAvailable = () => {
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		surveyList: [],
	});

	const handleSurveySubmit = (event) => {
		event.preventDefault();

		function getData(form) {
			const formData = new FormData(form);

			// iterate through entries...
			// eslint-disable-next-line no-restricted-syntax
			// for (const pair of formData.entries()) {
			// 	console.log(`${pair[0]}: ${pair[1]}`);
			// }

			// ...or output as an object
			return Object.fromEntries(formData).surveyAnswer;
		}

		const choiceId = getData(event.target);

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_SURVEY_URL}/saveSurveyAnswer`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...(state.id && { id: state.id }),
				surveyChoiceId: choiceId,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						isSurveyAnswered: true,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_SURVEY_URL}/getSurveyHomeForAnswer`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data?.content) {
					setState({
						...state,
						showLoading: false,
						surveyList: response.data.content,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						isSurveyAnswered: true,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});

		setTimeout(() => {
			// eslint-disable-next-line
			const sliderSurvey = new window.Swiper('.sliderSurvey', {
				loop: false,
				autoplay: {
					delay: 5000,
					disableOnInteraction: true,
					pauseOnMouseEnter: true,
				},
				pagination: {
					el: '.sliderSurvey .swiper-pagination',
					clickable: true,
				},
			});
		}, 200);
	}, []);
	return (
		<>
			<div className="rounded-2 flex-fill d-flex flex-column">
				<div className="rounded-2 swiper sliderSurvey w-100 h-100">
					<div className="swiper-wrapper">
						{state.surveyList &&
							state.surveyList.map((item) => (
								<div className="swiper-slide" key={item.id}>
									<form
										onSubmit={(event) =>
											handleSurveySubmit(event)
										}
									>
										{item.surveyQuestionDTOList?.length >
											0 &&
											item.surveyQuestionDTOList.map(
												(question) => (
													<div key={question.id}>
														<div className="d-flex flex-column">
															<p className="mb-4">
																{question.title}
															</p>
														</div>
														<div className="form-check mb-2">
															<div>
																{question?.surveyChoiceDTOList?.map(
																	(
																		choice
																	) => (
																		<div
																			key={
																				choice.id
																			}
																		>
																			<input
																				// eslint-disable-next-line no-undef
																				id={`option${choice.id}`}
																				className="form-check-input"
																				type="radio"
																				name="surveyAnswer"
																				value={
																					choice.id
																				}
																			/>

																			<label
																				className="form-check-label text-gray-light"
																				htmlFor={`option${choice.id}`}
																			>
																				{choice.description &&
																					choice.description}
																			</label>
																		</div>
																	)
																)}
															</div>
														</div>
													</div>
												)
											)}

										<div className="text-center mt-4">
											<button
												type="submit"
												className="btn border border-2 border-orange text-orange rounded-pill px-3 py-1 fs-7 me-sm-2 me-0"
											>
												Gönder
											</button>
										</div>
									</form>
								</div>
							))}
					</div>
					<div className="swiper-pagination swiper-pagination-white position-absolute bottom-0 start-0" />
				</div>
			</div>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

const WidgetSurveyUnavailable = () => (
	<div className="flex-fill bg-white rounded-2 d-flex flex-column justify-content-center align-items-center text-green text-center p-4">
		<i className="far fa-smile h1 mb-3" />
		<h6 className="mb-0 lh-base">
			Katılımın için <br />
			teşekkürler.
		</h6>
	</div>
);

const WidgetSurvey = () => {
	const [context] = React.useContext(Context);

	return (
		<div className="h-100 p-4 bg-gray-lightest rounded-2 d-flex flex-column">
			<h5 className="mb-4 text-gray">Anket</h5>

			{context.isSurveyAnswered ? (
				<WidgetSurveyUnavailable />
			) : (
				<WidgetSurveyAvailable />
			)}
		</div>
	);
};

export default WidgetSurvey;
