import React from 'react';
import { Navigate } from 'react-router-dom';

import Context from '../../stores/Context/Context';

const AdminProtectedRoute = (props) => {
	const [context] = React.useContext(Context);

	return context?.dataLogin?.userRole === 'admin' ? (
		props.children
	) : (
		<Navigate to="/giris" replace />
	);
};

export default AdminProtectedRoute;
