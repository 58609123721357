import React from 'react';
import { Outlet } from 'react-router-dom';

import ProtectedRoute from '../../routes/ProtectedRoute/ProtectedRoute';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Layout = () => (
	<ProtectedRoute>
		<Header />
		<Outlet />
		<Footer />
	</ProtectedRoute>
);

export default Layout;
