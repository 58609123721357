import React from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';

const Disclaimer = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		showLoading: false,
	});
	const [disclaimerText, setDisclaimerText] = React.useState(null);
	const navigate = useNavigate();

	// useEffect
	React.useEffect(() => {
		if (!context?.dataLogin?.token) {
			navigate('/giris');
		}

		if (
			context?.dataLogin?.token &&
			context?.dataLogin?.disclaimerChecked === true &&
			context?.dataUser?.userType !== 'ADMIN'
		) {
			navigate('/');
		}

		if (
			context?.dataLogin?.token &&
			context?.dataUser?.userType === 'ADMIN'
		) {
			navigate('/admin');
		}
	}, []);

	const GetDisclaimer = () => {
		fetch(`${constants.API_BASE_URL}/public/getDisclaimer`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response.data) {
					setDisclaimerText(response.data);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		GetDisclaimer();
	}, []);

	React.useEffect(() => {
		if (disclaimerText) {
			setContext({
				...context,
				disclaimerText,
			});
		}
	}, [disclaimerText]);

	const handleAccept = (e) => {
		e.preventDefault();
		setState({
			...state,
			showLoading: true,
		});
		fetch(`${constants.API_BASE_URL}/saveDisclaimer`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						dataLogin: {
							...context.dataLogin,
							disclaimerChecked: true,
						},
					});

					localStorage.setItem(
						'context',
						JSON.stringify({
							...context,
							dataLogin: {
								...context.dataLogin,
								disclaimerChecked: true,
							},
						})
					);

					navigate('/');
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const logout = (event) => {
		event.preventDefault();
		localStorage.setItem('context', JSON.stringify({}));
		setContext({});
		navigate('/giris');
	};

	return (
		<main>
			<Modal
				showModal="true"
				header="Kullanım Koşulları & Gizlilik Sözleşmesi"
				size="lg"
				onClose={(e) => logout(e)}
			>
				<div className="modal-body p-4">
					<div
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: context?.disclaimerText?.description,
						}}
					/>
				</div>

				<div className="modal-footer p-4 text-center justify-content-center">
					<button
						type="button"
						className="btn btn-orange text-white rounded-pill px-4 py-2 ms-md-3 ms-0 flex-md-grow-0 flex-fill"
						onClick={(e) => handleAccept(e)}
					>
						Okudum, anladım kabul ediyorum
					</button>
				</div>
			</Modal>
		</main>
	);
};

export default Disclaimer;
