/*eslint-disable*/
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import constants from '../../constants';
import Context from '../../stores/Context/Context';

import WidgetAdminAlerts from '../../components/WidgetAdminAlerts/WidgetAdminAlerts';
import WidgetAdminCalendar from '../../components/WidgetAdminCalendar/WidgetAdminCalendar';
import WidgetAdminCriticalNGO from '../../components/WidgetAdminCriticalNGO/WidgetAdminCriticalNGO';
import WidgetAdminCriticalParticipation from '../../components/WidgetAdminCriticalParticipation/WidgetAdminCriticalParticipation';
import WidgetAdminCriticalScholarship from '../../components/WidgetAdminCriticalScholarship/WidgetAdminCriticalScholarship';
import WidgetAdminSliderHero from '../../components/WidgetAdminSliderHero/WidgetAdminSliderHero';
import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';
import WidgetAdminWaitingApprovals from '../../components/WidgetAdminWaitingApprovals/WidgetAdminWaitingApprovals';

const AdminDashboardCharts = () => {
	const [context] = React.useContext(Context);
	const [stats, setStats] = React.useState();

	ChartJS.register(ArcElement, Tooltip, Legend);

	React.useEffect(() => {
		fetch(`${constants.API_BASE_URL}/admin/chartStatistics`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setStats(response.data);
				}
			});
	}, []);

	return (
		stats && (
			<>
				<div className="col-xl-4 col-md-6">
					<div className="bg-gray-lightest rounded-2 h-100 p-4 d-flex flex-column">
						<h5 className="mb-4">Öğrenciler</h5>
						<Doughnut
							data={{
								labels: [
									'Aktif',
									'Pasif',
									'Askıda',
									'Mezun',
									'Kesilen',
									'Devam Eden',
								],
								datasets: [
									{
										label: ' Öğrenci Sayısı',
										data: [
											stats.chartMenteeDTO.activeCount,
											stats.chartMenteeDTO.passiveCount,
											stats.chartMenteeDTO.hangingCount,
											stats.chartMenteeDTO.graduateCount,
											stats.chartMenteeDTO.cutoffCount,
											stats.chartMenteeDTO
												.continuingCount,
										],
										backgroundColor: [
											'rgba(101, 163, 13, 1)',
											'rgba(220, 38, 38, 1)',
											'rgba(251, 191, 36, 1)',
											'rgba(21, 101, 192, 1)',
											'rgba(0, 0, 0, 0.25)',
											'rgba(0, 0, 0, 0.5)',
										],
										borderWidth: 1,
									},
								],
							}}
							options={{
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
						/>
						<div className="row row-cols-2 mt-3 fs-7 gx-5 gy-2">
							<div className="col d-flex justify-content-between align-items-center text-green">
								Aktif{' '}
								<span className="fw-medium">
									{stats.chartMenteeDTO.activeCount}
								</span>
							</div>
							<div className="col d-flex justify-content-between align-items-center text-red">
								Pasif{' '}
								<span className="fw-medium">
									{stats.chartMenteeDTO.passiveCount}
								</span>
							</div>
							<div className="col d-flex justify-content-between align-items-center text-orange">
								Askıda{' '}
								<span className="fw-medium">
									{stats.chartMenteeDTO.hangingCount}
								</span>
							</div>
							<div className="col d-flex justify-content-between align-items-center text-gray">
								Mezun{' '}
								<span className="fw-medium">
									{stats.chartMenteeDTO.graduateCount}
								</span>
							</div>
							<div className="col d-flex justify-content-between align-items-center text-gray">
								Kesilen{' '}
								<span className="fw-medium">
									{stats.chartMenteeDTO.cutoffCount}
								</span>
							</div>
							<div className="col d-flex justify-content-between align-items-center text-gray">
								Devam Eden{' '}
								<span className="fw-medium">
									{stats.chartMenteeDTO.continuingCount}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-4 col-md-6">
					<div className="bg-gray-lightest rounded-2 h-100 p-4 d-flex flex-column">
						<h5 className="mb-4">Mentorlar</h5>
						<Doughnut
							data={{
								labels: ['Aktif', 'Pasif', 'Havuz'],
								datasets: [
									{
										label: ' Mentor Sayısı',
										data: [
											stats.chartMentorDTO.activeCount,
											stats.chartMentorDTO.passiveCount,
											stats.chartMentorDTO.poolCount,
										],
										backgroundColor: [
											'rgba(101, 163, 13, 1)',
											'rgba(220, 38, 38, 1)',
											'rgba(251, 191, 36, 1)',
										],
										borderWidth: 1,
									},
								],
							}}
							options={{
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
						/>
						<div className="row row-cols-2 mt-3 fs-7 gx-5 gy-2">
							<div className="col d-flex justify-content-between align-items-center text-green">
								Aktif{' '}
								<span className="fw-medium">
									{stats.chartMentorDTO.activeCount}
								</span>
							</div>
							<div className="col d-flex justify-content-between align-items-center text-red">
								Pasif{' '}
								<span className="fw-medium">
									{stats.chartMentorDTO.passiveCount}
								</span>
							</div>
							<div className="col d-flex justify-content-between align-items-center text-orange">
								Havuz{' '}
								<span className="fw-medium">
									{stats.chartMentorDTO.poolCount}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-4 col-md-6">
					<div className="bg-gray-lightest rounded-2 h-100 p-4 d-flex flex-column">
						<h5 className="mb-4">Programlar</h5>
						<Doughnut
							data={{
								labels: [
									...stats.programList.map(
										(item) => item.program
									),
								],
								datasets: [
									{
										label: ' Program Öğrenci Sayısı',
										data: [
											...stats.programList.map(
												(item) => item.count
											),
										],
										backgroundColor: [
											...stats.programList.map(
												(item) => item.colorCode
											),
										],
										borderWidth: 1,
									},
								],
							}}
							options={{
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
						/>
						<div className="row row-cols-1 mt-3 fs-7 gx-5 gy-2">
							{stats.programList.map((item) => (
								<div key={item.program} className="col d-flex justify-content-between align-items-center text-gray">
									{item.program}
									<span className="fw-medium">
										{item.count}
									</span>
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		)
	);
};

const AdminDashboard = () => {
	const [state, setState] = React.useState(null);
	const [context, setContext] = React.useContext(Context); //eslint-disable-line

	React.useEffect(() => {
		fetch(`${constants.API_BASE_URL}/admin/getAllCriticalUsers`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState(response.data);
				}
			});
	}, []);

	return (
		<>
			<WidgetAdminTitleBar title="Yönetim Paneli" />

			<div className="row g-4">
				<div className="col-xl-8">
					<WidgetAdminSliderHero />
				</div>

				<div className="col-xl-4 col-md-6">
					<WidgetAdminWaitingApprovals />
				</div>

				<div className="col-xl-4 col-md-6">
					<WidgetAdminCriticalScholarship
						data={
							state?.bursaryCriticalDTOList
								? state.bursaryCriticalDTOList
								: null
						}
						count={state?.bursaryCount ? state.bursaryCount : 0}
					/>
				</div>

				<div className="col-xl-4 col-md-6">
					<WidgetAdminCriticalParticipation
						data={
							state?.activityCriticalDTOList
								? state.activityCriticalDTOList
								: null
						}
						count={state?.activityCount ? state.activityCount : 0}
					/>
				</div>

				<div className="col-xl-4 col-md-6">
					<WidgetAdminCriticalNGO
						data={
							state?.stkCriticalDTOList
								? state.stkCriticalDTOList
								: null
						}
						count={state?.stkCount ? state.stkCount : 0}
					/>
				</div>

				<AdminDashboardCharts />

				<div className="col-xl-8">
					<WidgetAdminCalendar />
				</div>

				<div className="col-xl-4">
					<WidgetAdminAlerts />
				</div>
			</div>
		</>
	);
};

export default AdminDashboard;
