import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import trLocale from '@fullcalendar/core/locales/tr';
import moment from 'moment';
import { Tooltip } from 'bootstrap';
import constants from '../../constants';
import Context from '../../stores/Context/Context';

import './Calendar.scss';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

let tooltipInstance = null;

const Calendar = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		showLoading: false,
		activities: null,
		activitiesEvents: null,
		weekendsVisible: true,
		currentEvents: [],
	});
	const { userRole } = context.dataLogin;

	const handleEventClick = (info) => {
		info.jsEvent.preventDefault();

		if (info.event.url) {
			fetch(`${constants.API_BASE_URL}/saveMenteeActivity`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify(info.event.id),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						setState({
							...state,
							showLoading: false,
						});
						window.open(info.event.url);
					} else {
						setState({
							...state,
							showLoading: false,
							toast: {
								type: 'error',
								message: response.description
									? response.description
									: 'Lütfen daha sonra tekrar deneyin.',
							},
						});
					}
				})
				.catch((error) => {
					setState({
						...state,
						toast: {
							type: 'error',
							message:
								error.message === 'Failed to fetch'
									? 'Sunucu ile bağlantı kurulamadı.'
									: error.message,
						},
						showLoading: false,
					});
				});
		}
	};

	// FullCalendar
	const handleMouseEnter = (info) => {
		if (info.event.extendedProps.description) {
			tooltipInstance = new Tooltip(info.el, {
				title: info.event.extendedProps.description,
				html: true,
				placement: 'top',
				trigger: 'hover',
				container: 'body',
			});

			tooltipInstance.show();
		}
	};

	const handleMouseLeave = () => {
		if (tooltipInstance) {
			tooltipInstance.dispose();
			tooltipInstance = null;
		}
	};

	// Get Activities
	const getActivities = (currentUser) => {
		setState({ ...state, showLoading: true });
		let apiUrl = null;

		if (userRole === 'mentor') {
			if (currentUser) {
				apiUrl = '/activities';
			}
		}

		if (userRole === 'mentee') {
			if (currentUser) {
				apiUrl = `/mentee/${currentUser}/activities/has`;
			}
		}

		fetch(`${constants.API_BASE_URL}${apiUrl}?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				let activities;

				if (userRole === 'mentor') {
					activities = response.content;
				}

				if (userRole === 'mentee') {
					activities = response.data;
				}

				if (response && activities) {
					const activitiesEvents = [];

					activities.forEach((element) => {
						activitiesEvents.push({
							id: element.id,
							title: element.name,
							start: moment(element.dateTimeBegin).format(
								'YYYY-MM-DD HH:mm'
							),
							end: moment(element.dateTimeEnd).format(
								'YYYY-MM-DD HH:mm'
							),
							url: element.url,
							extendedProps: {
								description: `${moment(
									element.dateTimeBegin
								).format('YYYY-MM-DD HH:mm')}<br />${
									element.name
								}<br />${element.description}`,
							},
						});
					});

					setState({
						...state,
						showLoading: false,
						activities,
						activitiesEvents,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		if (context?.dataUser.id) {
			getActivities(context.dataUser.id);
		}
	}, []);

	return (
		<main>
			<div className="container">
				<div className="row justify-content-center gy-5">
					<div className="col-xl-12 col-lg-12">
						<div className="d-flex align-items-center mb-5">
							<h1 className="mb-0 text-blue-light">Takvim</h1>
						</div>

						<div className="pe-xl-0 pe-lg-5 pe-0">
							<div className="text-center">
								<FullCalendar
									plugins={[dayGridPlugin]}
									themeSystem="bootstrap"
									initialView="dayGridMonth"
									weekends
									locales={trLocale}
									locale="tr"
									events={state.activitiesEvents}
									eventMouseEnter={handleMouseEnter}
									eventMouseLeave={handleMouseLeave}
									eventClick={handleEventClick}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</main>
	);
};

export default Calendar;
