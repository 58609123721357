import React from 'react';

import { Tooltip } from 'bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

import { tinymceApiKey } from '../../config/api.config';

const AdminContentsStatics = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		title: '',
		description: '',
		url: '',
		icon: '',
		tinymceInit: {
			height: 500,
			menubar: true,
			plugins: [
				'advlist',
				'autolink',
				'lists',
				'link',
				'image',
				'charmap',
				'preview',
				'anchor',
				'searchreplace',
				'visualblocks',
				'code',
				'fullscreen',
				'insertdatetime',
				'media',
				'table',
				'code',
				'help',
				'wordcount',
			],
			toolbar:
				'undo redo | blocks | ' +
				'bold italic forecolor | alignleft aligncenter ' +
				'alignright alignjustify | bullist numlist outdent indent | ' +
				'removeformat | help',
			content_style:
				'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
			branding: false,
		},
	});

	const [homeBanner, setHomeBanner] = React.useState(null);

	// Default Get Homage Banner
	const GetHomeBanner = () => {
		fetch(`${constants.API_BASE_URL}/public/getLoginPageImage`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response?.data?.text) {
					setHomeBanner(response.data.text);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		GetHomeBanner();
	}, []);

	// Base64
	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	// Image Upload
	const handleSubmitClick = async (e) => {
		e.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/setLoginPageImage`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				originalFileName: state.originalFileName,
				text: await toBase64(state.text),
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'success',
							message: 'Dosya kaydedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: response.message,
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
					showModal: false,
				});
			});
	};

	const getDefaultDatas = () => {
		// KVKK
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/getKVKKText`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((responseKVKK) => responseKVKK.json())
			.then((responseKVKK) => {
				if (responseKVKK.data) {
					// Disclaimer
					fetch(`${constants.API_BASE_URL}/public/getDisclaimer`, {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${context.dataLogin.token}`,
						},
					})
						.then((responseDisclaimer) => responseDisclaimer.json())
						.then((responseDisclaimer) => {
							if (responseDisclaimer.data) {
								fetch(
									`${constants.API_BASE_URL}/public/getCookies`,
									{
										method: 'GET',
										headers: {
											'Content-Type': 'application/json',
											Authorization: `Bearer ${context.dataLogin.token}`,
										},
									}
								)
									.then(
										(responseCookies) =>
											responseCookies.json()
										// eslint-disable-next-line function-paren-newline
									)
									.then((responseCookies) => {
										if (responseCookies.data) {
											fetch(
												`${constants.API_BASE_URL}/public/getTermsOfUse`,
												{
													method: 'GET',
													headers: {
														'Content-Type':
															'application/json',
														Authorization: `Bearer ${context.dataLogin.token}`,
													},
												}
											)
												.then(
													(responseTermsOfUse) =>
														responseTermsOfUse.json()
													// eslint-disable-next-line function-paren-newline
												)
												.then((responseTermsOfUse) => {
													// eslint-disable-next-line no-empty
													if (
														responseTermsOfUse.data
													) {
														setState({
															...state,
															showLoading: false,
															termsOfUse:
																responseTermsOfUse.data,
															cookies:
																responseCookies.data,
															disclaimer:
																responseDisclaimer.data,
															kvkk: responseKVKK.data,
														});
													} else {
														setState({
															...state,
															showLoading: false,
														});
													}
												})
												.catch((error) => {
													setState({
														...state,
														toast: {
															type: 'error',
															message:
																error.message,
														},
														showLoading: false,
													});
												});
										} else {
											setState({
												...state,
												showLoading: false,
											});
										}
									})
									.catch((error) => {
										setState({
											...state,
											toast: {
												type: 'error',
												message: error.message,
											},
											showLoading: false,
										});
									});
							} else {
								setState({
									...state,
									showLoading: false,
								});
							}
						})
						.catch((error) => {
							setState({
								...state,
								toast: {
									type: 'error',
									message: error.message,
								},
								showLoading: false,
							});
						});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		getDefaultDatas();
	}, []);

	// useEffect
	React.useEffect(() => {
		/* eslint-disable */
		const tooltipTriggerList = document.querySelectorAll(
			'[data-bs-toggle="tooltip"]'
		);
		const tooltipList = [...tooltipTriggerList].map(
			(tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
		);
		/* eslint-enable */
	}, [state]);

	// TinyMCE
	const disclaimerEditorRef = React.useRef();
	const cookieEditorRef = React.useRef();
	const kvkkEditorRef = React.useRef();
	const termsOfUseEditorRef = React.useRef();
	// const aboutUsEditorRef = React.useRef();

	const handleSetDisclaimer = () => {
		if (disclaimerEditorRef.current.getContent()) {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/setDisclaimer`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					title: 'Disclaimer',
					description: disclaimerEditorRef.current.getContent(),
					standartContentType: 'DISCLAIMER',
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'success',
								message: 'Disclaimer kaydedildi.',
							},
						});
					} else {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'error',
								message: response.description
									? response.description
									: 'Lütfen daha sonra tekrar deneyin.',
							},
						});
					}
				})
				.catch((error) => {
					setState({
						...state,
						showModal: false,
						toast: {
							type: 'error',
							message: error.message,
						},
						showLoading: false,
					});
				});
		}
	};

	const handleSetCookie = () => {
		if (cookieEditorRef.current.getContent()) {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/setCookies`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					title: 'Çerez Politikası',
					description: cookieEditorRef.current.getContent(),
					standartContentType: 'COOKIES',
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'success',
								message: 'Çerezler kaydedildi.',
							},
						});
					} else {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'error',
								message: response.description
									? response.description
									: 'Lütfen daha sonra tekrar deneyin.',
							},
						});
					}
				})
				.catch((error) => {
					setState({
						...state,
						showModal: false,
						toast: {
							type: 'error',
							message: error.message,
						},
						showLoading: false,
					});
				});
		}
	};

	const handleSetKVKK = () => {
		if (kvkkEditorRef.current.getContent()) {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/setKVKKText`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					title: 'KVKK Aydınlatma Metni',
					description: kvkkEditorRef.current.getContent(),
					standartContentType: 'KVKK',
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'success',
								message: 'KVKK Metni kaydedildi.',
							},
						});
					} else {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'error',
								message: response.description
									? response.description
									: 'Lütfen daha sonra tekrar deneyin.',
							},
						});
					}
				})
				.catch((error) => {
					setState({
						...state,
						showModal: false,
						toast: {
							type: 'error',
							message: error.message,
						},
						showLoading: false,
					});
				});
		}
	};

	const handleSetTermsOfUse = () => {
		if (termsOfUseEditorRef.current.getContent()) {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/setTermsOfUse`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					title: 'Kullanım Koşulları',
					description: termsOfUseEditorRef.current.getContent(),
					standartContentType: 'TERMSOFUSE',
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'success',
								message: 'Kullanım Koşulları kaydedildi.',
							},
						});
					} else {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'error',
								message: response.description
									? response.description
									: 'Lütfen daha sonra tekrar deneyin.',
							},
						});
					}
				})
				.catch((error) => {
					setState({
						...state,
						showModal: false,
						toast: {
							type: 'error',
							message: error.message,
						},
						showLoading: false,
					});
				});
		}
	};

	// const handleSetAboutUs = () => {
	// 	if (aboutUsEditorRef.current.getContent()) {
	// 		setState({ ...state, showLoading: true });
	// 		fetch(`${constants.API_BASE_URL}/admin/setAboutUs`, {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 				Authorization: `Bearer ${context.dataLogin.token}`,
	// 			},
	// 			body: JSON.stringify({
	// 				title: 'Hakkımızda',
	// 				description: aboutUsEditorRef.current.getContent(),
	// 				standartContentType: 'ABOUTUS',
	// 			}),
	// 		})
	// 			.then((response) => response.json())
	// 			.then((response) => {
	// 				if (response.data) {
	// 					setState({
	// 						...state,
	// 						showLoading: false,
	// 						showModal: false,
	// 						toast: {
	// 							type: 'success',
	// 							message: 'Hakkımızda kaydedildi.',
	// 						},
	// 					});
	// 				} else {
	// 					setState({
	// 						...state,
	// 						showLoading: false,
	// 						showModal: false,
	// 						toast: {
	// 							type: 'error',
	// 							message: response.description
	// 								? response.description
	// 								: 'Lütfen daha sonra tekrar deneyin.',
	// 						},
	// 					});
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				setState({
	// 					...state,
	// 					showModal: false,
	// 					toast: {
	// 						type: 'error',
	// 						message: error.message,
	// 					},
	// 					showLoading: false,
	// 				});
	// 			});
	// 	}
	// };

	return (
		<>
			<div>
				<div className="accordion" id="accordionProfile">
					{/* Disclaimer */}
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button
								className="accordion-button bg-transparent p-4"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#group-0"
							>
								<h5 className="text-orange mb-0">Disclaimer</h5>
							</button>
						</h2>
						<div
							id="group-0"
							className="accordion-collapse collapse show"
							data-bs-parent="#accordionProfile"
						>
							<div className="accordion-body p-4 text-center">
								<Editor
									apiKey={tinymceApiKey}
									// eslint-disable-next-line no-return-assign
									onInit={(_evt, editor) =>
										(disclaimerEditorRef.current = editor)
									}
									initialValue={
										state?.disclaimer?.description
									}
									init={state.tinymceInit}
								/>

								<button
									type="button"
									className="btn btn-orange text-white rounded-pill px-4 py-2 ms-0 flex-md-grow-0 flex-fill mt-3 mb-3"
									onClick={() => handleSetDisclaimer()}
								>
									Kaydet
									<i className="fas fa-save ms-2" />
								</button>
							</div>
						</div>
					</div>

					{/* Cookie */}
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button
								className="accordion-button bg-transparent p-4"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#group-1"
							>
								<h5 className="text-orange mb-0">
									Çerez Politikası
								</h5>
							</button>
						</h2>
						<div
							id="group-1"
							className="accordion-collapse collapse"
							data-bs-parent="#accordionProfile"
						>
							<div className="accordion-body p-4 text-center">
								<Editor
									apiKey={tinymceApiKey}
									// eslint-disable-next-line no-return-assign
									onInit={(_evt, editor) =>
										(cookieEditorRef.current = editor)
									}
									initialValue={state?.cookies?.description}
									init={state.tinymceInit}
								/>

								<button
									type="button"
									className="btn btn-orange text-white rounded-pill px-4 py-2 ms-0 flex-md-grow-0 flex-fill mt-3 mb-3"
									onClick={() => handleSetCookie()}
								>
									Kaydet
									<i className="fas fa-save ms-2" />
								</button>
							</div>
						</div>
					</div>

					{/* KVKK */}
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button
								className="accordion-button bg-transparent p-4"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#group-2"
							>
								<h5 className="text-orange mb-0">
									KVKK Aydınlatma Metni
								</h5>
							</button>
						</h2>
						<div
							id="group-2"
							className="accordion-collapse collapse"
							data-bs-parent="#accordionProfile"
						>
							<div className="accordion-body p-4 text-center">
								<Editor
									apiKey={tinymceApiKey}
									// eslint-disable-next-line no-return-assign
									onInit={(_evt, editor) =>
										(kvkkEditorRef.current = editor)
									}
									initialValue={state?.kvkk?.description}
									init={state.tinymceInit}
								/>

								<button
									type="button"
									className="btn btn-orange text-white rounded-pill px-4 py-2 ms-0 flex-md-grow-0 flex-fill mt-3 mb-3"
									onClick={() => handleSetKVKK()}
								>
									Kaydet
									<i className="fas fa-save ms-2" />
								</button>
							</div>
						</div>
					</div>

					{/* Kullanım Koşulları */}
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button
								className="accordion-button bg-transparent p-4"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#group-3"
							>
								<h5 className="text-orange mb-0">
									Kullanım Koşulları
								</h5>
							</button>
						</h2>
						<div
							id="group-3"
							className="accordion-collapse collapse"
							data-bs-parent="#accordionProfile"
						>
							<div className="accordion-body p-4 text-center">
								<Editor
									apiKey={tinymceApiKey}
									// eslint-disable-next-line no-return-assign
									onInit={(_evt, editor) =>
										(termsOfUseEditorRef.current = editor)
									}
									initialValue={
										state?.termsOfUse?.description
									}
									init={state.tinymceInit}
								/>

								<button
									type="button"
									className="btn btn-orange text-white rounded-pill px-4 py-2 ms-0 flex-md-grow-0 flex-fill mt-3 mb-3"
									onClick={() => handleSetTermsOfUse()}
								>
									Kaydet
									<i className="fas fa-save ms-2" />
								</button>
							</div>
						</div>
					</div>

					{/* Giriş Sayfası Banner */}
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button
								className="accordion-button bg-transparent p-4"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#group-4"
							>
								<h5 className="text-orange mb-0">
									Giriş Sayfası Banner
								</h5>
							</button>
						</h2>
						<div
							id="group-4"
							className="accordion-collapse collapse"
							data-bs-parent="#accordionProfile"
						>
							<div className="accordion-body p-4 text-center">
								<form onSubmit={(e) => handleSubmitClick(e)}>
									<div className="row g-4 align-items-center mb-3">
										<div className="col-5 text-left">
											<label className="col-form-label fw-medium">
												Görsel
												<i
													className="fas fa-question-circle ms-2 opacity-75"
													data-bs-toggle="tooltip"
													data-bs-title="Görsel Boyutu: 624x385 ayarlanmalıdır. Dosya formatı .png, .jpg, .jpeg eklenebilir."
												/>
											</label>
										</div>
										<div className="col-7">
											<input
												type="file"
												accept=".jpg, .jpeg, .png"
												required
												name="text"
												className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
												onChange={(event) => {
													if (
														event?.target
															?.files?.[0]
													) {
														setState({
															...state,
															text: event.target
																.files[0],
															originalFileName:
																event.target
																	.files[0]
																	.name,
														});
													}
												}}
											/>
										</div>
									</div>

									<div className="mt-5">
										{homeBanner && (
											<img
												src={`https://${homeBanner}`}
												alt=""
												className="mw-100 m-auto d-md-block d-lg-block"
											/>
										)}
									</div>

									<button
										type="submit"
										className="btn btn-orange text-white rounded-pill px-4 py-2 ms-0 flex-md-grow-0 flex-fill mt-3 mb-3"
									>
										Kaydet
										<i className="fas fa-save ms-2" />
									</button>
								</form>
							</div>
						</div>
					</div>

					{/* Hakkımızda */}
					{/* <div className="accordion-item d-none">
						<h2 className="accordion-header">
							<button
								className="accordion-button bg-transparent p-4"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#group-5"
							>
								<h5 className="text-orange mb-0">Hakkımızda</h5>
							</button>
						</h2>
						<div
							id="group-5"
							className="accordion-collapse collapse"
							data-bs-parent="#accordionProfile"
						>
							<div className="accordion-body p-4 text-center">
								<button
									type="button"
									className="btn btn-orange text-white rounded-pill
									px-4 py-2 ms-0 flex-md-grow-0 flex-fill mt-3 mb-3"
									onClick={() => handleSetAboutUs()}
								>
									Kaydet
									<i className="fas fa-save ms-2" />
								</button>
							</div>
						</div>
					</div> */}
				</div>
			</div>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminContentsStatics;
