/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Context from '../../stores/Context/Context';

const WidgetMentor = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const navigate = useNavigate();

	return (
		<div className="p-4 mb-4 rounded-2 bg-gray-lightest h-100 d-flex flex-column">
			<h5 className="mb-4 text-gray flex-shrink-0">
				<NavLink
					to="/mentorler"
					className="d-flex justify-content-between align-items-center text-reset text-decoration-none"
				>
					Mentorum
					<i className="fas fa-arrow-right ms-auto" />
				</NavLink>
			</h5>

			{context?.dataUser?.matchedUser ? (
				<>
					<div className="d-flex align-items-center flex-grow-1 mb-4">
						{context?.dataUser?.matchedUser?.profileImage ? (
							<div
								className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
								style={{
									width: 80,
									height: 80,
									zIndex: 1,
								}}
							>
								<img
									src={`https://${context.dataUser.matchedUser.profileImage}`}
									alt={
										context.dataUser.matchedUser.name &&
										context.dataUser.matchedUser.surname
											? context.dataUser.matchedUser
													.name +
											  context.dataUser.matchedUser
													.surname
											: 'Avatar'
									}
									className="rounded-circle"
									style={{
										width: 80,
										height: 80,
									}}
								/>
							</div>
						) : (
							<>
								{context.dataUser.matchedUser.name &&
									context.dataUser.matchedUser.surname && (
										<div
											className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
											style={{
												width: 80,
												height: 80,
												cursor: 'pointer',
											}}
										>
											{context.dataUser.matchedUser.name.substring(
												0,
												1
											) +
												context.dataUser.matchedUser.surname.substring(
													0,
													1
												)}
										</div>
									)}
							</>
						)}

						<div className="flex-grow-1">
							<h6 className="text-blue mb-2 lh-1">
								{context.dataUser.matchedUser.name}{' '}
								{context.dataUser.matchedUser.surname}
							</h6>
							<span className="text-uppercase text-gray-light fs-7">
								Mentor
							</span>
						</div>
					</div>
					<div className="text-center flex-shrink-0 fs-7">
						<button
							type="button"
							className="btn btn-link text-decoration-none text-blue-lighter"
							onClick={() =>
								navigate('/iletisim', {
									state: {
										receiver: {
											id: context.dataUser.matchedUser
												?.id,
											name: context.dataUser.matchedUser
												?.name,
											surname:
												context.dataUser.matchedUser
													?.surname,
										},
									},
								})
							}
						>
							Mesaj Gönder
						</button>
					</div>
				</>
			) : (
				<div className="flex-fill bg-white rounded-2 d-flex flex-column justify-content-center align-items-center text-green text-center p-4">
					<i className="fas fa-chalkboard-teacher h1 mb-3 fw-bold" />
					<h6 className="mb-0 lh-base">
						Henüz bir mentor <br />
						tanımlanmadı.
					</h6>
				</div>
			)}
		</div>
	);
};

export default WidgetMentor;
