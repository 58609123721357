import React from 'react';
import { NavLink } from 'react-router-dom';

const WidgetAdminCriticalNGO = (props) => {
	const mentees = props.data;
	const { count } = props;

	return (
		<div
			className={
				mentees && mentees.length > 0
					? 'bg-gray-lightest rounded-2 h-100 p-4 d-flex flex-column infinity'
					: 'bg-gray-lightest rounded-2 h-100 p-4 d-flex flex-column'
			}
		>
			<NavLink
				to="/admin/ogrenciler"
				className="d-flex mb-4 text-reset text-decoration-none align-items-center justify-content-between"
			>
				<h5 className="mb-0">STK Durumu Kritik</h5>
				{count > 0 && (
					<div className="d-flex align-items-center text-red">
						<span className="h4 mb-0 me-2">{count}</span>
						<i className="fas fa-arrow-right" />
					</div>
				)}
			</NavLink>

			{mentees && mentees.length > 0 ? (
				<div
					className="flex-fill pb-5"
					style={{ overflowY: 'auto', maxHeight: 220 }}
				>
					{mentees.map((item) => (
						<NavLink
							to="/admin/ogrenciler"
							className="d-flex align-items-center bg-white rounded-2 p-3 text-reset text-decoration-none mb-2"
							key={item.id}
						>
							<div
								className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center h4 mb-0"
								style={{
									width: 48,
									height: 48,
									fontSize: '14px',
								}}
							>
								{item.name && item.name.substr(0, 1)}
								{item.surname && item.surname.substr(0, 1)}
							</div>

							<div className="flex-grow-1">
								<h5 className="mb-0 fs-7 text-red text-truncate w-75">
									{item.name &&
										item.surname &&
										`${item.name} ${item.surname}`}
								</h5>
								<span className="fs-8 text-gray-light">
									Eksik Dosya
								</span>
							</div>
						</NavLink>
					))}
				</div>
			) : (
				<div className="flex-fill bg-white rounded-2 d-flex flex-column justify-content-center align-items-center text-green text-center p-4">
					<i className="far fa-smile h1 mb-3" />
					<h6 className="mb-0 lh-base">
						Kritik durum <br />
						bulunmuyor
					</h6>
				</div>
			)}
		</div>
	);
};

export default WidgetAdminCriticalNGO;
