import React from 'react';

import constants from '../../constants';
import Context from '../../stores/Context/Context';

const WidgetSliderHero = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});

	React.useEffect(() => {
		fetch(`${constants.API_BASE_URL}/slider/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.content) {
					setState({
						...state,
						sliders: response.content,
					});
				}
			});
	}, []);

	if (state.sliders) {
		setTimeout(() => {
			// eslint-disable-next-line
			const sliderHero = new window.Swiper('.sliderHero', {
				loop: true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: true,
				},
				pagination: {
					el: '.sliderHero .swiper-pagination',
					clickable: true,
				},
			});
		}, 100);
	}

	return (
		<div className="rounded-2 swiper sliderHero">
			<div className="swiper-wrapper">
				{state.sliders &&
					state.sliders.map((item) => (
						<div
							className="swiper-slide bg-blue position-relative"
							key={item.id}
						>
							{item.sliderType === 'HALF' && (
								<div className="d-flex align-items-center sliderItemHalf">
									<div className="col-xl-6">
										<div className="p-5">
											<h2 className="mb-3 text-orange">
												{item.title}
											</h2>
											<p className="mb-4 h5 fw-normal lh-base text-white">
												{item.description}
											</p>
											{item.url && (
												<a
													href={item.url}
													target="_blank"
													className="btn btn-orange text-white rounded-pill px-4 py-2"
													rel="noreferrer"
												>
													İncele
													<i className="fas fa-arrow-right ms-2" />
												</a>
											)}
										</div>
									</div>
									<img
										src={`https://${item.icon}`}
										alt=""
										className="h-100 d-xl-block d-none position-absolute top-0 end-0 w-auto p-0 m-0"
										style={{ maxWidth: '50%' }}
									/>
								</div>
							)}

							{item.sliderType === 'FULL' && (
								<div className="sliderItemFull">
									{item.url ? (
										<a
											href={item.url}
											target="_blank"
											className="d-block"
											rel="noreferrer"
										>
											<img
												src={`https://${item.icon}`}
												alt=""
											/>
										</a>
									) : (
										<img
											src={`https://${item.icon}`}
											alt=""
										/>
									)}
								</div>
							)}
						</div>
					))}
			</div>
			<div className="swiper-pagination swiper-pagination-white position-absolute bottom-0 mb-4 start-0" />
		</div>
	);
};

export default WidgetSliderHero;
