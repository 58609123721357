import React from 'react';
import { NavLink } from 'react-router-dom';

import constants from '../../constants';
import Context from '../../stores/Context/Context';

const WidgetAdminWaitingApprovals = () => {
	const [context] = React.useContext(Context);
	const [approvalsUserList, setApprovalsUserList] = React.useState(null);
	const [profileList, setProfileList] = React.useState(null);

	// Use Effect
	React.useEffect(() => {
		fetch(
			`${constants.API_BASE_URL}/admin/getActiveUserRequest?size=9999`,
			{
				method: 'GET',
				headers: new Headers({
					Authorization: `Bearer ${context.dataLogin.token}`,
					'Content-Type': constants.API_HEADERS_PUBLIC,
				}),
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response?.data?.content) {
					setApprovalsUserList(response.data.content);
				}
			});

		fetch(
			`${constants.API_BASE_URL}/admin/getUserStatusWaiting?size=9999`,
			{
				method: 'GET',
				headers: new Headers({
					Authorization: `Bearer ${context.dataLogin.token}`,
					'Content-Type': constants.API_HEADERS_PUBLIC,
				}),
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response?.data?.content) {
					setProfileList(response.data.content);
				}
			});
	}, []);

	return (
		<div className="bg-gray-lightest rounded-2 h-100 p-4 d-flex flex-column infinity">
			<NavLink
				to="/admin/onaylar"
				className="d-flex mb-4 text-reset text-decoration-none align-items-center justify-content-between"
			>
				<h5 className="mb-0">Onay Bekleyen</h5>
				{(approvalsUserList || profileList) && (
					<div className="d-flex align-items-center text-orange">
						<span className="h4 mb-0 me-2">
							{(approvalsUserList
								? approvalsUserList.length
								: 0) + (profileList ? profileList.length : 0)}
						</span>
						<i className="fas fa-arrow-right" />
					</div>
				)}
			</NavLink>

			{approvalsUserList || profileList ? (
				<div
					className="flex-fill pb-5"
					style={{ overflowY: 'auto', maxHeight: 220 }}
				>
					{approvalsUserList &&
						approvalsUserList.map((item) => (
							<NavLink
								to="/admin/onaylar"
								className="d-flex align-items-center bg-white rounded-2 p-3 text-reset text-decoration-none mb-2"
								key={item.id}
							>
								<div
									className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center h4 mb-0"
									style={{
										width: 48,
										height: 48,
										fontSize: '14px',
									}}
								>
									{item.name && item.name.substr(0, 1)}
									{item.surname && item.surname.substr(0, 1)}
								</div>

								<div className="flex-grow-1">
									<h5 className="mb-0 fs-7 text-blue text-truncate w-75">
										{item.name &&
											item.surname &&
											`${item.name} ${item.surname}`}
									</h5>
									<span className="fs-8 text-gray-light">
										Yeni üyelik onayı
									</span>
								</div>
							</NavLink>
						))}

					{profileList &&
						profileList.map((item) => (
							<NavLink
								to="/admin/onaylar"
								className="d-flex align-items-center bg-white rounded-2 p-3 text-reset text-decoration-none mb-2"
								key={item.id}
							>
								<div
									className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center h4 mb-0"
									style={{
										width: 48,
										height: 48,
										fontSize: '14px',
									}}
								>
									{item.name && item.name.substr(0, 1)}
									{item.surname && item.surname.substr(0, 1)}
								</div>

								<div className="flex-grow-1">
									<h5 className="mb-0 fs-7 text-blue text-truncate w-75">
										{item.name &&
											item.surname &&
											`${item.name} ${item.surname}`}
									</h5>
									<span className="fs-8 text-gray-light">
										Giriş kartı onayı
									</span>
								</div>
							</NavLink>
						))}
				</div>
			) : (
				<div className="flex-fill bg-white rounded-2 d-flex flex-column justify-content-center align-items-center text-green text-center p-4">
					<i className="far fa-smile h1 mb-3" />
					<h6 className="mb-0 lh-base">
						Onay bekleyen <br />
						bulunmuyor
					</h6>
				</div>
			)}
		</div>
	);
};

export default WidgetAdminWaitingApprovals;
