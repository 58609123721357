import ImageCropper from './ImageCropper';
import Modal from '../Modal/Modal';

const ModalCropper = ({ modalOpen, updateAvatar, closeModal }) => (
	<Modal
		showModal={modalOpen}
		onClose={() => closeModal()}
		header="Taşı ve Boyutlandır"
	>
		<div className="modal-body p-4">
			<div className="alert alert-orange fs-7 text-center mb-4">
				Yüklenecek görsel .jpg, .jpeg veya .png formatlarında olmalıdır.
			</div>
			<ImageCropper updateAvatar={updateAvatar} closeModal={closeModal} />
		</div>
	</Modal>
);

export default ModalCropper;
