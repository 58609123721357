import React from 'react';
import { NavLink } from 'react-router-dom';

import constants from '../../constants';
import Context from '../../stores/Context/Context';

const WidgetAdminCalendar = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});

	React.useEffect(() => {
		fetch(`${constants.API_BASE_URL}/admin/allActivitiesAdmin/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.content) {
					setState({
						...state,
						courses: response.content,
					});
				}
			});
	}, []);

	return (
		<div
			className={`h-100 p-4 bg-blue-lightest rounded-2 d-flex flex-column ${
				state?.courses && 'infinity infinity-blue-lightest'
			} `}
		>
			<h5 className="mb-4 text-blue-light">
				<NavLink
					to="/admin/takvim"
					className="d-flex justify-content-between align-items-center text-reset text-decoration-none"
				>
					Takvim{' '}
					{state?.courses && (
						<span className="fs-7 ms-3 text-blue-lighter">
							{state?.courses?.length} adet etkinlik mevcut.
						</span>
					)}
					<i className="fas fa-arrow-right ms-auto" />
				</NavLink>
			</h5>

			<div
				className={`flex-fill ${state?.courses && 'pb-3'}`}
				style={{
					overflowY: 'auto',
					maxHeight: state?.courses ? 217 : 'none',
				}}
			>
				{state?.courses ? (
					state.courses.map((item) => (
						<NavLink
							key={item?.id}
							to="/admin/takvim"
							className="d-flex bg-white p-3 rounded-2 align-items-center text-decoration-none mb-2"
						>
							<div
								className="flex-shrink-0 me-3 d-flex justify-content-center align-items-center text-white bg-blue rounded-circle"
								style={{
									width: 48,
									height: 48,
								}}
							>
								<i className="fas fa-book" />
							</div>
							<div className="flex-grow-1">
								<h5 className="mb-0 fs-7 text-blue">
									<span className="text-red me-2">
										{item.isMandatory ? (
											<span className="text-red ">
												Zorunlu
											</span>
										) : (
											<span className="text-green">
												Opsiyonel
											</span>
										)}
									</span>{' '}
									{item.name}
								</h5>
								<span className="fs-8 text-blue-light">
									{new Date(
										item?.dateTimeBegin
									).toLocaleDateString('tr-TR')}
								</span>
							</div>
							<div className="flex-shrink-0 ms-3 fs-7">
								<p className="mb-0">
									{new Date(item?.dateTimeBegin)
										.toLocaleTimeString('tr-TR')
										.substring(0, 5)}
								</p>
								<p className="mb-0">
									{new Date(item?.dateTimeEnd)
										.toLocaleTimeString('tr-TR')
										.substring(0, 5)}
								</p>
							</div>
						</NavLink>
					))
				) : (
					<div className="flex-fill h-100 bg-white rounded-2 d-flex flex-column justify-content-center align-items-center text-blue-light text-center p-4">
						<i className="far fa-calendar-days h1 mb-3" />
						<h6 className="mb-0 lh-base">
							Takviminde eğitim bulunmuyor
						</h6>
					</div>
				)}
			</div>
		</div>
	);
};

export default WidgetAdminCalendar;
