import React from 'react';

import constants from '../../constants';
import Context from '../../stores/Context/Context';

const WidgetAlerts = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});
	const { userRole } = context.dataLogin;

	React.useEffect(() => {
		fetch(
			`${constants.API_BASE_URL}/${
				userRole === 'mentee'
					? 'mentee/getWarningActiveForMentee'
					: 'mentor/getWarningActiveForMentor'
			}/?size=9999`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data?.content) {
					setState({
						...state,
						notifications: response.data.content,
					});
				}
			});
	}, []);

	if (state.notifications) {
		setTimeout(() => {
			// eslint-disable-next-line
			const sliderAlerts = new window.Swiper('.sliderAlerts', {
				loop: true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: true,
				},
				pagination: {
					el: '.sliderAlerts .swiper-pagination',
					clickable: true,
				},
			});
		}, 100);
	}

	return (
		<div className="p-4 rounded-2 bg-orange flex-fill d-flex flex-column">
			<h5 className="mb-4 text-white">Uyarılar</h5>
			<div className="rounded-2 swiper sliderAlerts w-100 h-100">
				<div className="swiper-wrapper">
					{state.notifications &&
						state.notifications.map((item) => (
							<div className="swiper-slide" key={item.id}>
								<div className="d-flex">
									<div
										className="d-flex justify-content-center align-items-center bg-white rounded-circle flex-shrink-0 me-3"
										style={{
											width: 48,
											height: 48,
										}}
									>
										<i className="fas fa-exclamation-circle text-orange fs-4" />
									</div>
									<div className="text-white flex-grow-1">
										<p className="mb-2">
											{item.description}
										</p>
									</div>
								</div>
							</div>
						))}
				</div>
				<div className="swiper-pagination swiper-pagination-white position-absolute bottom-0 start-0" />
			</div>
		</div>
	);
};

export default WidgetAlerts;
