import React from 'react';
import { Outlet } from 'react-router-dom';

import AdminProtectedRoute from '../../routes/AdminProtectedRoute/AdminProtectedRoute';

import AdminHeader from '../AdminHeader/AdminHeader';
import FooterGeneric from '../FooterGeneric/FooterGeneric';

const AdminLayout = () => (
	<AdminProtectedRoute>
		<main className="mt-lg-5 mt-3">
			<div className="container">
				<div className="row">
					<div className="col-lg-3">
						<AdminHeader />
					</div>

					<div className="col-lg-9">
						<Outlet />
					</div>
				</div>
			</div>
		</main>
		<FooterGeneric />
	</AdminProtectedRoute>
);

export default AdminLayout;
