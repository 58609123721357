import React from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';
import SuperAdminHelpWhatIsNew from './SuperAdminHelpWhatIsNew';
import SuperAdminHelpUsersManual from './SuperAdminHelpUsersManual';

const SuperAdminHelp = () => {
	const [context] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({ activeTab: 'new' });
	const navigate = useNavigate();

	const handleTabClick = (tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	// useEffect
	React.useEffect(() => {
		if (constants.ADMIN_USERS.indexOf(context.dataUser?.id) === -1) {
			navigate('/admin/destek');
		}
	}, []);

	return (
		<>
			<WidgetAdminTitleBar title="Destek" />

			{/* filter */}
			<div className="mb-5 d-flex flex-sm-row flex-column gap-2">
				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'new'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('new')}
				>
					Yeni ne var?
				</button>

				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'userGuide'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('userGuide')}
				>
					Kullanım Klavuzu
				</button>
			</div>

			{state.activeTab === 'new' && <SuperAdminHelpWhatIsNew />}
			{state.activeTab === 'userGuide' && <SuperAdminHelpUsersManual />}
		</>
	);
};

export default SuperAdminHelp;
