import React from 'react';
import { NavLink } from 'react-router-dom';

const WidgetCTA = () => (
	<div className="bg-gray-lightest rounded-2 mt-xl-8 mt-4 text-lg-start text-center">
		<div className="row justify-content-center align-items-center">
			<div className="col-xl-5 col-lg-4">
				<img
					src={require('../../assets/images/cta.png')}
					alt=""
					className="img-fluid my-xl-n4 my-5"
				/>
			</div>
			<div className="col-xl-3 col-lg-4">
				<div className="h-100 d-flex flex-column justify-content-center py-5">
					<h3 className="text-orange mb-3 lh-base">
						Eğitim içerikleri seni bekliyor!
					</h3>
					<p className="h5 mb-4 fw-normal text-gray-light lh-base">
						Sana atanmış veya opsiyonel tüm eğitimleri hemen
						görüntüle.
					</p>
					<p className="mb-0">
						<NavLink
							to="/egitimler"
							className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						>
							İncele
							<i className="fas fa-arrow-right ms-2" />
						</NavLink>
					</p>
				</div>
			</div>
		</div>
	</div>
);

export default WidgetCTA;
