import React from 'react';

const WidgetHandbook = () => (
	<div className="p-4 mb-4 rounded-2 bg-gray-lightest">
		<a
			href="/assets/content/Program-Rehberi.pdf"
			target="_blank"
			className="d-flex align-items-center justify-content-center text-reset text-decoration-none"
		>
			<div
				className="flex-shrink-0 me-3 d-flex justify-content-center align-items-center text-white bg-blue rounded-circle"
				style={{
					width: 48,
					height: 48,
				}}
			>
				<i className="fas fa-book" />
			</div>
			<div className="flex-grow-1 fs-5 fw-semibold text-blue lh-sm">
				Program Rehberi
			</div>
		</a>
	</div>
);

export default WidgetHandbook;
