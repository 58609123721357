import React from 'react';

const Modal = (props) =>
	props.showModal && (
		<>
			<div className="modal d-block">
				<div
					className={`modal-dialog ${
						props.size && `modal-${props.size}`
					} modal-dialog-centered modal-dialog-scrollable`}
				>
					<div className="modal-content border-0 rounded-2">
						<div className="modal-header">
							<h1 className="modal-title fs-5">{props.header}</h1>
							<button
								type="button"
								className="btn-close"
								aria-label="Close"
								onClick={props.onClose}
							/>
						</div>
						{props.children}
					</div>
				</div>
			</div>
			<div className="modal-backdrop fade show" />
			<style>
				{`
				hmtl, body {
					overflow: hidden;
				}
				`}
			</style>
		</>
	);

export default Modal;
