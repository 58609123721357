import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import trLocale from '@fullcalendar/core/locales/tr';
import moment from 'moment';
import { Tooltip } from 'bootstrap';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';

import './AdminCalendar.scss';

// import Modal from '../../components/Modal/Modal';
// import Loading from '../../components/Loading/Loading';
// import Toast from '../../components/Toast/Toast';

let tooltipInstance = null;
const AdminCalendar = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		showLoading: false,
		activities: null,
		activitiesEvents: null,
		weekendsVisible: true,
		currentEvents: [],
	});

	// Get Activities
	const getActivities = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/allActivitiesAdmin/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.content) {
					const activitiesEvents = [];

					response.content.forEach((element) => {
						activitiesEvents.push({
							title: element.name,
							start: moment(element.dateTimeBegin).format(
								'YYYY-MM-DD HH:mm'
							),
							end: moment(element.dateTimeEnd).format(
								'YYYY-MM-DD HH:mm'
							),
							url: element.url,
							extendedProps: {
								description: `${moment(
									element.dateTimeBegin
								).format('YYYY-MM-DD HH:mm')}<br />${
									element.name
								}<br />${element.description}`,
							},
						});
					});

					setState({
						...state,
						showLoading: false,
						activities: response.content,
						activitiesEvents,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	// FullCalendar
	const handleMouseEnter = (info) => {
		if (info.event.extendedProps.description) {
			tooltipInstance = new Tooltip(info.el, {
				title: info.event.extendedProps.description,
				html: true,
				placement: 'top',
				trigger: 'hover',
				container: 'body',
			});

			tooltipInstance.show();
		}
	};

	const handleMouseLeave = () => {
		if (tooltipInstance) {
			tooltipInstance.dispose();
			tooltipInstance = null;
		}
	};

	// useEffect
	React.useEffect(() => {
		getActivities();
	}, []);

	// React.useEffect(() => {
	// 	if (state.activitiesEvents) {
	// 		console.log('activitiesEvents: ', state.activitiesEvents);
	// 	}
	// }, [state.activitiesEvents]);

	// When stat.activities load
	// React.useEffect(() => {
	// 	if (state && state.activities) {
	// 		console.log('state.activities: ', state.activities);
	// 	}
	// }, [state.activities]);

	return (
		<div className="text-center">
			<WidgetAdminTitleBar title="Takvim" />
			<FullCalendar
				plugins={[dayGridPlugin]}
				themeSystem="bootstrap"
				initialView="dayGridMonth"
				weekends
				locales={trLocale}
				locale="tr"
				events={state.activitiesEvents}
				eventMouseEnter={handleMouseEnter}
				eventMouseLeave={handleMouseLeave}
				// eslint-disable-next-line react/jsx-no-bind
				eventClick={function (info) {
					info.jsEvent.preventDefault();

					if (info.event.url) {
						window.open(info.event.url);
					}
				}}
			/>
		</div>
	);
};

export default AdminCalendar;
