import React from 'react';
import { NavLink } from 'react-router-dom';

import Context from '../../stores/Context/Context';

const WidgetPrograms = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	return (
		<div className="p-4 pb-5 mb-4 rounded-2 bg-gray-lightest">
			<h5 className="mb-4 text-gray">
				<NavLink
					to="/profil"
					className="d-flex justify-content-between align-items-center text-reset text-decoration-none"
				>
					Programım
					<i className="fas fa-arrow-right ms-auto" />
				</NavLink>
			</h5>
			<div className="d-flex align-items-center justify-content-center">
				<div
					className="flex-shrink-0 me-3 d-flex justify-content-center align-items-center text-white bg-blue rounded-circle"
					style={{
						width: 48,
						height: 48,
					}}
				>
					<i className="fas fa-address-card" />
				</div>
				<div className="flex-grow-1 fs-5 fw-semibold text-blue lh-sm">
					{context?.dataUser?.programDTO?.name}
				</div>
			</div>
		</div>
	);
};

export default WidgetPrograms;
