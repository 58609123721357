import React from 'react';
import constants from '../../constants';
import Context from '../../stores/Context/Context';

const AdminHelpUsersManual = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});
	const [usersManual, setUsersManual] = React.useState(null);

	const UsersManual = () => {
		fetch(`${constants.API_BASE_URL}/admin/getUsersManual`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response?.data?.content) {
					setUsersManual(response.data.content);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		UsersManual();
	}, []);

	React.useEffect(() => {
		if (usersManual) {
			setContext({
				...context,
				usersManual,
			});
		}
	}, [usersManual]);

	return (
		<main>
			{usersManual?.length > 0 &&
				usersManual.map((item) => (
					<div key={item.id && item.id} value={item.id && item.id}>
						<h5>{item.title && item.title}</h5>
						<p>{item.description && item.description}</p>
					</div>
				))}
		</main>
	);
};

export default AdminHelpUsersManual;
