import React from 'react';

const Loading = (props) =>
	props.showLoading && (
		<>
			<div
				className="position-fixed top-50 start-50 translate-middle bg-white rounded rounded-2 d-flex justify-content-center align-items-center shadow"
				style={{ width: 64, height: 64, zIndex: 10001 }}
			>
				<span>
					<i className="fas fa-spinner fa-spin fs-3 text-blue-light" />
				</span>
			</div>
			<div
				className="modal-backdrop fade show"
				style={{ zIndex: 10000 }}
			/>
		</>
	);

export default Loading;
