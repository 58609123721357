// eslint-disable-next-line import/named, object-curly-newline
import { baseURL, surveyUrl, gaCode } from '../config/api.config';

const constants = {
	API_BASE_URL: baseURL,
	API_SURVEY_URL: surveyUrl,
	API_HEADERS_PUBLIC: {
		'Content-Type': 'application/json',
	},
	ADMIN_USERS: [999],
	GA_CODE: gaCode,
};

export default constants;
