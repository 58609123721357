import React from 'react';

import { Tooltip } from 'bootstrap';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

const AdminContentsSliders = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		title: '',
		description: '',
		url: '',
		icon: '',
	});

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/slider/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.content) {
					setState({
						...state,
						showLoading: false,
						sliders: response.content,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	React.useEffect(() => {
		/* eslint-disable */
		const tooltipTriggerList = document.querySelectorAll(
			'[data-bs-toggle="tooltip"]'
		);
		const tooltipList = [...tooltipTriggerList].map(
			(tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
		);
		/* eslint-enable */
	}, [state]);

	const handleSearch = (event) => {
		setState({
			...state,
			search: event.target.value,
		});
	};

	const handleEditClick = (item) => {
		if (item) {
			setState({
				...state,
				showModal: true,
				header: `#${item.id} Düzenle`,
				id: item.id,
				sliderType: item.sliderType,
				title: item.title,
				description: item.description,
				url: item.url,
				icon: item.icon,
			});
		} else {
			setState({
				...state,
				showModal: true,
				header: 'Yeni',
				title: '',
				sliderType: 'FULL',
				description: '',
				url: '',
				icon: '',
			});
		}
	};

	const handleSaveClick = async () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/slider/save`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...(state.id && { id: state.id }),
				sliderType: state.sliderType,
				title: state.title,
				description: state.description,
				url: state.url,
				icon:
					state.icon && !state.newIcon
						? state.icon
						: await toBase64(state.newIcon),
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'success',
							message: 'Slider kaydedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: response.description
								? response.description
								: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					showModal: false,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleRemoveClick = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/slider/deactive/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Slider silindi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<>
			<div className="row g-md-4 g-2 align-items-center  mb-5">
				<div className="col-md-3">
					<div className="d-flex align-items-center">
						<label className="form-label fw-medium mb-0 me-3 fs-7">
							Arama
						</label>
						<input
							type="text"
							className="form-control border border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
							onChange={(event) => handleSearch(event)}
							value={state.search}
						/>
					</div>
				</div>
				<div className="col-auto ms-auto">
					<div className="d-flex align-items-center gap-3 justify-content-end">
						<button
							type="button"
							className="btn btn-orange text-white rounded-pill px-4 py-2 ms-md-3 ms-0 flex-md-grow-0 flex-fill"
							onClick={() => handleEditClick()}
						>
							Yeni
							<i className="fas fa-plus ms-2" />
						</button>
					</div>
				</div>
			</div>
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr className="fs-7">
							<th
								scope="col"
								className="align-middle text-gray text-center"
								width="60"
							>
								ID
							</th>
							<th scope="col" className="align-middle text-gray">
								Tür
							</th>
							<th scope="col" className="align-middle text-gray">
								Başlık
							</th>
							<th scope="col" className="align-middle text-gray">
								Açıklama
							</th>
							<th scope="col" className="align-middle text-gray">
								URL
							</th>
							<th
								scope="col"
								className="align-middle text-gray text-center"
								style={{ width: 170 }}
							>
								İşlem
							</th>
						</tr>
					</thead>
					<tbody className="fs-7">
						{state.sliders &&
							state.sliders
								.filter(
									(item) =>
										item.title &&
										item.description &&
										(item.title + item.description)
											.toLowerCase()
											.search(
												state?.search?.toLowerCase()
											) > -1
								)
								.map((item) => (
									<tr key={item.id}>
										<td className="align-middle text-gray-light text-center fw-medium fs-8">
											{item.id}
										</td>
										<td className="align-middle text-gray">
											{item.sliderType === 'FULL' &&
												'Görsel'}
											{item.sliderType === 'HALF' &&
												'Görsel + Metin'}
										</td>
										<td className="align-middle text-gray">
											{item.title}
										</td>
										<td className="align-middle text-gray">
											{item.description.substring(0, 20)}
											...
										</td>
										<td className="align-middle text-gray">
											<a
												href={item.url}
												target="_blank"
												rel="noreferrer"
												className="text-blue-lighter text-decoration-none"
											>
												{item.url}
											</a>
										</td>
										<td className="align-middle text-gray text-center">
											<button
												type="button"
												className="btn border border-2 border-red text-red bg-white rounded-pill px-3 py-1 fs-7 mx-1"
												onClick={() =>
													handleRemoveClick(item.id)
												}
											>
												Sil
											</button>
											<button
												type="button"
												className="btn border border-2 border-orange text-orange bg-white rounded-pill px-3 py-1 fs-7 mx-1"
												onClick={() =>
													handleEditClick(item)
												}
											>
												Düzenle
											</button>
										</td>
									</tr>
								))}
					</tbody>
				</table>
			</div>

			<Modal
				showModal={state.showModal}
				onClose={() =>
					setState({
						...state,
						showModal: false,
					})
				}
				header={state.header}
			>
				<div className="modal-body p-4">
					<form>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Tür
								</label>
							</div>
							<div className="col-7">
								<div className="form-check">
									<input
										type="radio"
										name="sliderType"
										id="sliderTypeFull"
										className="form-check-input"
										value={state.sliderType}
										onChange={() => {
											setState({
												...state,
												sliderType: 'FULL',
											});
										}}
										checked={state.sliderType === 'FULL'}
									/>
									<label
										className="form-check-label"
										htmlFor="sliderTypeFull"
									>
										Sadece Görsel
									</label>
								</div>
								<div className="form-check">
									<input
										type="radio"
										name="sliderType"
										id="sliderTypeHalf"
										className="form-check-input"
										value={state.sliderType}
										onChange={() => {
											setState({
												...state,
												sliderType: 'HALF',
											});
										}}
										checked={state.sliderType === 'HALF'}
									/>
									<label
										className="form-check-label"
										htmlFor="sliderTypeHalf"
									>
										Görsel ve Metin
									</label>
								</div>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Başlık
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 40 karakter uzunluğunda bir slider başlığı giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="title"
									value={state.title}
									onChange={(event) => {
										setState({
											...state,
											title: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={40}
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Açıklama
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 60 karakter uzunluğunda bir slider açıklaması giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="description"
									value={state.description}
									onChange={(event) => {
										setState({
											...state,
											description: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={60}
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Bağlantı
								</label>
							</div>
							<div className="col-7">
								<input
									type="url"
									name="url"
									placeholder="http://"
									value={state.url}
									onChange={(event) => {
										setState({
											...state,
											url: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Görsel
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="Sadece görsel için 970x420; görsel + metin için 420x420 piksel ebatlarında, PNG veya JPG
										formatında ve en fazla 4MB boyutunda
										olmalıdır."
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="file"
									accept="image/*"
									name="newIcon"
									onChange={(event) => {
										if (event?.target?.files?.[0]) {
											setState({
												...state,
												newIcon: event.target.files[0],
											});
										}
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
							{state.icon && !state.newIcon && (
								<div className="col-12">
									<img
										src={`https://${state.icon}`}
										alt=""
										className="w-100"
									/>
								</div>
							)}
							{state.newIcon && (
								<div className="col-12">
									<img
										src={window.URL.createObjectURL(
											state.newIcon
										)}
										alt=""
										className="w-100"
									/>
								</div>
							)}
						</div>
					</form>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() => handleSaveClick()}
					>
						Kaydet
					</button>
				</div>
			</Modal>
			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminContentsSliders;
