import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';

import constants from '../../constants';
import FooterGeneric from '../../layouts/FooterGeneric/FooterGeneric';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

const Reset = () => {
	const [state, setState] = React.useState({
		step: 1,
	});
	const navigate = useNavigate();

	const handleUsernameSubmit = (event) => {
		event.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/forgotPassword`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				headers: constants.API_HEADERS_PUBLIC,
			},
			body: JSON.stringify({
				username: Object.fromEntries(new FormData(event.target))
					.username,
				type: 'sms',
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						username: Object.fromEntries(new FormData(event.target))
							.username,
						step: 2,
						time: Date.now() + 115000,
						showLoading: false,
						toast: {
							type: 'success',
							message:
								'Doğrulama kodu telefon numaranıza SMS olarak gönderildi.',
						},
					});
				} else {
					event.target.reset();
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.description
								? response.description
								: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleConfirmCodeSubmit = (event) => {
		event.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/confirm/passwordResetCode`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				headers: constants.API_HEADERS_PUBLIC,
			},
			body: JSON.stringify({
				userName: state.username,
				confirmCode: Object.fromEntries(new FormData(event.target))
					.confirmCode,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						step: 3,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Doğrulama kodu onaylandı.',
						},
					});
				} else {
					event.target.reset();
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.description
								? response.description
								: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleNewPassSubmit = (event) => {
		event.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/saveNewPassword`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				headers: constants.API_HEADERS_PUBLIC,
			},
			body: JSON.stringify({
				userName: state.username,
				newPass: Object.fromEntries(new FormData(event.target)).newPass,
				newPassConfirm: Object.fromEntries(new FormData(event.target))
					.newPassConfirm,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				event.target.reset();
				if (response.data) {
					setState({
						...state,
						showLoading: true,
						toast: {
							type: 'success',
							message:
								'Yeni parola tanımlandı. 5 saniye içinde giriş sayfasına yönlendirileceksiniz.',
						},
					});
					setTimeout(() => {
						navigate('/giris');
					}, 5000);
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.description
								? response.description
								: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<>
			<main>
				<div className="py-md-8 py-4">
					<div className="container">
						<NavLink
							to="/giris"
							className="d-inline-block text-orange mb-4"
						>
							<i className="fas fa-arrow-left me-3" />
							Giriş
						</NavLink>

						{state.step === 1 && (
							<>
								<div className="row justify-content-center mb-8">
									<div className="col-xxl-4 col-lg-6 col-md-8 text-center">
										<h1 className="text-blue-light mb-3">
											Parolamı Unuttum
										</h1>
										<p className="h5 lh-base mb-0 fw-normal text-gray-light">
											Lütfen parolasını sıfırlamak
											istediğiniz kullanıcı adınızı girin.
											Sıfırlama kodu telefon numaranıza
											SMS olarak gönderilecektir.
										</p>
									</div>
								</div>

								<form
									action=""
									onSubmit={(event) =>
										handleUsernameSubmit(event)
									}
								>
									<div className="row align-items-center justify-content-center g-4">
										<div className="col-xxl-4 col-lg-6 col-md-8">
											<div className="mb-4">
												<label className="form-label fw-medium">
													Kullanıcı Adı
												</label>
												<input
													type="text"
													name="username"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													required
												/>
											</div>
											<div className="d-flex align-items-center justify-content-between">
												<p className="mb-0 fs-7 me-4 text-gray-light">
													Hesabın yok mu?{' '}
													<NavLink
														to="/kayit"
														className="d-xxl-block d-inline text-blue-light text-decoration-none"
													>
														Yeni üye ol
													</NavLink>
												</p>
												<button
													type="submit"
													className="btn btn-orange text-white rounded-pill px-4 py-2 flex-shrink-0"
												>
													Gönder
													<i className="fas fa-arrow-right ms-2" />
												</button>
											</div>
										</div>
									</div>
								</form>
							</>
						)}
						{state.step === 2 && (
							<>
								<div className="row justify-content-center mb-8">
									<div className="col-xxl-4 col-lg-6 col-md-8 text-center">
										<h1 className="text-blue-light mb-3">
											Doğrulama Kodu
										</h1>
										<p className="h5 lh-base mb-0 fw-normal text-gray-light">
											Lütfen telefon numaranıza gönderilen
											4 haneli doğrulama kodunu giriniz.
										</p>
									</div>
								</div>

								<form
									action=""
									onSubmit={(event) =>
										handleConfirmCodeSubmit(event)
									}
								>
									<div className="row align-items-center justify-content-center g-4">
										<div className="col-xxl-4 col-lg-6 col-md-8">
											<div className="mb-4">
												<label className="form-label fw-medium">
													Doğrulama Kodu
												</label>
												<input
													type="text"
													name="confirmCode"
													maxLength={4}
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													required
												/>
											</div>
											<div className="d-flex align-items-center justify-content-between">
												<p className="mb-0 fs-7 me-4 text-red fw-bold">
													Kalan süre:{' '}
													<Countdown
														date={state.time}
														renderer={({
															minutes,
															seconds,
															completed,
														}) => {
															if (completed) {
																setState({
																	...state,
																	step: 1,
																	toast: {
																		type: 'error',
																		message:
																			'Süre doldu, lütfen tekrar deneyin.',
																	},
																});
																return 'Süre doldu';
															}
															return (
																<>
																	{minutes}:
																	{seconds}
																</>
															);
														}}
													/>
												</p>
												<button
													type="submit"
													className="btn btn-orange text-white rounded-pill px-4 py-2 flex-shrink-0"
												>
													Gönder
													<i className="fas fa-arrow-right ms-2" />
												</button>
											</div>
										</div>
									</div>
								</form>
							</>
						)}
						{state.step === 3 && (
							<>
								<div className="row justify-content-center mb-8">
									<div className="col-xxl-4 col-lg-6 col-md-8 text-center">
										<h1 className="text-blue-light mb-3">
											Yeni Parola
										</h1>
										<p className="h5 lh-base mb-0 fw-normal text-gray-light">
											Lütfen bir küçük harf, bir büyük
											harf ve bir rakam içeren en az 8
											karakterli yeni bir parola
											belirleyin.
										</p>
									</div>
								</div>

								<form
									action=""
									onSubmit={(event) =>
										handleNewPassSubmit(event)
									}
								>
									<div className="row align-items-center justify-content-center g-4">
										<div className="col-xxl-4 col-lg-6 col-md-8">
											<div className="mb-4">
												<label className="form-label fw-medium">
													Yeni Parola
												</label>
												<input
													type="password"
													name="newPass"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													minLength={8}
													required
												/>
											</div>
											<div className="mb-4">
												<label className="form-label fw-medium">
													Yeni Parola (Tekrar)
												</label>
												<input
													type="password"
													name="newPassConfirm"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													minLength={8}
													required
												/>
											</div>
											<div className="d-flex justify-content-end">
												<button
													type="submit"
													className="btn btn-orange text-white rounded-pill px-4 py-2 flex-shrink-0"
												>
													Gönder
													<i className="fas fa-arrow-right ms-2" />
												</button>
											</div>
										</div>
									</div>
								</form>
							</>
						)}
					</div>
				</div>

				<Toast
					showToast={state.toast}
					onClose={() =>
						setState({
							...state,
							toast: false,
						})
					}
					type={state.toast?.type}
					message={state.toast?.message}
				/>
				<Loading showLoading={state.showLoading} />
			</main>

			<FooterGeneric />
		</>
	);
};

export default Reset;
