/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */
import constants from '../constants';

const getLoginUserData = async (context, setContext) => {
	try {
		const response = await fetch(`${constants.API_BASE_URL}/getLoginUser`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin?.token}`,
			},
		});

		const responseData = await response.json();

		// Destructure the existing context
		const { dataUser, ...restContext } = context;

		// Merge the updated dataUser
		const updatedDataUser = {
			...dataUser,
			...responseData.data,
		};

		// Update the context with the new dataUser
		setContext({
			...restContext,
			dataUser: updatedDataUser,
		});

		// Update localStorage
		localStorage.setItem(
			'context',
			JSON.stringify({
				...restContext,
				dataUser: updatedDataUser,
			})
		);

		// return responseData.data;
	} catch (error) {
		throw error;
	}
};

export default { getLoginUserData };
