import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import constants from '../../constants';

import Context from '../../stores/Context/Context';

const ProtectedRoute = (props) => {
	const [context] = React.useContext(Context);
	const navigate = useNavigate();

	React.useEffect(() => {
		// console.log(
		// 	`change: /${
		// 		window.location.href.split('/')[
		// 			window.location.href.split('/').length - 1
		// 		]
		// 	}`
		// );

		if (
			localStorage.getItem('cookiePolicy') &&
			localStorage.getItem('targetCookies')
		) {
			// Google Analytics
			ReactGA.initialize(constants.GA_CODE);
			ReactGA.send({
				hitType: 'pageview',
				page: `/${
					window.location.href.split('/')[
						window.location.href.split('/').length - 1
					]
				}`,
				title: `${
					window.location.href.split('/')[
						window.location.href.split('/').length - 1
					]
				}`,
			});

			// const tagManagerArgs = {
			// 	gtmId: constants.GTM_CODE,
			// };

			// // Google TagManager
			// TagManager.initialize(tagManagerArgs);
			// window.dataLayer.push({
			// 	event: 'pageview',
			// 	page: {
			// 		url: `/${
			// 			window.location.href.split('/')[
			// 				window.location.href.split('/').length - 1
			// 			]
			// 		}`,
			// 		title: `${
			// 			window.location.href.split('/')[
			// 				window.location.href.split('/').length - 1
			// 			]
			// 		}`,
			// 	},
			// });
		}
	}, [navigate]);

	if (
		context?.dataLogin?.disclaimerChecked === false &&
		context?.dataUser?.userType !== 'ADMIN'
	) {
		return <Navigate to="/disclaimer" replace />;
	}
	if (['mentee', 'mentor'].includes(context?.dataLogin?.userRole)) {
		return props.children;
	}
	return <Navigate to="/giris" replace />;
};

export default ProtectedRoute;
