import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

const Contact = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [toast, setToast] = React.useState(null);
	const [isShowingLoading, setIsShowingLoading] = React.useState(null);
	const [activeAccordion, setActiveAccordion] = React.useState(null);
	const [subjects, setSubjects] = React.useState(null);

	const navigate = useNavigate();
	const { state } = useLocation();
	const { userRole } = context.dataLogin;

	const [receiverId, setReceiverId] = React.useState(
		state && state.receiver && state.receiver.id ? state.receiver.id : 0
	);

	const getSubjects = () => {
		setIsShowingLoading(true);
		fetch(`${constants.API_BASE_URL}/contactSubjectList`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					setIsShowingLoading(false);
					setSubjects(response.data);
				} else {
					setIsShowingLoading(false);
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				setIsShowingLoading(false);
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	React.useEffect(() => {
		getSubjects();
	}, []);

	const handleInitialSubmit = (event) => {
		event.preventDefault();
		setIsShowingLoading(true);
		fetch(`${constants.API_BASE_URL}/contactMessageInitial/save`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				message: Object.fromEntries(new FormData(event.target)).message,
				contactSubjectText: Object.fromEntries(
					new FormData(event.target)
				).subject,
				receiverUserId: Object.fromEntries(new FormData(event.target))
					.id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					setIsShowingLoading(false);
					setToast({
						type: 'bg-green',
						message: 'Mesaj gönderildi.',
					});
					window.history.replaceState({}, document.title);
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setIsShowingLoading(false);
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				setIsShowingLoading(false);
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	const handleSubmit = (event, id) => {
		event.preventDefault();
		fetch(`${constants.API_BASE_URL}/contactMessage/save`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				message: Object.fromEntries(new FormData(event.target)).message,
				contactSubjectId: id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					getSubjects();
					event.target.reset();
				} else {
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	return (
		<main>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-9">
						<div className="d-flex align-items-center mb-5">
							<h1 className="mb-0 text-blue-light">İletişim</h1>
							{!state && (
								<div className="d-flex align-items-center ms-auto">
									<button
										type="button"
										className="btn btn-orange text-white rounded-pill px-4 py-2"
										onClick={() =>
											navigate('/iletisim', {
												state: {
													receiver: {
														id: 0,
														name: 'Yönetici',
													},
												},
											})
										}
									>
										Yeni Mesaj
										<i className="fas fa-plus ms-2" />
									</button>
								</div>
							)}
						</div>
					</div>
				</div>

				{state ? (
					<div className="row justify-content-center">
						<div className="col-lg-9">
							<form
								onSubmit={(event) => {
									handleInitialSubmit(event);
								}}
							>
								<div className="bg-gray-lightest rounded rounded-2 mb-2 border-0">
									<div className="d-block text-reset text-decoration-none p-4">
										<div className="row align-items-center g-3">
											<div className="col">
												<div className="d-flex align-items-center">
													<div
														className="bg-blue text-white rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
														style={{
															width: 48,
															height: 48,
														}}
													>
														<i className="fas fa-envelope" />
													</div>
													<div className="ms-3 overflow-hidden d-flex align-items-center">
														<span className="h6 mb-0 me-3 flex-shrink-0">
															Alıcı seçiniz
														</span>
														<select
															value={receiverId}
															name="id"
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															style={{
																minWidth: 250,
															}}
															onChange={(
																event
															) => {
																setReceiverId(
																	event.target
																		.value
																);
															}}
														>
															<option value="0">
																Yönetici
															</option>
															{userRole ===
																'mentee' &&
																context.dataUser
																	.matchedUser && (
																	<option
																		value={
																			context
																				.dataUser
																				.matchedUser
																				.id
																		}
																	>
																		Mentor -{' '}
																		{context
																			.dataUser
																			.matchedUser
																			.name +
																			context
																				.dataUser
																				.matchedUser
																				.surname}
																	</option>
																)}
															{userRole ===
																'mentor' &&
																context.dataUser
																	.mentorDTOList &&
																context.dataUser.mentorDTOList.map(
																	(item) => (
																		<option
																			value={
																				item.id
																			}
																		>
																			Öğrenci
																			-{' '}
																			{`${item.name} ${item.surname}`}
																		</option>
																	)
																)}
														</select>
													</div>
													<button
														type="button"
														className="btn btn-link text-red fs-7 fw-semibold ms-auto"
														onClick={() => {
															window.history.replaceState(
																{},
																document.title
															);
															window.location.reload();
														}}
													>
														İptal
													</button>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div className="p-4 pt-0">
											<div className="bg-white border border-2 border-gray-lighter rounded rounded-2 p-3 d-flex mb-3">
												<input
													type="text"
													name="subject"
													className="form-control flex-fill border-0 p-0 rounded-0"
													placeholder="Başlık"
												/>
											</div>
											<div className="bg-white border border-2 border-gray-lighter rounded rounded-2 p-3 d-flex mb-3">
												<textarea
													rows={3}
													name="message"
													className="form-control flex-fill border-0 p-0 rounded-0"
													placeholder="Mesaj"
												/>
											</div>
											<div className="text-end">
												<button
													type="submit"
													className="btn border border-2 border-orange text-orange rounded-pill px-3 py-2 fs-6"
												>
													Gönder
												</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				) : subjects && Object.keys(subjects).length ? (
					<div className="row justify-content-center">
						<div className="col-lg-9">
							<div className="accordion accordion-flush">
								{subjects?.map((subject, index) => (
									<div
										className="accordion-item bg-gray-lightest rounded rounded-2 mb-2 border-0"
										key={subject.id}
									>
										<button
											type="button"
											className="btn w-100 text-start d-block text-reset text-decoration-none p-4"
											onClick={() => {
												if (
													activeAccordion ===
													subject.id
												) {
													setActiveAccordion(null);
												} else {
													setActiveAccordion(
														subject.id
													);
													fetch(
														`${constants.API_BASE_URL}/contactMessageList/${subject.id}`,
														{
															method: 'GET',
															headers: {
																'Content-Type':
																	'application/json',
																Authorization: `Bearer ${context.dataLogin.token}`,
															},
														}
													).then(() => {
														getSubjects();
													});
												}
											}}
										>
											<div className="row align-items-center g-3">
												<div className="col">
													<div className="d-flex align-items-center">
														<div
															className={`text-white rounded-circle d-flex justify-content-center align-items-center flex-shrink-0 ${
																subject.listMessage &&
																subject.listMessage.filter(
																	(message) =>
																		message.unreadMessage &&
																		message
																			.sender
																			.id !==
																			context
																				.dataUser
																				.id
																).length > 0
																	? 'bg-green'
																	: 'bg-blue'
															}`}
															style={{
																width: 48,
																height: 48,
															}}
														>
															{subject.listMessage &&
															subject.listMessage.filter(
																(message) =>
																	message.unreadMessage &&
																	message
																		.sender
																		.id !==
																		context
																			.dataUser
																			.id
															).length > 0 ? (
																<i className="fas fa-envelope" />
															) : (
																<i className="fas fa-envelope-open" />
															)}
														</div>
														<div className="ms-3 overflow-hidden">
															<div className="d-flex align-items-center">
																<h6 className="mb-0">
																	{
																		subject.subject
																	}
																</h6>
																{subject.listMessage &&
																	subject.listMessage.filter(
																		(
																			message
																		) =>
																			message.unreadMessage &&
																			message
																				.sender
																				.id !==
																				context
																					.dataUser
																					.id
																	).length >
																		0 && (
																		<div className="bg-green text-white fs-8 rounded-pill px-2 ms-3">
																			Yeni
																		</div>
																	)}
															</div>
															<p className="mb-0 fs-7 mt-1 d-lg-block d-none text-truncate">
																{
																	subject
																		.sender
																		?.name
																}{' '}
																{
																	subject
																		.sender
																		?.surname
																}
															</p>
														</div>
														<span className="ms-auto fs-8 fw-medium opacity-75">
															{subject.listMessage &&
																moment(
																	subject
																		.listMessage[0]
																		.date
																).format(
																	'DD.MM.YYYY HH:mm'
																)}
														</span>
													</div>
												</div>
											</div>
										</button>
										<div
											id={`item-${index}`}
											className={`accordion-collapse collapse ${
												activeAccordion ===
													subject.id && 'show'
											}`}
										>
											<div className="p-4 pt-0">
												<div
													className="d-flex flex-column flex-column-reverse mb-3"
													style={{
														height: 300,
														overflowY: 'scroll',
													}}
												>
													{subject.listMessage &&
														subject.listMessage.map(
															(message) => (
																<div
																	className={
																		message
																			.sender
																			.id ===
																		context
																			.dataUser
																			.id
																			? 'bg-green-lightest p-3 rounded rounded-2 fs-7 text-green lh-lg align-self-end mb-3'
																			: 'bg-blue-lightest p-3 rounded rounded-2 fs-7 text-blue-light lh-lg mb-3'
																	}
																	style={{
																		maxWidth:
																			'75%',
																	}}
																	key={
																		message.id
																	}
																>
																	{
																		message.message
																	}
																	<span className="d-block fs-9 opacity-50">
																		{moment(
																			message.date
																		).format(
																			'DD.MM.YYYY HH:mm'
																		)}
																	</span>
																</div>
															)
														)}
												</div>
												<form
													className="bg-white border border-2 border-gray-lighter rounded rounded-2 p-3 d-flex"
													onSubmit={(event) => {
														handleSubmit(
															event,
															subject.id
														);
													}}
												>
													<input
														type="text"
														name="message"
														className="form-control flex-fill border-0 p-0 rounded-0"
														placeholder="Mesajınız"
													/>
													<button
														type="submit"
														className="btn border border-2 border-orange text-orange rounded-pill px-3 py-1 fs-7 ms-3 d-lg-inline d-none flex-shrink-0"
													>
														Gönder
													</button>
												</form>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				) : (
					<div className="flex-fill rounded-2 d-flex mt-4 flex-column justify-content-center align-items-center text-blue-light text-center">
						<i className="fa-solid fa-envelope h1 mb-3" />
						<h6 className="mb-0 lh-base">
							Hiç mesajınız bulunmuyor.
						</h6>
					</div>
				)}
			</div>

			<Toast
				showToast={toast}
				onClose={() => setToast(null)}
				type={toast?.type}
				message={toast?.message}
			/>
			<Loading showLoading={isShowingLoading} />
		</main>
	);
};

export default Contact;
