/* eslint-disable react/no-array-index-key */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';
import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';

const AdminApprovalsMemberships = (props) => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		showLoading: false,
	});

	const handleDenied = (e, id) => {
		e.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/deniedUserRequest/${id}`, {
			method: 'POST',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					// Loader Hide
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Kullanıcı profili reddedildi!',
						},
					});

					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleApproved = (e, id, userType) => {
		e.preventDefault();
		let programId = null;

		e.target
			.closest('tr')
			.querySelectorAll('select > option')
			.forEach((item) => {
				if (item.selected) {
					programId = parseInt(item.value, 10);
				}
			});

		let data = {
			id,
			programDTO: {
				id: programId,
			},
		};

		if (userType === 'MENTOR') {
			data = {
				id,
			};
		}

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/approveUser`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					setState({ ...state, showLoading: true });
					fetch(`${constants.API_BASE_URL}/admin/welcomeMail`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${context.dataLogin.token}`,
						},
						body: JSON.stringify({ userId: response.data }),
					})
						.then((res) => res.json())
						.then((res) => {
							if (res) {
								// Loader Hide
								setState({
									...state,
									showLoading: false,
									toast: {
										type: 'success',
										message:
											'Giriş Bilgileriniz Mail Olarak Gönderildi!',
									},
								});

								setInterval(() => {
									window.location.reload();
								}, 2000);
							} else {
								// Loader Hide
								setState({
									...state,
									showLoading: false,
								});
							}
						});
				} else {
					// Loader Hide
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<div className="table-responsive">
			{/* {!props?.userList?.content && <Loading showLoading />} */}

			<Loading showLoading={state.showLoading} />

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>

			<table className="table table-striped">
				<thead>
					<tr className="fs-7">
						<th scope="col" className="align-middle text-gray">
							Tür
						</th>
						<th scope="col" className="align-middle text-gray">
							İsim
						</th>
						<th scope="col" className="align-middle text-gray">
							Soyisim
						</th>
						<th scope="col" className="align-middle text-gray">
							E-Posta
						</th>
						<th scope="col" className="align-middle text-gray">
							Telefon Numarası
						</th>
						<th scope="col" className="align-middle text-gray">
							Program
						</th>
						<th
							scope="col"
							className="align-middle text-gray text-center"
						>
							İşlem
						</th>
					</tr>
				</thead>
				<tbody className="fs-7">
					{props?.userList?.totalElements === 0 && (
						<tr>
							<td colSpan="8" className="text-center">
								Gösterilecek Sonuç Bulunamadı!
							</td>
						</tr>
					)}

					{props?.userList?.content &&
						props.userList.content.length > 0 &&
						props.userList.content.map((item) => (
							<tr key={item.id}>
								<td className="align-middle text-gray">
									{item.userType && item.userType === 'MENTEE'
										? 'Öğrenci'
										: 'Mentor'}
								</td>
								<td className="align-middle text-gray">
									{item.name && item.name}
								</td>
								<td className="align-middle text-gray">
									{item.surname && item.surname}
								</td>
								<td className="align-middle text-gray">
									{item.email && item.email}
								</td>
								<td className="align-middle text-gray">
									{item.phoneNumber && item.phoneNumber}
								</td>
								<td className="align-middle text-gray">
									{item.userType === 'MENTEE' && (
										<select className="form-select border-2 border-gray-lighter py-1 px-2 lh-sm fs-7">
											{props &&
												props.programList &&
												props.programList.length > 0 &&
												props.programList.map(
													(program) => (
														<option
															key={
																program.id &&
																program.id
															}
															value={
																program.id &&
																program.id
															}
														>
															{program.name &&
																program.name}
														</option>
													)
												)}
										</select>
									)}
								</td>

								<td
									className="align-middle text-gray text-center"
									style={{ width: '200px' }}
								>
									<button
										type="button"
										className="btn border border-2 border-red text-red rounded-pill px-3 py-1 fs-7 me-sm-2 me-0"
										onClick={(e) =>
											handleDenied(e, item.id)
										}
									>
										Reddet
									</button>
									<button
										type="button"
										className="btn border border-2 border-green bg-green text-white rounded-pill px-3 py-1 fs-7 me-sm-2 me-0"
										onClick={(e) =>
											handleApproved(
												e,
												item.id,
												item.userType
											)
										}
									>
										Onayla
									</button>
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};

const AdminApprovalsProfiles = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [waitingUserList, setWaitingUserList] = React.useState([]); //eslint-disable-line
	const [programList, setProgramList] = React.useState(''); //eslint-disable-line
	const [state, setState] = React.useState({
		showLoading: false,
		activeUser: null,
	});

	const navigate = useNavigate();

	const [showModal, setShowModal] = React.useState(false);
	const [dataProvinces, setDataProvinces] = React.useState(null);

	const [universities, setUniversities] = React.useState(null);
	const [faculties, setFaculties] = React.useState(null);
	const [departments, setDepartments] = React.useState(null);

	const getAllProvince = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/getAllProvince`, {
			method: 'GET',
			headers: constants.API_HEADERS_PUBLIC,
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data) {
					setDataProvinces(response.data);
				}

				// Loader Hide
				setState({
					...state,
					showLoading: false,
				});
			});
	};

	// Universities
	const getUniversities = () => {
		// Loader
		setState({ ...state, showLoading: true });

		// Get Universities
		fetch(`${constants.API_BASE_URL}/public/getUniversityNameAll`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((university) => university.json())
			.then((university) => {
				if (university.data) {
					setState({
						...state,
						showLoading: true,
					});

					setUniversities(university.data);
				} else {
					setUniversities('');
				}
			});
	};

	// Faculty
	const getUniversityFaculty = (universityId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getFacultyAll/${universityId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								universityDTO: {
									...context?.modalData?.educationInfoDTO
										?.universityDTO,
									id: universityId,
								},
							},
						},
						universityFacultyList: response.data,
						universityDepartmentList: undefined,
					});

					setFaculties(response.data);
					setDepartments('');
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setFaculties('');
					setDepartments('');
				}
			});
	};

	// Faculty 2
	const getUniversityFaculty2 = (universityId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getFacultyAll/${universityId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});
					setFaculties(response.data);
					setDepartments('');
				} else {
					setState({
						...state,
						showLoading: false,
					});
					setFaculties('');
					setDepartments('');
				}
			});
	};

	// Department
	const getUniversityDepartment = (facultyId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${facultyId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setDepartments(response.data);
				} else {
					setDepartments('');

					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	const handleGetAllUniversityFaculty = (e, universityId) => {
		e.preventDefault();

		if (universityId !== '') {
			getUniversityFaculty(universityId);
		} else {
			setFaculties('');
			setDepartments('');
		}
	};

	const handleGetAllUniversityDepartment = (e, facultyId) => {
		e.preventDefault();

		if (facultyId !== '') {
			getUniversityDepartment(facultyId);
		} else {
			setDepartments('');
		}
	};

	// useEffect
	React.useEffect(() => {
		// ALL PROVINCES
		getAllProvince();

		// ALL UNIVERSITIES
		getUniversities();
	}, []);

	// useEffect
	React.useEffect(() => {
		if (context?.modalData?.educationInfoDTO?.universityDTO?.id) {
			getUniversityFaculty2(
				context?.modalData?.educationInfoDTO?.universityDTO?.id
			);
		}
	}, [context?.modalData?.educationInfoDTO?.universityDTO?.id]);

	// useEffect
	React.useEffect(() => {
		if (context?.modalData?.educationInfoDTO?.faculty?.id) {
			getUniversityDepartment(
				context?.modalData?.educationInfoDTO?.faculty?.id
			);
		}
	}, [context?.modalData?.educationInfoDTO?.faculty?.id && faculties]);

	const getUserStatusWaiting = () => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/admin/getUserStatusWaiting?size=9999`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data) {
					setWaitingUserList(response.data);
					fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
						method: 'GET',
						headers: new Headers({
							Authorization: `Bearer ${context.dataLogin.token}`,
							'Content-Type': constants.API_HEADERS_PUBLIC,
						}),
					})
						.then((resp) => resp.json())
						.then((resp) => {
							setState({
								...state,
								showLoading: false,
							});

							if (resp && resp.content) {
								setProgramList(resp.content);
							}
						});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const getUserById = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/getUser/${id}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						modalData: response.data,
						modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
						getUserClicked: true,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const showUserProfile = (e, id) => {
		e.preventDefault();

		getUserById(id);
		setShowModal(true);
	};

	// Use Effect
	React.useEffect(() => {
		getUserStatusWaiting();
	}, []);

	const handleApprovedWaiting = (e, id) => {
		e.preventDefault();

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/loginCard/confirm/${id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify(),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Giriş Kartı Onaylandı!',
						},
					});

					setTimeout(() => {
						getUserStatusWaiting();
					}, 1000);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleUserDeny = (id, result) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/loginCard/reject`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				id,
				rejectedResult: result,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModalDeny: false,
						denyUserId: null,
						denyUserResult: null,
						toast: {
							type: 'success',
							message: 'Reddedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModalDeny: false,
						denyUserId: null,
						denyUserResult: null,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					showModal: false,
					showModalDeny: false,
					denyUserId: null,
					denyUserResult: null,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<>
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr className="fs-7">
							<th scope="col" className="align-middle text-gray">
								Tür
							</th>
							<th scope="col" className="align-middle text-gray">
								İsim Soyisim
							</th>
							<th scope="col" className="align-middle text-gray">
								Profil Durumu
							</th>
							<th
								scope="col"
								className="align-middle text-gray text-center"
							>
								İşlem
							</th>
						</tr>
					</thead>
					<tbody className="fs-7">
						{waitingUserList.content &&
						waitingUserList.content.length > 0 ? (
							waitingUserList.content.map((item) => (
								<tr key={item.id}>
									<td className="align-middle text-gray">
										{item.userType &&
										item.userType === 'MENTEE'
											? 'Öğrenci'
											: 'Mentor'}
									</td>
									<td className="align-middle text-gray">
										<a
											href="#"
											onClick={(e) =>
												showUserProfile(e, item.id)
											}
										>
											{`${item.name} ${item.surname}`}
										</a>
									</td>
									<td>
										{item.success === true ? (
											<i
												className="fa-solid fa-check"
												style={{ paddingLeft: '30px' }}
											/>
										) : (
											<i
												className="fa-solid fa-times"
												style={{ paddingLeft: '30px' }}
											/>
										)}
									</td>
									<td
										className="align-middle text-gray text-center"
										style={{ width: '200px' }}
									>
										<button
											type="button"
											className="btn border border-2 border-red text-red rounded-pill px-3 py-1 fs-7 me-sm-2 me-0"
											onClick={() => {
												setState({
													...state,
													showModalDeny: true,
													denyUserId: item.id,
												});
											}}
										>
											Reddet
										</button>
										<button
											type="button"
											className="btn border border-2 border-green bg-green text-white rounded-pill px-3 py-1 fs-7 me-0"
											onClick={(e) =>
												handleApprovedWaiting(
													e,
													item.id
												)
											}
										>
											Onayla
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="8" className="text-center">
									Gösterilecek Sonuç Bulunamadı!
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			<Modal
				showModal={showModal}
				onClose={() => setShowModal(false)}
				header={context.modalDataHeader}
				size="lg"
			>
				<style>
					{`
						.accordion input, .accordion select, .accordion textarea {
							border: none !important;
							pointer-events: none;
							background: none !important;
							appearance: none;
						}
						.accordion-body > div {
							border-bottom: 2px solid #FAFAFA;
							padding-bottom: 8px;
							margin-bottom: 8px !important;
						}
						`}
				</style>
				<div className="modal-body p-4">
					<div className="accordion" id="accordionProfile">
						{/* Kişisel Bilgiler  */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-1"
								>
									<h5 className="text-orange mb-0">
										Kişisel Bilgiler
									</h5>
								</button>
							</h2>
							<div
								id="group-1"
								className="accordion-collapse collapse show"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									{context?.modalData?.profileImage && (
										<div className="mb-3">
											<div className="row align-items-center">
												<div className="col-4">
													<h6 className="mb-0">
														Aktif Avatar
													</h6>
												</div>
												<div className="col-8">
													<img
														src={`https://${context?.modalData?.profileImage}`}
														className="rounded-circle"
														alt="Aktif Avatar"
														style={{
															width: '80px',
															height: '80px',
														}}
													/>
												</div>
											</div>
										</div>
									)}

									{context?.modalData
										?.profileImagePassive && (
										<div className="mb-3">
											<div className="row align-items-center">
												<div className="col-4">
													<h6 className="mb-0">
														Onay Bekleyen Avatar
													</h6>
												</div>
												<div className="col-8">
													<img
														src={`https://${context?.modalData?.profileImagePassive}`}
														className="rounded-circle"
														alt="Onay Bekleyen Avatar"
														style={{
															width: '80px',
															height: '80px',
														}}
													/>
												</div>
											</div>
										</div>
									)}

									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">İsim</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													readOnly
													value={
														context?.modalData
															?.name &&
														context.modalData.name
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																name: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Soyisim
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													readOnly
													value={
														context?.modalData
															?.surname &&
														context.modalData
															.surname
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																surname:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">TCKN</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													readOnly
													value={
														context?.modalData
															?.identityNo &&
														context.modalData
															.identityNo
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																identityNo:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Doğum Tarihi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="date"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													readOnly
													value={
														context?.modalData
															?.birthday &&
														context.modalData
															.birthday
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																birthday:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Cinsiyet
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.gender &&
														context.modalData.gender
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													disabled
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																gender: event
																	.target
																	.value,
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="MAN">
														Erkek
													</option>
													<option value="WOMAN">
														Kadın
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Iban</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.iban &&
														context.modalData.iban
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																iban: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İletişim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-2"
								>
									<h5 className="text-orange mb-0">
										İletişim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-2"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Telefon Numarası
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													readOnly
													value={
														context?.modalData
															?.phoneNumber &&
														context.modalData
															.phoneNumber
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																phoneNumber:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													E-Posta Adresi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													readOnly
													value={
														context?.modalData
															?.email &&
														context.modalData.email
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																email: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{context?.modalData?.userType &&
							context.modalData.userType === 'MENTEE' && (
								<>
									{/* Aile Bilgileri */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-22"
											>
												<h5 className="text-orange mb-0">
													Aile Bilgileri
												</h5>
											</button>
										</h2>
										<div
											id="group-22"
											className="accordion-collapse collapse"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Aile Durumu
															</h6>
														</div>
														<div className="col-8">
															<select
																value={
																	context
																		?.modalData
																		?.familyInfoDTO
																		?.familyStatus &&
																	context
																		.modalData
																		.familyInfoDTO
																		.familyStatus
																}
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				familyInfoDTO:
																					{
																						...context
																							?.modalData
																							?.familyInfoDTO,
																						familyStatus:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																<option value="MRFR">
																	Anne Sağ,
																	Baba Sağ
																</option>
																<option value="MRFN">
																	Anne Sağ,
																	Baba Yok
																</option>
																<option value="MNFR">
																	Anne Yok,
																	Baba Sağ
																</option>
																<option value="MNFN">
																	Anne Yok,
																	Baba Yok
																</option>
																<option value="DIVORCED">
																	Boşanmışlar
																</option>
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Aile Şehri
															</h6>
														</div>
														<div className="col-8">
															<select
																value={
																	context
																		?.modalData
																		?.familyInfoDTO
																		?.familyCity
																		?.id &&
																	context
																		.modalData
																		.familyInfoDTO
																		.familyCity
																		.id
																}
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				familyInfoDTO:
																					{
																						...context
																							?.modalData
																							?.familyInfoDTO,
																						familyCity:
																							{
																								...context
																									?.modalData
																									?.familyInfoDTO
																									?.familyCity,
																								id: event
																									.target
																									.value,
																							},
																					},
																			},
																	})
																}
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{dataProvinces &&
																	dataProvinces.length >
																		0 &&
																	dataProvinces.map(
																		(
																			province
																		) => (
																			<option
																				key={
																					province.id &&
																					province.id
																				}
																				value={
																					province.id &&
																					province.id
																				}
																			>
																				{province.name &&
																					province.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																İletişim
																Kurulacak Kişi
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	context
																		?.modalData
																		?.familyInfoDTO
																		?.parentComName &&
																	context
																		.modalData
																		.familyInfoDTO
																		.parentComName
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				familyInfoDTO:
																					{
																						...context
																							?.modalData
																							?.familyInfoDTO,
																						parentComName:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																İletişim
																Kurulacak Kişi
																Telefon
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	context
																		?.modalData
																		?.familyInfoDTO
																		?.parentComTel &&
																	context
																		.modalData
																		.familyInfoDTO
																		.parentComTel
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				familyInfoDTO:
																					{
																						...context
																							?.modalData
																							?.familyInfoDTO,
																						parentComTel:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* Eğitim Bilgileri */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-3"
											>
												<h5 className="text-orange mb-0">
													Eğitim Bilgileri
												</h5>
											</button>
										</h2>
										<div
											id="group-3"
											className="accordion-collapse collapse"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Lise Adı
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																readOnly
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.highSchoolName &&
																	context
																		.modalData
																		.educationInfoDTO
																		.highSchoolName
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						highSchoolName:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Üniversite Adı
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																disabled
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.universityDTO
																		?.id &&
																	context
																		.modalData
																		.educationInfoDTO
																		.universityDTO
																		.id
																}
																onChange={(
																	event
																) => {
																	handleGetAllUniversityFaculty(
																		event,
																		event
																			.target
																			.value
																	);
																}}
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{universities &&
																	universities.length >
																		0 &&
																	universities.map(
																		(
																			university
																		) => (
																			<option
																				key={
																					university.id &&
																					university.id
																				}
																				value={
																					university.id &&
																					university.id
																				}
																			>
																				{university.name &&
																					university.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Fakülte
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.faculty
																		?.id &&
																	context
																		.modalData
																		.educationInfoDTO
																		.faculty
																		.id
																}
																onChange={(
																	event
																) => {
																	handleGetAllUniversityDepartment(
																		event,
																		event
																			.target
																			.value
																	);

																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						faculty:
																							{
																								...context
																									?.modalData
																									?.educationInfoDTO
																									?.faculty,
																								id: event
																									.target
																									.value,
																							},
																					},
																			},
																	});
																}}
																disabled
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{faculties &&
																	faculties.length >
																		0 &&
																	faculties.map(
																		(
																			university
																		) => (
																			<option
																				key={
																					university.id &&
																					university.id
																				}
																				value={
																					university.id &&
																					university.id
																				}
																			>
																				{university.name &&
																					university.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Bölüm
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.universityDepartment
																		?.id &&
																	context
																		.modalData
																		.educationInfoDTO
																		.universityDepartment
																		.id
																}
																onChange={(
																	event
																) => {
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						universityDepartment:
																							{
																								...context
																									?.modalData
																									?.educationInfoDTO
																									?.universityDepartment,
																								id: event
																									.target
																									.value,
																							},
																					},
																			},
																	});
																}}
																disabled
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{departments &&
																	departments.length >
																		0 &&
																	departments.map(
																		(
																			department
																		) => (
																			<option
																				key={
																					department.id &&
																					department.id
																				}
																				value={
																					department.id &&
																					department.id
																				}
																			>
																				{department.name &&
																					department.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>

												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Sınıf
															</h6>
														</div>
														<div className="col-8">
															<select
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.universityClass &&
																	context
																		.modalData
																		.educationInfoDTO
																		.universityClass
																}
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						universityClass:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																<option value="PRE">
																	Hazırlık
																</option>
																<option value="ONE">
																	1.Sınıf
																</option>
																<option value="TWO">
																	2.Sınıf
																</option>
																<option value="THREE">
																	3.Sınıf
																</option>
																<option value="FOUR">
																	4.Sınıf
																</option>
																<option value="FIVE">
																	5.Sınıf
																</option>
																<option value="SIX">
																	6.Sınıf
																</option>
																<option value="GRADUATE">
																	Mezun
																</option>
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Başlangıç Yılı
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.universityStartDate &&
																	context
																		.modalData
																		.educationInfoDTO
																		?.universityStartDate
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						universityStartDate:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Üniversite Şehri
															</h6>
														</div>
														<div className="col-8">
															<select
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.universityCity
																		?.id &&
																	context
																		.modalData
																		.educationInfoDTO
																		.universityCity
																		.id
																}
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																onChange={(
																	event
																) => {
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						universityCity:
																							{
																								...context
																									?.modalData
																									?.educationInfoDTO
																									?.universityCity,
																								id: event
																									.target
																									.value,
																							},
																					},
																			},
																	});
																}}
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{dataProvinces?.length >
																	0 &&
																	dataProvinces.map(
																		(
																			province
																		) => (
																			<option
																				key={
																					province.id &&
																					province.id
																				}
																				value={
																					province.id &&
																					province.id
																				}
																			>
																				{province.name &&
																					province.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Kaldığı Yer
															</h6>
														</div>
														<div className="col-8">
															<select
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.accommodationStatus &&
																	context
																		.modalData
																		.educationInfoDTO
																		.accommodationStatus
																}
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						accommodationStatus:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																<option value="HOME">
																	Ev
																</option>
																<option value="FAMILY">
																	Aile
																</option>
																<option value="RELATIVE">
																	Akraba
																</option>
																<option value="GOVDORM">
																	Devlet Yurdu
																</option>
																<option value="PRIVDORM">
																	Özel Yurt
																</option>
																<option value="UNIDORM">
																	Üniversite
																	Yurdu
																</option>
															</select>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* Transkript Bilgileri */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-5"
											>
												<h5 className="text-orange mb-0">
													Transkript Bilgileri
												</h5>
											</button>
										</h2>
										<div
											id="group-5"
											className="accordion-collapse collapse"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												{/* Genel */}
												<div className="mb-5">
													<h5 className="text-blue-light mb-4">
														Genel
													</h5>
													<div className="mb-3">
														<div className="row align-items-center">
															<div className="col-4">
																<h6 className="mb-0">
																	Genel
																	Ortalama
																</h6>
															</div>
															<div className="col-8">
																<input
																	type="text"
																	className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																	placeholder="Lütfen giriniz"
																	value={
																		context
																			?.modalData
																			?.transcriptDTO
																			?.endOfYearAverage &&
																		context
																			.modalData
																			.transcriptDTO
																			.endOfYearAverage
																	}
																	onChange={(
																		event
																	) =>
																		setContext(
																			{
																				...context,
																				modalData:
																					{
																						...context?.modalData,
																						transcriptDTO:
																							{
																								...context
																									?.modalData
																									?.transcriptDTO,
																								endOfYearAverage:
																									event
																										.target
																										.value,
																							},
																					},
																			}
																		)
																	}
																/>
															</div>
														</div>
													</div>
													<div className="mb-3">
														<div className="row align-items-center">
															<div className="col-4">
																<h6 className="mb-0">
																	Genel Kalan
																	Ders
																</h6>
															</div>
															<div className="col-8">
																<input
																	type="text"
																	className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																	placeholder="Lütfen giriniz"
																	value={
																		context
																			?.modalData
																			?.transcriptDTO
																			?.endOfYearFailedLesson &&
																		context
																			.modalData
																			.transcriptDTO
																			.endOfYearFailedLesson
																	}
																	onChange={(
																		event
																	) =>
																		setContext(
																			{
																				...context,
																				modalData:
																					{
																						...context?.modalData,
																						transcriptDTO:
																							{
																								...context
																									?.modalData
																									?.transcriptDTO,
																								endOfYearFailedLesson:
																									event
																										.target
																										.value,
																							},
																					},
																			}
																		)
																	}
																/>
															</div>
														</div>
													</div>
												</div>

												{/* Hazırlık */}
												{[
													'PRE',
													'ONE',
													'TWO',
													'THREE',
													'FOUR',
													'FIVE',
													'SIX',
													'GRADUATE',
												].includes(
													context?.modalData
														?.educationInfoDTO
														?.universityClass
												) && (
													<div className="mb-5">
														<h5 className="text-blue-light mb-4">
															Hazırlık
														</h5>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Hazırlık
																		Okudu
																		mu?
																	</h6>
																</div>
																<div className="col-8">
																	<select
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.preparetoryInfo &&
																			context
																				.modalData
																				.transcriptDTO
																				.preparetoryInfo
																		}
																		className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									preparetoryInfo:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	>
																		<option value="">
																			Lütfen
																			seçiniz
																		</option>
																		<option value="YES">
																			Evet
																		</option>
																		<option value="NO">
																			Hayır
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Hazırlıktan
																		Geçti
																		mi?
																	</h6>
																</div>
																<div className="col-8">
																	<select
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.preparetoryResultInfo &&
																			context
																				.modalData
																				.transcriptDTO
																				.preparetoryResultInfo
																		}
																		className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									preparetoryResultInfo:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	>
																		<option value="">
																			Lütfen
																			seçiniz
																		</option>
																		<option value="YES">
																			Evet
																		</option>
																		<option value="NO">
																			Hayır
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Hazırlık
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="text"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.preparetoryAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.preparetoryAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									preparetoryAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}

												{/* 1. Sınıf */}
												{[
													'ONE',
													'TWO',
													'THREE',
													'FOUR',
													'FIVE',
													'SIX',
													'GRADUATE',
												].includes(
													context?.modalData
														?.educationInfoDTO
														?.universityClass
												) && (
													<div className="mb-5">
														<h5 className="text-blue-light mb-4">
															1. Sınıf
														</h5>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		min={0}
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.firstTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.firstTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...(context
																										?.modalData
																										?.transcriptDTO &&
																										context
																											.modalData
																											.transcriptDTO),
																									firstTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.firstTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.firstTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									firstTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.secondTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.secondTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									secondTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.secondTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.secondTermFailedLesson
																		}
																		onChange={(
																			event
																		) => {
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									secondTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			);
																		}}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.firstSummerTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.firstSummerTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									firstSummerTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																	</h6>
																</div>
																<div className="col-8">
																	<select
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.firstSummerInfo &&
																			context
																				.modalData
																				.transcriptDTO
																				.firstSummerInfo
																		}
																		className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									firstSummerInfo:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	>
																		<option value="">
																			Lütfen
																			seçiniz
																		</option>
																		<option value="YES">
																			EVET
																		</option>
																		<option value="NO">
																			HAYIR
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.firstSummerTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.firstSummerTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									firstSummerTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.firstYearAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.firstYearAverage
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.firstYearFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.firstYearFailedLesson
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}

												{/* 2. Sınıf */}
												{[
													'TWO',
													'THREE',
													'FOUR',
													'FIVE',
													'SIX',
													'GRADUATE',
												].includes(
													context?.modalData
														?.educationInfoDTO
														?.universityClass
												) && (
													<div className="mb-5">
														<h5 className="text-blue-light mb-4">
															2. Sınıf
														</h5>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.thirdTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.thirdTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...(context
																										?.modalData
																										?.transcriptDTO &&
																										context
																											.modalData
																											.transcriptDTO),
																									thirdTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.thirdTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.thirdTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									thirdTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fourthTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.fourthTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fourthTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fourthTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.fourthTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fourthTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.secondSummerTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.secondSummerTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									secondSummerTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																	</h6>
																</div>
																<div className="col-8">
																	<select
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.secondSummerInfo &&
																			context
																				.modalData
																				.transcriptDTO
																				.secondSummerInfo
																		}
																		className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									secondSummerInfo:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	>
																		<option value="">
																			Lütfen
																			seçiniz
																		</option>
																		<option value="YES">
																			EVET
																		</option>
																		<option value="NO">
																			HAYIR
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.secondSummerTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.secondSummerTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									secondSummerTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.secondYearAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.secondYearAverage
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.secondYearFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.secondYearFailedLesson
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}

												{/* 3. Sınıf */}
												{[
													'THREE',
													'FOUR',
													'FIVE',
													'SIX',
													'GRADUATE',
												].includes(
													context?.modalData
														?.educationInfoDTO
														?.universityClass
												) && (
													<div className="mb-5">
														<h5 className="text-blue-light mb-4">
															3. Sınıf
														</h5>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fifthTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.fifthTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...(context
																										?.modalData
																										?.transcriptDTO &&
																										context
																											.modalData
																											.transcriptDTO),
																									fifthTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fifthTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.fifthTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fifthTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.sixthTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.sixthTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									sixthTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.sixthTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.sixthTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									sixthTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.thirdSummerTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.thirdSummerTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									thirdSummerTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																	</h6>
																</div>
																<div className="col-8">
																	<select
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.thirdSummerInfo &&
																			context
																				.modalData
																				.transcriptDTO
																				.thirdSummerInfo
																		}
																		className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									thirdSummerInfo:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	>
																		<option value="">
																			Lütfen
																			seçiniz
																		</option>
																		<option value="YES">
																			EVET
																		</option>
																		<option value="NO">
																			HAYIR
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.thirdSummerTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.thirdSummerTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									thirdSummerTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.thirdYearAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.thirdYearAverage
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.thirdYearFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.thirdYearFailedLesson
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}

												{/* 4. Sınıf */}
												{[
													'FOUR',
													'FIVE',
													'SIX',
													'GRADUATE',
												].includes(
													context?.modalData
														?.educationInfoDTO
														?.universityClass
												) && (
													<div className="mb-5">
														<h5 className="text-blue-light mb-4">
															4. Sınıf
														</h5>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.seventhTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.seventhTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...(context
																										?.modalData
																										?.transcriptDTO &&
																										context
																											.modalData
																											.transcriptDTO),
																									seventhTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.seventhTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.seventhTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									seventhTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.eighthTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.eighthTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									eighthTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.eighthTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.eighthTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									eighthTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fourthSummerTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.fourthSummerTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fourthSummerTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																	</h6>
																</div>
																<div className="col-8">
																	<select
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fourthSummerInfo &&
																			context
																				.modalData
																				.transcriptDTO
																				.fourthSummerInfo
																		}
																		className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fourthSummerInfo:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	>
																		<option value="">
																			Lütfen
																			seçiniz
																		</option>
																		<option value="YES">
																			EVET
																		</option>
																		<option value="NO">
																			HAYIR
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fourthSummerTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.fourthSummerTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fourthSummerTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fourthYearAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.fourthYearAverage
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fourthYearFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.fourthYearFailedLesson
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}

												{/* 5. Sınıf */}
												{[
													'FIVE',
													'SIX',
													'GRADUATE',
												].includes(
													context?.modalData
														?.educationInfoDTO
														?.universityClass
												) && (
													<div className="mb-5">
														<h5 className="text-blue-light mb-4">
															5. Sınıf
														</h5>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.ninethTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.ninethTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...(context
																										?.modalData
																										?.transcriptDTO &&
																										context
																											.modalData
																											.transcriptDTO),
																									ninethTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.ninethTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.ninethTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									ninethTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.tenthTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.tenthTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									tenthTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.tenthTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.tenthTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									tenthTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fifthSummerTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.fifthSummerTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fifthSummerTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																	</h6>
																</div>
																<div className="col-8">
																	<select
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fifthSummerInfo &&
																			context
																				.modalData
																				.transcriptDTO
																				.fifthSummerInfo
																		}
																		className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fifthSummerInfo:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	>
																		<option value="">
																			Lütfen
																			seçiniz
																		</option>
																		<option value="YES">
																			EVET
																		</option>
																		<option value="NO">
																			HAYIR
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fifthSummerTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.fifthSummerTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									fifthSummerTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fifthYearAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.fifthYearAverage
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.fifthYearFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.fifthYearFailedLesson
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}

												{/* 6. Sınıf */}
												{['SIX', 'GRADUATE'].includes(
													context?.modalData
														?.educationInfoDTO
														?.universityClass
												) && (
													<div>
														<h5 className="text-blue-light mb-4">
															6. Sınıf
														</h5>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.eleventhTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.eleventhTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...(context
																										?.modalData
																										?.transcriptDTO &&
																										context
																											.modalData
																											.transcriptDTO),
																									eleventhTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Güz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.eleventhTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.eleventhTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									eleventhTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.twelfthTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.twelfthTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									twelfthTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Bahar
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.twelfthTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.twelfthTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									twelfthTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.sixthSummerTermAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.sixthSummerTermAverage
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									sixthSummerTermAverage:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																	</h6>
																</div>
																<div className="col-8">
																	<select
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.sixthSummerInfo &&
																			context
																				.modalData
																				.transcriptDTO
																				.sixthSummerInfo
																		}
																		className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									sixthSummerInfo:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	>
																		<option value="">
																			Lütfen
																			seçiniz
																		</option>
																		<option value="YES">
																			EVET
																		</option>
																		<option value="NO">
																			HAYIR
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yaz
																		Dönemi
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.sixthSummerTermFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.sixthSummerTermFailedLesson
																		}
																		onChange={(
																			event
																		) =>
																			setContext(
																				{
																					...context,
																					modalData:
																						{
																							...context?.modalData,
																							transcriptDTO:
																								{
																									...context
																										?.modalData
																										?.transcriptDTO,
																									sixthSummerTermFailedLesson:
																										event
																											.target
																											.value,
																								},
																						},
																				}
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Ortalaması
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.sixthYearAverage &&
																			context
																				.modalData
																				.transcriptDTO
																				.sixthYearAverage
																		}
																	/>
																</div>
															</div>
														</div>
														<div className="mb-3">
															<div className="row align-items-center">
																<div className="col-4">
																	<h6 className="mb-0">
																		Yıl Sonu
																		Kalan
																		Ders
																	</h6>
																</div>
																<div className="col-8">
																	<input
																		readOnly
																		type="number"
																		className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																		placeholder="Lütfen giriniz"
																		value={
																			context
																				?.modalData
																				?.transcriptDTO
																				?.sixthYearFailedLesson &&
																			context
																				.modalData
																				.transcriptDTO
																				.sixthYearFailedLesson
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>

									{/* Belgeler */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-6"
											>
												<h5 className="text-orange mb-0">
													Belgeler
												</h5>
											</button>
										</h2>
										<div
											id="group-6"
											className="accordion-collapse collapse"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Öğrenci Belgesi
															</h6>
														</div>
														<div className="col-8">
															<input
																type="file"
																accept="application/pdf"
																name="NewCertificateDocument"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																onChange={(
																	event
																) => {
																	if (
																		event
																			?.target
																			?.files?.[0]
																	) {
																		setContext(
																			{
																				...context,
																				modalData:
																					{
																						...context?.modalData,
																					},
																				NewCertificateDocument:
																					event
																						.target
																						.files[0],
																			}
																		);
																	}
																}}
															/>
															<div className="row d-flex align-items-center">
																<div className="col-12 col-md-6">
																	{context
																		?.modalData
																		?.certificateDocument &&
																		!context.NewCertificateDocument && (
																			<a
																				href={`https://${context.modalData.certificateDocument}`}
																				target="_blank"
																				rel="noreferrer"
																				className="my-3 text-decoration-none fs-7 text-green d-block"
																			>
																				Öğrenci
																				Belgesi{' '}
																				<i
																					className="fas fa-check-circle ms-2"
																					style={{
																						width: 16,
																					}}
																				/>
																			</a>
																		)}
																</div>

																<div className="col-12 col-md-6">
																	{context
																		?.modalData
																		?.certificateDocumentUpdateDate &&
																		!context.NewCertificateDocument && (
																			<p className="m-0 p-0">
																				{moment(
																					context
																						?.modalData
																						?.certificateDocumentUpdateDate
																				).format(
																					'DD.MM.YYYY HH:mm'
																				)}
																			</p>
																		)}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Transkript
																Belgesi
															</h6>
														</div>
														<div className="col-8">
															<input
																type="file"
																accept="application/pdf"
																name="NewTranscriptDocument"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																onChange={(
																	event
																) => {
																	if (
																		event
																			?.target
																			?.files?.[0]
																	) {
																		setContext(
																			{
																				...context,
																				modalData:
																					{
																						...context?.modalData,
																					},
																				NewTranscriptDocument:
																					event
																						.target
																						.files[0],
																			}
																		);
																	}
																}}
															/>
															<div className="row d-flex align-items-center">
																<div className="col-12 col-md-6">
																	{context
																		?.modalData
																		?.transcriptDocumentList
																		?.length >
																		0 && (
																		<a
																			href={`https://${context?.modalData?.transcriptDocumentList[0]?.baseAddress}`}
																			target="_blank"
																			rel="noreferrer"
																			className="my-3 text-decoration-none fs-7 text-green d-block"
																		>
																			Transkript
																			Belgesi{' '}
																			<i
																				className="fas fa-check-circle ms-2"
																				style={{
																					width: 16,
																				}}
																			/>
																		</a>
																	)}
																</div>

																<div className="col-12 col-md-6">
																	{context
																		?.modalData
																		?.transcriptDocumentList
																		?.length >
																		0 && (
																		<p className="m-0 p-0 my-3">
																			{moment(
																				context
																					?.modalData
																					?.transcriptDocumentList[0]
																					?.documentUpdateDate
																			).format(
																				'DD.MM.YYYY HH:mm'
																			)}
																		</p>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																STK Belgesi
															</h6>
														</div>
														<div className="col-8">
															<input
																type="file"
																accept="application/pdf"
																name="NewStkDocument"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																onChange={(
																	event
																) => {
																	if (
																		event
																			?.target
																			?.files?.[0]
																	) {
																		setContext(
																			{
																				...context,
																				modalData:
																					{
																						...context?.modalData,
																					},
																				NewStkDocument:
																					event
																						.target
																						.files[0],
																			}
																		);
																	}
																}}
															/>
															<div className="row d-flex align-items-center">
																<div className="col-12 col-md-6">
																	{context
																		?.modalData
																		?.stkDocumentList
																		?.length >
																		0 && (
																		<a
																			href={`https://${context?.modalData?.stkDocumentList[0]?.baseAddress}`}
																			target="_blank"
																			rel="noreferrer"
																			className="my-3 text-decoration-none fs-7 text-green d-block"
																		>
																			{
																				context
																					?.modalData
																					?.stkDocumentList[0]
																					?.originalFileName
																			}
																			<i
																				className="fas fa-check-circle ms-2"
																				style={{
																					width: 16,
																				}}
																			/>
																		</a>
																	)}
																</div>

																<div className="col-12 col-md-6">
																	{context
																		?.modalData
																		?.stkDocumentList
																		?.length >
																		0 && (
																		<p className="m-0 p-0 my-3">
																			{moment(
																				context
																					?.modalData
																					?.stkDocumentList[0]
																					?.documentUpdateDate
																			).format(
																				'DD.MM.YYYY HH:mm'
																			)}
																		</p>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							)}

						{context?.modalData?.userType &&
							context.modalData.userType === 'MENTOR' && (
								<>
									{/* Eğitim Bilgileri */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-3"
											>
												<h5 className="text-orange mb-0">
													Eğitim Bilgileri
												</h5>
											</button>
										</h2>
										<div
											id="group-3"
											className="accordion-collapse collapse"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Lise Adı
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																readOnly
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.highSchoolName &&
																	context
																		.modalData
																		.educationInfoDTO
																		.highSchoolName
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						highSchoolName:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Üniversite Adı
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																disabled
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.universityDTO
																		?.id &&
																	context
																		.modalData
																		.educationInfoDTO
																		.universityDTO
																		.id
																}
																onChange={(
																	event
																) => {
																	handleGetAllUniversityFaculty(
																		event,
																		event
																			.target
																			.value
																	);
																}}
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{universities &&
																	universities.length >
																		0 &&
																	universities.map(
																		(
																			university
																		) => (
																			<option
																				key={
																					university.id &&
																					university.id
																				}
																				value={
																					university.id &&
																					university.id
																				}
																			>
																				{university.name &&
																					university.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Fakülte
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.faculty
																		?.id &&
																	context
																		.modalData
																		.educationInfoDTO
																		.faculty
																		.id
																}
																onChange={(
																	event
																) => {
																	handleGetAllUniversityDepartment(
																		event,
																		event
																			.target
																			.value
																	);

																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						faculty:
																							{
																								...context
																									?.modalData
																									?.educationInfoDTO
																									?.faculty,
																								id: event
																									.target
																									.value,
																							},
																					},
																			},
																	});
																}}
																disabled
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{faculties &&
																	faculties.length >
																		0 &&
																	faculties.map(
																		(
																			university
																		) => (
																			<option
																				key={
																					university.id &&
																					university.id
																				}
																				value={
																					university.id &&
																					university.id
																				}
																			>
																				{university.name &&
																					university.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Bölüm
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.universityDepartment
																		?.id &&
																	context
																		.modalData
																		.educationInfoDTO
																		.universityDepartment
																		.id
																}
																onChange={(
																	event
																) => {
																	setContext({
																		...context,
																		modalData:
																			{
																				...context?.modalData,
																				educationInfoDTO:
																					{
																						...context
																							?.modalData
																							?.educationInfoDTO,
																						universityDepartment:
																							{
																								...context
																									?.modalData
																									?.educationInfoDTO
																									?.universityDepartment,
																								id: event
																									.target
																									.value,
																							},
																					},
																			},
																	});
																}}
																disabled
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{departments &&
																	departments.length >
																		0 &&
																	departments.map(
																		(
																			department
																		) => (
																			<option
																				key={
																					department.id &&
																					department.id
																				}
																				value={
																					department.id &&
																					department.id
																				}
																			>
																				{department.name &&
																					department.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>

												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Yüksek Lisans
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																readOnly
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.degree &&
																	context
																		.modalData
																		.educationInfoDTO
																		.degree
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context.modalData,
																				educationInfoDTO:
																					{
																						...context
																							.modalData
																							.educationInfoDTO,
																						degree: event
																							.target
																							.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Doktora
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																readOnly
																value={
																	context
																		?.modalData
																		?.educationInfoDTO
																		?.doctorate &&
																	context
																		.modalData
																		.educationInfoDTO
																		.doctorate
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context.modalData,
																				educationInfoDTO:
																					{
																						...context
																							.modalData
																							.educationInfoDTO,
																						doctorate:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* Çalışma Bilgileri */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-4"
											>
												<h5 className="text-orange mb-0">
													Çalışma Bilgileri
												</h5>
											</button>
										</h2>
										<div
											id="group-4"
											className="accordion-collapse collapse"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Çalıştığı Firma
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	context
																		?.modalData
																		?.jobInfoDTO
																		?.firmName &&
																	context
																		.modalData
																		.jobInfoDTO
																		.firmName
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context.modalData,
																				jobInfoDTO:
																					{
																						...context
																							.modalData
																							.jobInfoDTO,
																						firmName:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Sektör
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	context
																		?.modalData
																		?.jobInfoDTO
																		?.sectorName &&
																	context
																		.modalData
																		.jobInfoDTO
																		.sectorName
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context.modalData,
																				jobInfoDTO:
																					{
																						...context
																							.modalData
																							.jobInfoDTO,
																						sectorName:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																İş Ünvanı
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	context
																		?.modalData
																		?.jobInfoDTO
																		?.jobTitle &&
																	context
																		.modalData
																		.jobInfoDTO
																		.jobTitle
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context.modalData,
																				jobInfoDTO:
																					{
																						...context
																							.modalData
																							.jobInfoDTO,
																						jobTitle:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Geçmiş İş
																Bilgisi
															</h6>
														</div>
														<div className="col-8">
															<textarea
																rows="4"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	context
																		?.modalData
																		?.jobInfoDTO
																		?.jobPastInfo &&
																	context
																		.modalData
																		.jobInfoDTO
																		.jobPastInfo
																}
																onChange={(
																	event
																) =>
																	setContext({
																		...context,
																		modalData:
																			{
																				...context.modalData,
																				jobInfoDTO:
																					{
																						...context
																							.modalData
																							.jobInfoDTO,
																						jobPastInfo:
																							event
																								.target
																								.value,
																					},
																			},
																	})
																}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							)}

						{/* İlgi Alanları */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-9"
								>
									<h5 className="text-orange mb-0">
										İlgi Alanları
									</h5>
								</button>
							</h2>
							<div
								id="group-9"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-12">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.interest &&
														context.modalData
															.interest
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																interest:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* eslint-disable */}
						{/* Diğer Bilgiler */}
						{/* <div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed bg-transparent p-4"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#group-6"
									>
										<h5 className="text-orange mb-0">
											Diğer Bilgiler
										</h5>
									</button>
								</h2>
								<div
									id="group-6"
									className="accordion-collapse collapse"
									data-bs-parent="#accordionProfile"
								>
									<div className="accordion-body p-4">
										<div className="mb-3">
											<div className="row">
												<div className="col-4">
													<h6 className="mb-0">Durum</h6>
												</div>
												<div className="col-8">
													<select
														value={
															context?.modalData
																?.mentorStatus &&
															context.modalData
																.mentorStatus
														}
														className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
														onChange={(event) =>
															setContext({
																...context,
																modalData: {
																	...context.modalData,
																	mentorStatus:
																		event.target
																			.value,
																},
															})
														}
													>
														<option value="">
															Lütfen seçiniz
														</option>
														<option value="ACTIVE">
															Aktif
														</option>
														<option value="PASSIVE">
															Pasif
														</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						{/* eslint-enable */}
					</div>

					<div className="modal-footer justify-content-center">
						<button
							type="button"
							className="btn bg-white text-orange border border-2 border-orange text-white rounded-pill px-4 py-2 me-2"
							onClick={() =>
								navigate('/admin/iletisim', {
									state: {
										receiver: {
											id:
												context?.modalData?.id &&
												context.modalData.id,
											name:
												context?.modalData?.name &&
												context.modalData.name,
											surname:
												context?.modalData?.surname &&
												context.modalData.surname,
										},
									},
								})
							}
						>
							<i className="fas fa-envelope me-2" /> Mesaj Gönder
						</button>
					</div>
				</div>
			</Modal>

			<Modal
				showModal={state.showModalDeny}
				onClose={() =>
					setState({
						...state,
						showModalDeny: false,
						denyUserId: null,
						denyUserResult: null,
					})
				}
				header="Reddetme Nedeni"
			>
				<div className="modal-body p4">
					<textarea
						rows="4"
						className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
						placeholder="Lütfen giriniz"
						value={state.denyUserResult}
						onChange={(event) => {
							setState({
								...state,
								denyUserResult: event.target.value,
							});
						}}
					/>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() =>
							handleUserDeny(
								state.denyUserId,
								state.denyUserResult
							)
						}
					>
						Kaydet
					</button>
				</div>
			</Modal>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>

			<Loading showLoading={state.showLoading} />
		</>
	);
};

const AdminApprovalsMatches = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [allWaitingMatch, setAllWaitingMatch] = React.useState(null); //eslint-disable-line
	const [allMentors, setAllMentors] = React.useState(null); //eslint-disable-line
	const [state, setState] = React.useState({
		showLoading: false,
	});

	// Use Effect
	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/getAllWaitingMatch?size=9999`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data) {
					setAllWaitingMatch(response.data);
					fetch(
						`${constants.API_BASE_URL}/admin/getAllMentor?size=9999`,
						{
							method: 'GET',
							headers: new Headers({
								Authorization: `Bearer ${context.dataLogin.token}`,
								'Content-Type': constants.API_HEADERS_PUBLIC,
							}),
						}
					)
						.then((response2) => response2.json())
						.then((response2) => {
							if (response2 && response2.data) {
								setAllMentors(response2.data);
							}
							setState({ ...state, showLoading: false });
						})
						.catch((error) => {
							setState({
								...state,
								toast: {
									type: 'error',
									message:
										error.message === 'Failed to fetch'
											? 'Sunucu ile bağlantı kurulamadı.'
											: error.message,
								},
								showLoading: false,
							});
						});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	const handleMatch = (e, menteeId) => {
		e.preventDefault();
		let mentorId = null;
		let data = null;

		e.target
			.closest('tr')
			.querySelectorAll('select > option')
			.forEach((item) => {
				if (item.selected && item.value !== '') {
					mentorId = parseInt(item.value, 10);
				}
			});

		if (mentorId == null || mentorId === '') {
			setState({
				...state,
				toast: {
					type: 'error',
					message:
						'Lütfen eşleştirmeye çalıştığınız kullanıcı için mentor seçiniz',
				},
			});
		} else {
			data = {
				menteeId,
				mentorId,
			};

			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/menteeMentorMatchManuel`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify(data),
			})
				.then((responseMatch) => responseMatch.json())
				.then((responseMatch) => {
					if (responseMatch) {
						fetch(
							`${constants.API_BASE_URL}/admin/getAllWaitingMatch?size=9999`,
							{
								method: 'GET',
								headers: new Headers({
									Authorization: `Bearer ${context.dataLogin.token}`,
									'Content-Type':
										constants.API_HEADERS_PUBLIC,
								}),
							}
						)
							.then((response) => response.json())
							.then((response) => {
								if (response && response.data) {
									setAllWaitingMatch(response.data);
									setState({
										...state,
										toast: {
											type: 'success',
											message:
												'Başarılı bir şekilde öğrenci ve mentor eşleştirildi.',
										},
										showLoading: false,
									});
								} else {
									setState({ ...state, showLoading: false });
								}
							})
							.catch((error) => {
								setState({
									...state,
									toast: {
										type: 'error',
										message:
											error.message === 'Failed to fetch'
												? 'Sunucu ile bağlantı kurulamadı.'
												: error.message,
									},
									showLoading: false,
								});
							});
					} else {
						setState({ ...state, showLoading: false });
					}
				})
				.catch((error) => {
					setState({
						...state,
						toast: {
							type: 'error',
							message:
								error.message === 'Failed to fetch'
									? 'Sunucu ile bağlantı kurulamadı.'
									: error.message,
						},
						showLoading: false,
					});
				});
		}
	};

	return (
		<div className="table-responsive">
			{/* {!allWaitingMatch?.content && <Loading showLoading />} */}

			<Loading showLoading={state.showLoading} />

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>

			<table className="table table-striped">
				<thead>
					<tr className="fs-7">
						<th scope="col" className="align-middle text-gray">
							Tür
						</th>
						<th scope="col" className="align-middle text-gray">
							İsim
						</th>
						<th scope="col" className="align-middle text-gray">
							Soyisim
						</th>
						<th scope="col" className="align-middle text-gray">
							E-Posta
						</th>
						<th scope="col" className="align-middle text-gray">
							Telefon Numarası
						</th>
						<th scope="col" className="align-middle text-gray">
							Mentör
						</th>
						<th
							scope="col"
							className="align-middle text-gray text-center"
						>
							İşlem
						</th>
					</tr>
				</thead>
				<tbody className="fs-7">
					{allWaitingMatch?.totalElements === 0 && (
						<tr>
							<td colSpan="8" className="text-center">
								Gösterilecek Sonuç Bulunamadı!
							</td>
						</tr>
					)}

					{allWaitingMatch?.content &&
						allWaitingMatch.content.length > 0 &&
						allWaitingMatch.content.map((item) => (
							<tr key={item.id}>
								<td className="align-middle text-gray">
									{item.userType && item.userType === 'MENTEE'
										? 'Öğrenci'
										: 'Mentor'}
								</td>
								<td className="align-middle text-gray">
									{item.name && item.name}
								</td>
								<td className="align-middle text-gray">
									{item.surname && item.surname}
								</td>
								<td className="align-middle text-gray">
									{item.email && item.email}
								</td>
								<td className="align-middle text-gray">
									{item.phoneNumber && item.phoneNumber}
								</td>
								<td className="align-middle text-gray">
									<select
										className="form-select border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
										style={{ maxWidth: '160px' }}
									>
										<option value="">Lütfen Seçiniz</option>
										{allMentors?.content &&
											allMentors.content.length > 0 &&
											allMentors.content
												.filter(
													(mentorItem) =>
														mentorItem.mentorStatus ===
														'ACTIVE'
												)
												.map((mentorItem) => (
													<option
														key={mentorItem.id}
														value={mentorItem.id}
													>
														{`${
															mentorItem.name &&
															mentorItem.name
														} ${
															mentorItem.surname &&
															mentorItem.surname
														}`}
													</option>
												))}
									</select>
								</td>

								<td
									className="align-middle text-gray text-center"
									style={{ width: '200px' }}
								>
									<button
										type="button"
										className="btn border border-2 border-green bg-green text-white rounded-pill px-3 py-1 fs-7 me-0"
										onClick={(e) => handleMatch(e, item.id)}
									>
										Onayla
									</button>
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};

const AdminApprovals = () => {
	const [state, setState] = React.useState({
		activeTab: 'memberships',
		showLoading: false,
	});

	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [userList, setUserList] = React.useState(''); //eslint-disable-line
	const [programList, setProgramList] = React.useState(null); //eslint-disable-line

	// Use Effect
	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/admin/getActiveUserRequest?size=9999`,
			{
				method: 'GET',
				headers: new Headers({
					Authorization: `Bearer ${context.dataLogin.token}`,
					'Content-Type': constants.API_HEADERS_PUBLIC,
				}),
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data) {
					setUserList(response.data);

					setState({ ...state, showLoading: true });
					fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
						method: 'GET',
						headers: new Headers({
							Authorization: `Bearer ${context.dataLogin.token}`,
							'Content-Type': constants.API_HEADERS_PUBLIC,
						}),
					})
						.then((resp) => resp.json())
						.then((resp) => {
							if (resp && resp.content) {
								setProgramList(resp.content);
							}
							setState({ ...state, showLoading: false });
						})
						.catch((error) => {
							setState({
								...state,
								toast: {
									type: 'error',
									message:
										error.message === 'Failed to fetch'
											? 'Sunucu ile bağlantı kurulamadı.'
											: error.message,
								},
								showLoading: false,
							});
						});
				} else {
					setState({ ...state, showLoading: false });
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	const handleTabClick = (event, tab) => {
		event.preventDefault();
		setState({
			...state,
			activeTab: tab,
		});
	};

	return (
		<>
			<WidgetAdminTitleBar title="Onaylar" />

			{/* filter */}
			<div className="mb-5 d-flex flex-sm-row flex-column gap-2">
				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'memberships'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={(event) => handleTabClick(event, 'memberships')}
				>
					Üyelik
				</button>
				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'profiles'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={(event) => handleTabClick(event, 'profiles')}
				>
					Giriş Kartı
				</button>
				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'matches'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={(event) => handleTabClick(event, 'matches')}
				>
					Eşleşme
				</button>
			</div>

			{state.activeTab === 'memberships' && (
				<AdminApprovalsMemberships
					userList={userList}
					programList={programList}
				/>
			)}
			{state.activeTab === 'profiles' && <AdminApprovalsProfiles />}
			{state.activeTab === 'matches' && <AdminApprovalsMatches />}
		</>
	);
};

export default AdminApprovals;
