import React from 'react';
import moment from 'moment';

import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';
import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';

const AdminFiles = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/getAllAdminDocument?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data?.content) {
					setState({
						...state,
						showLoading: false,
						files: response.data.content,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	const handleSearch = (event) => {
		setState({
			...state,
			search: event.target.value,
		});
	};

	const handleEditClick = () => {
		setState({
			...state,
			showModal: true,
		});
	};

	const handleSaveClick = async () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/saveDocument`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				originalFileName: state.originalFileName,
				text: await toBase64(state.text),
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'success',
							message: 'Dosya kaydedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
					showModal: false,
				});
			});
	};

	const handleRemoveClick = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/deleteDocument/${id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Dosya silindi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<>
			<WidgetAdminTitleBar title="Dosyalar" />

			<div className="row g-md-4 g-2 align-items-center  mb-5">
				<div className="col-md-3">
					<div className="d-flex align-items-center">
						<label className="form-label fw-medium mb-0 me-3 fs-7">
							Arama
						</label>
						<input
							type="text"
							className="form-control border border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
							onChange={(event) => handleSearch(event)}
							value={state.search}
						/>
					</div>
				</div>
				<div className="col-auto ms-auto">
					<div className="d-flex align-items-center gap-3 justify-content-end">
						<button
							type="button"
							className="btn btn-orange text-white rounded-pill px-4 py-2 ms-md-3 ms-0 flex-md-grow-0 flex-fill"
							onClick={() => handleEditClick()}
						>
							Yeni
							<i className="fas fa-plus ms-2" />
						</button>
					</div>
				</div>
			</div>
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr className="fs-7">
							<th
								scope="col"
								className="align-middle text-gray text-center"
								width="60"
							>
								ID
							</th>
							<th scope="col" className="align-middle text-gray">
								İsim
							</th>
							<th scope="col" className="align-middle text-gray">
								Tarih
							</th>
							<th scope="col" className="align-middle text-gray">
								URL
							</th>
							<th
								scope="col"
								className="align-middle text-gray text-center"
								style={{ width: 170 }}
							>
								İşlem
							</th>
						</tr>
					</thead>
					<tbody className="fs-7">
						{state.files &&
							state.files
								.filter(
									(file) =>
										file.originalFileName
											.toLowerCase()
											.search(
												state?.search?.toLowerCase()
											) > -1
								)
								.map((file) => (
									<tr key={file.id}>
										<td className="align-middle text-gray-light text-center fw-medium fs-8">
											{file.id}
										</td>
										<td className="align-middle text-gray fw-medium">
											{file.originalFileName}
										</td>
										<td className="align-middle text-gray fs-8">
											{moment(file.createDate).format(
												'DD.MM.YYYY HH:mm'
											)}
										</td>
										<td className="align-middle text-gra fs-8">
											<a
												href={`https://${file.text}`}
												target="_blank"
												rel="noreferrer"
											>
												{`https://${file.text}`}
											</a>
										</td>
										<td className="align-middle text-gray text-center">
											<button
												type="button"
												className="btn border border-2 border-red text-red bg-white rounded-pill px-3 py-1 fs-7 mx-1"
												onClick={() =>
													handleRemoveClick(file.id)
												}
											>
												Sil
											</button>
										</td>
									</tr>
								))}
					</tbody>
				</table>
			</div>

			<Modal
				showModal={state.showModal}
				onClose={() =>
					setState({
						...state,
						showModal: false,
					})
				}
				header="Yeni"
			>
				<div className="modal-body p-4">
					<form>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Dosya
								</label>
							</div>
							<div className="col-7">
								<input
									type="file"
									// accept="application/pdf"
									name="text"
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									onChange={(event) => {
										if (event?.target?.files?.[0]) {
											setState({
												...state,
												text: event.target.files[0],
												originalFileName:
													event.target.files[0].name,
											});
										}
									}}
								/>
							</div>
						</div>
					</form>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() => handleSaveClick()}
					>
						Kaydet
					</button>
				</div>
			</Modal>
			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminFiles;
