import React from 'react';

import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';
import AdminContentsSliders from './AdminContentsSliders';
import AdminContentsSurveys from './AdminContentsSurveys';
import AdminContentsStatics from './AdminContentsStatics';
import AdminContentsFaq from './AdminContentsFaq';
import AdminContentsUniversities from './AdminContentsUniversities';

const AdminContents = () => {
	const [state, setState] = React.useState({ activeTab: 'sliders' });

	const handleTabClick = (tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	return (
		<>
			<WidgetAdminTitleBar title="İçerikler" />

			{/* filter */}
			<div className="mb-5 d-flex flex-sm-row flex-column gap-2">
				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'sliders'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('sliders')}
				>
					Sliderlar
				</button>

				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'surveys'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('surveys')}
				>
					Anketler
				</button>

				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'statics'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('statics')}
				>
					Sabit İçerikler
				</button>

				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'faq'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('faq')}
				>
					Sıkça Sorulan Sorular
				</button>

				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'universities'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('universities')}
				>
					Üniversiteler
				</button>
			</div>

			{state.activeTab === 'sliders' && <AdminContentsSliders />}
			{state.activeTab === 'surveys' && <AdminContentsSurveys />}
			{state.activeTab === 'statics' && <AdminContentsStatics />}
			{state.activeTab === 'faq' && <AdminContentsFaq />}
			{state.activeTab === 'universities' && (
				<AdminContentsUniversities />
			)}
		</>
	);
};

export default AdminContents;
