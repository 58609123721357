/* eslint-disable no-plusplus */
/* eslint-disable object-curly-newline */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import { useRef, useState, useContext } from 'react';
import ReactCrop, {
	centerCrop,
	convertToPixelCrop,
	makeAspectCrop,
} from 'react-image-crop';
import setCanvasPreview from './setCanvasPreview';

import constants from '../../constants';
import Context from '../../stores/Context/Context';

import Toast from '../Toast/Toast';
import Loading from '../Loading/Loading';

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 120;

const ImageCropper = ({ updateAvatar }) => {
	const [context, setContext] = useContext(Context);
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const [imgSrc, setImgSrc] = useState('');
	const [crop, setCrop] = useState();
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [clickedOnce, setClickedOnce] = useState(false);

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const onSelectFile = async (e) => {
		const file = e.target.files?.[0];
		const maxSize = 4 * 1024 * 1024; // 4 MB
		if (!file) return;

		if (file.size > maxSize) {
			setError("Dosya boyutu 4 MB'tan büyük olmamalıdır");
			e.target.value = null; // Cancel file select
			return;
		}

		const imageUrl = await toBase64(file);
		const imageElement = new Image();
		imageElement.src = imageUrl;

		imageElement.onload = () => {
			const { naturalWidth, naturalHeight } = imageElement;
			if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
				setError(
					'Yüklediğiniz görsel en az (120 x 120) çözünürlükte olmalıdır.'
				);
				return setImgSrc('');
			}

			setImgSrc(imageUrl);
		};
	};

	const onImageLoad = (e) => {
		const { width, height } = e.target;
		const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

		const crop = makeAspectCrop(
			{
				unit: '%',
				width: cropWidthInPercent,
			},
			ASPECT_RATIO,
			width,
			height
		);
		const centeredCrop = centerCrop(crop, width, height);
		setCrop(centeredCrop);
	};

	const convertImageToJPEGBase64 = async (imageDataURL) => {
		// Check the format
		const format = imageDataURL.substring(
			'data:image/'.length,
			imageDataURL.indexOf(';base64')
		);

		if (format === 'jpeg' || format === 'jpg') {
			// Coming data if jpeg or jpg dont do anything
			return imageDataURL;
		}
		if (format === 'png') {
			// Coming data if PNG format convert to JPEG
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.crossOrigin = 'Anonymous';
				img.onload = function () {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');
					canvas.width = img.width;
					canvas.height = img.height;
					ctx.drawImage(img, 0, 0);
					canvas.toBlob(
						(blob) => {
							const reader = new FileReader();
							reader.readAsDataURL(blob);
							reader.onloadend = () => {
								const base64data = reader.result;
								resolve(base64data);
							};
						},
						'image/jpeg',
						0.6
					); // Image quality maximum parameter is 1 or %60 quality jpg format for 0.6
				};
				img.onerror = reject;
				img.src = imageDataURL;
			});
		}
		// Unsupported format
		throw new Error('Desteklenmeyen dosya formatı');
	};

	const handleSaveClick = async () => {
		if (loading || clickedOnce) return;

		setClickedOnce(true);
		setLoading(true);

		setCanvasPreview(
			imgRef.current, // HTMLImageElement
			previewCanvasRef.current, // HTMLCanvasElement
			convertToPixelCrop(
				crop,
				imgRef.current.width,
				imgRef.current.height
			)
		);

		const imageData = previewCanvasRef.current.toDataURL();

		updateAvatar(imageData);

		const profileImageBase64 = await convertImageToJPEGBase64(imageData);

		const payload = {
			...context,
			modalData: {
				...context.modalData,
				userType: context?.dataUser?.userType,
				profileImage: profileImageBase64,
			},
		};

		fetch(`${constants.API_BASE_URL}/updateUser`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...payload.modalData,
				...(context?.NewCertificateDocument ||
				context?.modalData?.certificateDocument
					? {
							certificateDocument:
								context?.modalData?.certificateDocument &&
								!context?.NewCertificateDocument
									? context?.modalData?.certificateDocument
									: await toBase64(
											context?.NewCertificateDocument
									  ),
					  }
					: {}),
				...(context?.NewTranscriptDocument
					? {
							transcriptDocument: await toBase64(
								context?.NewTranscriptDocument
							),
					  }
					: {}),
				...(context?.NewStkDocument
					? {
							stkDocument: await toBase64(
								context?.NewStkDocument
							),
					  }
					: {}),
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setLoading(false);

					setContext({
						...context,
						modalData: {
							...context.modalData,
							userType: payload?.modalData.dataUser?.userType,
							profileImage: payload.modalData.profileImageBase64,
						},
					});

					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setLoading(false);
					setError(
						'Lütfen tüm alanları doldurduğunuzdan emin olun! Lütfen sistem yöneticisine başvurun...'
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				setError('Bir hata oluştu, lütfen tekrar deneyin.');
				console.error('Error:', error);
			});
	};

	return (
		<>
			<label className="block mb-3 w-fit">
				<span className="sr-only">Lütfen bir fotoğraf seçiniz</span>
				<input
					type="file"
					accept="image/*"
					onChange={onSelectFile}
					className="block w-full text-sm text-slate-500 file:mr-4 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-gray-700 file:text-sky-300 hover:file:bg-gray-600"
				/>
			</label>
			{error && <p className="text-red-400 text-xs">{error}</p>}
			{imgSrc && (
				<div className="flex flex-col items-center">
					<ReactCrop
						crop={crop}
						onChange={(pixelCrop, percentCrop) =>
							setCrop(percentCrop)
						}
						circularCrop
						keepSelection
						aspect={ASPECT_RATIO}
						minWidth={MIN_DIMENSION}
					>
						<img
							ref={imgRef}
							src={imgSrc}
							alt="Upload"
							style={{ maxHeight: '70vh' }}
							onLoad={onImageLoad}
						/>
					</ReactCrop>
					<div className="text-center mt-3">
						<button
							className="btn btn-orange border border-2 border-orange text-white rounded-pill px-4 py-2"
							onClick={handleSaveClick}
							disabled={loading}
						>
							Yükle <i className="fas fa-arrow-right ms-2" />
						</button>
					</div>
				</div>
			)}
			{crop && (
				<canvas
					ref={previewCanvasRef}
					className="mt-4"
					style={{
						display: 'none',
						border: '1px solid black',
						objectFit: 'contain',
						width: 120,
						height: 120,
					}}
				/>
			)}

			<Toast
				showToast={error}
				onClose={() => setError('')}
				type="error"
				message={error}
			/>
			<Loading showLoading={loading} />
		</>
	);
};

export default ImageCropper;
