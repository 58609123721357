import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

import { tinymceApiKey } from '../../config/api.config';

const SuperAdminHelpWhatIsNew = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		title: '',
		description: '',
		url: '',
		icon: '',
		whatIsNew: '',
		tinymceInit: {
			height: 500,
			menubar: true,
			plugins: [
				'advlist',
				'autolink',
				'lists',
				'link',
				'image',
				'charmap',
				'preview',
				'anchor',
				'searchreplace',
				'visualblocks',
				'code',
				'fullscreen',
				'insertdatetime',
				'media',
				'table',
				'code',
				'help',
				'wordcount',
			],
			toolbar:
				'undo redo | blocks | ' +
				'bold italic forecolor | alignleft aligncenter ' +
				'alignright alignjustify | bullist numlist outdent indent | ' +
				'removeformat | help',
			content_style:
				'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
			branding: false,
		},
	});

	const getDefaultDatas = () => {
		// KVKK
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/getWhatIsNew`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					setState({
						...state,
						showLoading: false,
						whatIsNew: response.data,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		getDefaultDatas();
	}, []);

	// TinyMCE
	const newEditorRef = React.useRef();

	const handleSetWhatIsNew = () => {
		if (newEditorRef.current.getContent()) {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/setWhatIsNew`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					id: '0',
					title: 'WhatIsNew',
					description: newEditorRef.current.getContent(),
					standartContentType: 'WHATISNEW',
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					console.log('save response: ', response);
					if (response.data) {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'success',
								message: 'Yeni ne var kaydedildi.',
							},
						});
					} else {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'error',
								message: response.description
									? response.description
									: 'Lütfen daha sonra tekrar deneyin.',
							},
						});
					}
				})
				.catch((error) => {
					setState({
						...state,
						showModal: false,
						toast: {
							type: 'error',
							message: error.message,
						},
						showLoading: false,
					});
				});
		}
	};

	return (
		<>
			<div>
				<div className="accordion" id="accordionProfile">
					{/* New */}
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button
								className="accordion-button bg-transparent p-4"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#group-0"
							>
								<h5 className="text-orange mb-0">
									Yeni ne var?
								</h5>
							</button>
						</h2>
						<div
							id="group-0"
							className="accordion-collapse collapse show"
							data-bs-parent="#accordionProfile"
						>
							<div className="accordion-body p-4 text-center">
								<Editor
									apiKey={tinymceApiKey}
									// eslint-disable-next-line no-return-assign
									onInit={(_evt, editor) =>
										(newEditorRef.current = editor)
									}
									initialValue={state?.whatIsNew?.description}
									init={state.tinymceInit}
								/>

								<button
									type="button"
									className="btn btn-orange text-white rounded-pill px-4 py-2 ms-0 flex-md-grow-0 flex-fill mt-3 mb-3"
									onClick={() => handleSetWhatIsNew()}
								>
									Kaydet
									<i className="fas fa-save ms-2" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default SuperAdminHelpWhatIsNew;
