import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

const CoursesTitle = () => (
	<div className="d-flex align-items-center mb-5">
		<h1 className="mb-0 text-blue-light">Eğitimler</h1>
	</div>
);

const CoursesSidebar = () => {
	const [context, setContext] = React.useContext(Context);

	const handlefilterRequiredClick = (event, isRequired) => {
		event.preventDefault();
		setContext({
			...context,
			filterRequired: isRequired,
		});
	};

	return (
		<>
			<div className="offcanvas offcanvas-start offcanvas-responsive-lg">
				<button
					type="button"
					className="btn-close text-reset position-absolute end-0 top-0 me-3 mt-3"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				/>
				<div className="offcanvas-body">
					<h5 className="mb-4">Katılım</h5>
					<ul className="list-unstyled gap-2 d-grid mb-5">
						<li>
							<a
								href="#"
								className={`text-decoration-none ${
									context.filterRequired === undefined
										? 'text-orange'
										: 'text-gray-light'
								}`}
								onClick={(event) =>
									handlefilterRequiredClick(event, undefined)
								}
							>
								Tümü
							</a>
						</li>
						<li>
							<a
								href="#"
								className={`text-decoration-none ${
									context.filterRequired === 'required'
										? 'text-orange'
										: 'text-gray-light'
								}`}
								onClick={(event) =>
									handlefilterRequiredClick(event, 'required')
								}
							>
								Zorunlu
							</a>
						</li>
						<li>
							<a
								href="#"
								className={`text-decoration-none ${
									context.filterRequired === 'optional'
										? 'text-orange'
										: 'text-gray-light'
								}`}
								onClick={(event) =>
									handlefilterRequiredClick(event, 'optional')
								}
							>
								Opsiyonel
							</a>
						</li>
					</ul>
				</div>
			</div>
			<a
				href=".offcanvas"
				className="btn btn-orange text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3"
				data-bs-toggle="offcanvas"
			>
				<i className="fas fa-filter" />
			</a>
		</>
	);
};

const CoursesContent = (props) => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});

	const handleJoinClick = (event, id) => {
		event.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/activities/${id}/menteeList/add/${context.dataUser.id}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'successs',
							message: 'Etkinlik takviminize eklendi.',
						},
					});
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.description
								? response.description
								: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<>
			{useLocation()?.state?.s &&
				(props?.data?.length ? (
					<h4 className="mb-5 text-center">
						<span className="text-orange text-capitalize">
							{useLocation().state.s}
						</span>{' '}
						ile ilgili bulunan eğitimler
					</h4>
				) : (
					<div className="d-flex flex-column justify-content-center align-items-center text-center">
						<i className="fas fa-book-open h1 fw-bold text-orange" />
						<h4 className="mb-0 lh-base">
							<span className="text-capitalize text-orange">
								{useLocation().state.s}
							</span>{' '}
							ile ilgili eğitim bulunamadı
						</h4>
					</div>
				))}

			<div className="row g-4">
				{props.data &&
					props.data
						.filter((item) => {
							if (context.filterRequired) {
								if (
									context.filterRequired === 'required' &&
									item.isMandatory
								) {
									return true;
								}

								if (
									context.filterRequired === 'optional' &&
									!item.isMandatory
								) {
									return true;
								}
								return false;
							}
							return true;
						})
						.map((item) => (
							<div className="col-xl-4 col-md-6" key={item.id}>
								<div className="d-block text-decoration-none text-reset h-100 rounded rounded-2 bg-gray-lightest overflow-hidden">
									<div
										className="position-relative bg-white d-flex justify-content-center align-items-center border border-2 border-gray-lightest rounded-top rounded-2"
										style={{
											height: 160,
										}}
									>
										<img
											src={require('../../assets/images/logo.png')}
											alt=""
											style={{
												width: '40%',
											}}
										/>
									</div>
									<div className="p-4">
										<h5 className="mb-3 lh-base">
											{item.name}
										</h5>
										<p className="text-gray-light fs-7 mb-2">
											{item.description}
										</p>

										<p className="fs-8 mb-3">
											{item.instructor}
										</p>

										<div className="border-top border-2 d-flex justify-content-between pt-3 fs-7 fw-medium text-blue-light mb-2">
											<span>Başlangıç</span>
											<span>
												{new Date(
													item.dateTimeBegin
												).toLocaleDateString('tr-TR')}
												<span className="mx-1">-</span>
												{new Date(item.dateTimeBegin)
													.toLocaleTimeString('tr-TR')
													.substring(0, 5)}
											</span>
										</div>
										<div className="border-bottom border-2 d-flex justify-content-between pb-3 fs-7 fw-medium text-blue-light mb-3">
											<span>Bitiş</span>
											<span>
												{new Date(
													item.dateTimeEnd
												).toLocaleDateString('tr-TR')}
												<span className="mx-1">-</span>
												{new Date(item.dateTimeEnd)
													.toLocaleTimeString('tr-TR')
													.substring(0, 5)}
											</span>
										</div>
										{context.dataLogin.userRole ===
											'mentor' && (
											<div className="d-flex align-items-center justify-content-center">
												<NavLink
													to="/takvim"
													className="btn border border-2 border-orange text-orange rounded-pill px-3 py-1 fs-7"
												>
													Takvimi Gör
												</NavLink>
											</div>
										)}
										{context.dataLogin.userRole ===
											'mentee' &&
											(item.isMandatory ? (
												<div className="d-flex align-items-center justify-content-between">
													<span className="fs-7 fw-semibold text-red">
														Zorunlu
													</span>
													<NavLink
														to="/takvim"
														className="btn border border-2 border-orange text-orange rounded-pill px-3 py-1 fs-7"
													>
														Takvimi Gör
													</NavLink>
												</div>
											) : (
												<div className="d-flex align-items-center justify-content-between">
													<span className="fs-7 fw-semibold text-green">
														Opsiyonel
													</span>
													<button
														type="submit"
														className="btn border border-2 border-orange text-orange rounded-pill px-3 py-1 fs-7"
														onClick={(event) =>
															handleJoinClick(
																event,
																item.id
															)
														}
													>
														Takvime Ekle
													</button>
												</div>
											))}
									</div>
								</div>
							</div>
						))}
			</div>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

const Courses = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});
	const { userRole } = context.dataLogin;

	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/activities?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.content) {
					setState({
						...state,
						showLoading: false,
						courses: response.content,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	return (
		<main>
			<div className="container">
				<CoursesTitle />

				{userRole === 'mentee' ? (
					<div className="row">
						<div className="col-lg-3">
							<CoursesSidebar />
						</div>
						<div className="col-lg-9">
							<CoursesContent
								data={
									useLocation()?.state?.s
										? state?.courses?.filter(
												(item) =>
													item?.name
														.toLowerCase()
														.search(
															useLocation()?.state?.s.toLowerCase()
														) > -1
										  ) /* eslint-disable-line */
										: state?.courses
								}
							/>
						</div>
					</div>
				) : (
					<CoursesContent
						data={
							useLocation()?.state?.s
								? state?.courses?.filter(
										(item) =>
											item?.name
												.toLowerCase()
												.search(
													useLocation()?.state?.s.toLowerCase()
												) > -1
								  ) /* eslint-disable-line */
								: state?.courses
						}
					/>
				)}
			</div>
		</main>
	);
};

export default Courses;
