/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line object-curly-newline
import { useRef, useState } from 'react';
import ModalCropper from './ModalCropper';

const ProfileCircle = ({ context }) => {
	const avatarRef = useRef(null);
	const [modalOpen, setModalOpen] = useState(false);

	const updateAvatar = (imgSrc) => {
		avatarRef.current = imgSrc;
	};

	return (
		<div className="flex flex-col items-center pt-12 user-select-none">
			<div className="position-relative">
				{context?.modalData?.cardStatus !== 'WAITINGAPPROVAL' && (
					<button
						type="button"
						className="position-absolute bottom-0 end-0 rounded-full border-0 bg-transparent"
						title="Change photo"
						onClick={() => setModalOpen(true)}
					>
						<i className="fas fa-camera-retro me-3 d-inline-block" />
					</button>
				)}

				{context?.dataUser?.profileImage ? (
					<div
						className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
						style={{
							width: 130,
							height: 130,
							cursor: 'pointer',
							zIndex: 1,
						}}
						onClick={() => {
							context?.modalData?.cardStatus !==
								'WAITINGAPPROVAL' && setModalOpen(true);
						}}
					>
						<img
							src={`https://${context.dataUser.profileImage}`}
							alt={
								context.dataUser?.name &&
								context.dataUser?.surname
									? context.dataUser.name +
									  context.dataUser.surname
									: 'Avatar'
							}
							className="rounded-circle"
							style={{
								width: 120,
								height: 120,
							}}
						/>
					</div>
				) : (
					<>
						{context.dataUser.name && context.dataUser.surname && (
							<div
								className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
								style={{
									width: 130,
									height: 130,
									cursor: 'pointer',
								}}
								onClick={() => setModalOpen(true)}
							>
								{context.dataUser.name.substring(0, 1) +
									context.dataUser.surname.substring(0, 1)}
							</div>
						)}
					</>
				)}
			</div>

			{modalOpen && (
				<ModalCropper
					modalOpen={modalOpen}
					updateAvatar={updateAvatar}
					closeModal={() => setModalOpen(false)}
				/>
			)}
		</div>
	);
};

export default ProfileCircle;
