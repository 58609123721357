import React from 'react';

import Context from '../../stores/Context/Context';

import WidgetAlerts from '../../components/WidgetAlerts/WidgetAlerts';
import WidgetCalendar from '../../components/WidgetCalendar/WidgetCalendar';
import WidgetCTA from '../../components/WidgetCTA/WidgetCTA';
import WidgetMentees from '../../components/WidgetMentees/WidgetMentees';
import WidgetMentor from '../../components/WidgetMentor/WidgetMentor';
import WidgetPrograms from '../../components/WidgetPrograms/WidgetPrograms';
import WidgetHandbook from '../../components/WidgetHandbook/WidgetHandbook';
import WidgetSliderHero from '../../components/WidgetSliderHero/WidgetSliderHero';
import WidgetSurvey from '../../components/WidgetSurvey/WidgetSurvey';
// import WidgetTargets from '../../components/WidgetTargets/WidgetTargets';

const Dashboard = () => {
	const [context] = React.useContext(Context);
	const { userRole } = context.dataLogin;

	return (
		<main>
			<div className="container">
				<div className="row g-4 mb-4">
					<div className="col-xl-9 col-lg-6">
						<WidgetSliderHero />
					</div>

					<div className="col-xl-3 col-lg-6 d-flex flex-column">
						{/* <WidgetTargets /> */}
						{userRole === 'mentee' && <WidgetPrograms />}
						{userRole === 'mentor' && <WidgetHandbook />}
						<WidgetAlerts />
					</div>
				</div>

				<div className="row g-4 mb-4">
					<div className="col-xl-6">
						<WidgetCalendar />
					</div>

					<div className="col-xl-3 col-lg-6">
						<WidgetSurvey />
					</div>

					<div className="col-xl-3 col-lg-6">
						{userRole === 'mentee' && <WidgetMentor />}
						{userRole === 'mentor' && <WidgetMentees />}
					</div>
				</div>

				<WidgetCTA />
			</div>
		</main>
	);
};

export default Dashboard;
