import React from 'react';

import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';
import AdminHelpWhatIsNew from './AdminHelpWhatIsNew';
import AdminHelpUsersManual from './AdminHelpUsersManual';

const AdminHelp = () => {
	const [state, setState] = React.useState({ activeTab: 'new' });

	const handleTabClick = (tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	return (
		<>
			<WidgetAdminTitleBar title="Destek" />

			{/* filter */}
			<div className="mb-5 d-flex flex-sm-row flex-column gap-2">
				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'new'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('new')}
				>
					Yeni ne var?
				</button>

				<button
					type="button"
					className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
						state.activeTab === 'userGuide'
							? 'btn-orange text-white'
							: 'border border-2 border-orange text-orange'
					}      `}
					onClick={() => handleTabClick('userGuide')}
				>
					Kullanım Klavuzu
				</button>
			</div>

			{state.activeTab === 'new' && <AdminHelpWhatIsNew />}
			{state.activeTab === 'userGuide' && <AdminHelpUsersManual />}
		</>
	);
};

export default AdminHelp;
