import React from 'react';
import { useLocation } from 'react-router-dom';
import constants from '../../constants';
import Context from '../../stores/Context/Context';

const ContentCookiePolicy = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});
	const [cookies, setCookies] = React.useState(null);

	const GetCookies = () => {
		fetch(`${constants.API_BASE_URL}/public/getCookies`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response.data) {
					setCookies(response.data);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		GetCookies();
	}, []);

	React.useEffect(() => {
		if (cookies) {
			setContext({
				...context,
				cookies,
			});
		}
	}, [cookies]);

	return (
		<main>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8 col-md-10">
						<h1 className="text-blue-light text-center mb-5">
							{context?.cookies?.title}
						</h1>
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: context?.cookies?.description,
							}}
						/>
					</div>
				</div>
			</div>
		</main>
	);
};

const ContentPrivacyPolicy = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});
	const [kvkk, setKvkk] = React.useState(null);

	const GetKvkk = () => {
		fetch(`${constants.API_BASE_URL}/public/getKVKKText`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response.data) {
					setKvkk(response.data);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		GetKvkk();
	}, []);

	React.useEffect(() => {
		if (kvkk) {
			setContext({
				...context,
				kvkk,
			});
		}
	}, [kvkk]);

	return (
		<main>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8 col-md-10">
						<h1 className="text-blue-light text-center mb-5">
							{context?.kvkk?.title}
						</h1>
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: context?.kvkk?.description,
							}}
						/>
					</div>
				</div>
			</div>
		</main>
	);
};

const ContentTermsOfUse = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});
	const [terms, setTerms] = React.useState(null);

	const GetTerms = () => {
		fetch(`${constants.API_BASE_URL}/public/getTermsOfUse`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response.data) {
					setTerms(response.data);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		GetTerms();
	}, []);

	React.useEffect(() => {
		if (terms) {
			setContext({
				...context,
				terms,
			});
		}
	}, [terms]);

	return (
		<main>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8 col-md-10">
						<h1 className="text-blue-light text-center mb-5">
							{context?.terms?.title}
						</h1>
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: context?.terms?.description,
							}}
						/>
					</div>
				</div>
			</div>
		</main>
	);
};

const ContentFaq = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});
	const [faq, setFaq] = React.useState(null);

	const GetFaq = () => {
		fetch(`${constants.API_BASE_URL}/public/getAskedQuestions`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response?.data?.content) {
					setFaq(response.data.content);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		GetFaq();
	}, []);

	React.useEffect(() => {
		if (faq) {
			setContext({
				...context,
				faq,
			});
		}
	}, [faq]);

	return (
		<main>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8 col-md-10">
						<h1 className="text-blue-light text-center mb-5">
							Sıkça Sorulan Sorular
						</h1>
						{faq?.length > 0 &&
							faq.map((item) => (
								<div
									key={item.id && item.id}
									value={item.id && item.id}
								>
									<h5>{item.title && item.title}</h5>
									<p>
										{item.description && item.description}
									</p>
								</div>
							))}
					</div>
				</div>
			</div>
		</main>
	);
};

const Content = () => (
	<>
		{useLocation().pathname === '/cerez-politikasi' && (
			<ContentCookiePolicy />
		)}
		{useLocation().pathname === '/aydinlatma-metni' && (
			<ContentPrivacyPolicy />
		)}
		{useLocation().pathname === '/kullanim-kosullari' && (
			<ContentTermsOfUse />
		)}
		{useLocation().pathname === '/sss' && <ContentFaq />}
	</>
);

export default Content;
