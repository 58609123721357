import React from 'react';

const Toast = (props) =>
	props.showToast && (
		<div
			className={`${
				props.type === 'error' ? 'bg-red' : 'bg-green'
			} toast show position-fixed start-50 translate-middle-x top-0 m-4 border-0 rounded-2 p-3`}
			data-bs-autohide="true"
			style={{ zIndex: 99999 }}
		>
			<div className="d-flex">
				<div className="toast-body p-0 text-white">{props.message}</div>
				<button
					type="button"
					className="btn-close btn-close-white opacity-100 ms-auto"
					aria-label="Close"
					onClick={props.onClose}
				/>
			</div>
		</div>
	);

export default Toast;
