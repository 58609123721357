/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import Select from 'react-select';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';

const AdminMenteesStats = () => {
	const [context] = React.useContext(Context);
	const [stats, setStats] = React.useState();

	React.useEffect(() => {
		fetch(`${constants.API_BASE_URL}/admin/chartStatistics`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data.chartMenteeDTO) {
					setStats(response.data.chartMenteeDTO);
				}
			});
	}, []);

	return (
		stats && (
			<div className="row text-center mb-6 g-3 row-cols-2 row-cols-md-3 row-cols-lg-6">
				<div className="col">
					<div className="rounded rounded-2 bg-green p-2">
						<h5 className="mb-1 text-white">{stats.activeCount}</h5>
						<h6 className="text-white text-truncate small mb-0 opacity-75">
							Aktif
						</h6>
					</div>
				</div>
				<div className="col">
					<div className="rounded rounded-2 bg-red p-2">
						<h5 className="mb-1 text-white">
							{stats.passiveCount}
						</h5>
						<h6 className="text-white text-truncate small mb-0 opacity-75">
							Pasif
						</h6>
					</div>
				</div>
				<div className="col">
					<div
						className="rounded rounded-2 p-2"
						style={{ backgroundColor: '#fbbf24' }}
					>
						<h5 className="mb-1 text-white">
							{stats.hangingCount}
						</h5>
						<h6 className="text-white text-truncate small mb-0 opacity-75">
							Askıda
						</h6>
					</div>
				</div>
				<div className="col">
					<div className="rounded rounded-2 bg-gray-lightest p-2">
						<h5 className="mb-1 text-gray">
							{stats.graduateCount}
						</h5>
						<h6 className="text-gray-light text-truncate small mb-0">
							Mezun
						</h6>
					</div>
				</div>
				<div className="col">
					<div className="rounded rounded-2 bg-gray-lightest p-2">
						<h5 className="mb-1 text-gray">{stats.cutoffCount}</h5>
						<h6 className="text-gray-light text-truncate small mb-0">
							Kesilen
						</h6>
					</div>
				</div>
				<div className="col">
					<div className="rounded rounded-2 bg-gray-lightest p-2">
						<h5 className="mb-1 text-gray">
							{stats.continuingCount}
						</h5>
						<h6 className="text-gray-light text-truncate small mb-0">
							Devam Eden
						</h6>
					</div>
				</div>
			</div>
		)
	);
};

const AdminMenteesFilters = () => {
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		showLoading: false,
	});

	const handleViewClick = (event, type) => {
		event.preventDefault();

		setContext({
			...context,
			adminMenteesViewType: type,
		});
	};

	const handleSearch = (event) => {
		event.preventDefault();
		const searchText = event.target.value.toLowerCase();

		if (searchText.length >= 2) {
			const filteredList = context.defaultMentees.filter(
				(el) =>
					el.name.toLowerCase().includes(searchText)
			);

			setContext({
				...context,
				dataMentees: filteredList,
			});
		} else {
			setContext({
				...context,
				dataMentees: context.defaultMentees,
			});
		}
	};

	const filterMentees = (url) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}${url}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data?.totalElements > 0) {
					if (response?.data?.content) {
						setContext({
							...context,
							dataMentees: response.data.content,
						});

						// Loader Hide
						setState({
							...state,
							showLoading: false,
						});
					} else {
						setState({
							...state,
							showLoading: false,
						});
					}
				} else {
					setContext({
						...context,
						dataMentees: null,
					});
				}
			});
	};

	const handleFilter = (event) => {
		event.preventDefault();

		if (parseInt(event.target.value, 10) === 0) {
			setContext({
				...context,
				dataMentees: context.defaultMentees,
			});
		} else if (parseInt(event.target.value, 10) === 1) {
			filterMentees('/admin/getBursaryCriticalUser');
		} else if (parseInt(event.target.value, 10) === 2) {
			filterMentees('/admin/activityCheckList');
		} else if (parseInt(event.target.value, 10) === 3) {
			filterMentees('/admin/getStkCriticalUser');
		}
	};

	const handleCreateMentee = (event) => {
		event.preventDefault();
		setState({ ...state, showLoading: true });

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/getAllMentor?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((responseMentor) => responseMentor.json())
			.then((responseMentor) => {
				if (
					responseMentor?.data?.content &&
					responseMentor.data.content
				) {
					fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
						method: 'GET',
						headers: new Headers({
							Authorization: `Bearer ${context.dataLogin.token}`,
							'Content-Type': constants.API_HEADERS_PUBLIC,
						}),
					})
						.then((resp) => resp.json())
						.then((resp) => {
							if (resp && resp.content) {
								setState({ ...state, showLoading: true });

								// Get Universities
								fetch(
									`${constants.API_BASE_URL}/public/getUniversityNameAll`,
									{
										method: 'GET',
										headers: {
											'Content-Type': 'application/json',
											Authorization: `Bearer ${context.dataLogin.token}`,
										},
									}
								)
									.then((response) => response.json())
									.then((response) => {
										if (response.data) {
											setState({
												...state,
												showLoading: false,
											});

											// Context
											setContext({
												...context,
												universityList: response.data,
												universityFacultyList:
													undefined,
												universityDepartmentList:
													undefined,
												dataProgramList: resp.content,
												dataMentorList:
													responseMentor.data.content,
												showModal: true,
											});
										} else {
											setState({
												...state,
												showLoading: false,
											});

											// Context
											setContext({
												...context,
												dataProgramList: resp.content,
												dataMentorList:
													responseMentor.data.content,
												showModal: true,
											});
										}
									});
							}

							// Loader Hide
							setState({
								...state,
								showLoading: false,
							});
						});
				}

				setState({
					...state,
					showLoading: false,
				});
			});
	};

	return (
		<div className="row g-md-4 g-2 align-items-center mb-5">
			<div className="col-md-3">
				<div className="d-flex align-items-center">
					<label className="form-label fw-medium mb-0 me-3 fs-7">
						Filtrele
					</label>
					<select
						className="form-select border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
						onChange={(event) => handleFilter(event)}
					>
						<option value="0">Tümü</option>
						<option value="1">Burs Durumu Kritik</option>
						<option value="2">Eğitim Durumu Kritik</option>
						<option value="3">STK Durumu Kritik</option>
					</select>
				</div>
			</div>
			<div className="col-md-3">
				<div className="d-flex align-items-center">
					<label className="form-label fw-medium mb-0 me-3 fs-7">
						Arama
					</label>
					<input
						type="text"
						className="form-control border border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
						onChange={(event) => handleSearch(event)}
					/>
				</div>
			</div>
			<div className="col-md-6">
				<div className="d-flex align-items-center gap-3 justify-content-end">
					<a
						href="#"
						className={`d-md-block d-none ${
							context.adminMenteesViewType === 'grid'
								? 'text-orange'
								: 'text-gray-light'
						}`}
						onClick={(event) => handleViewClick(event, 'grid')}
					>
						<i className="fas fa-th-large" />
					</a>
					<a
						href="#"
						className={`d-md-block d-none ${
							context.adminMenteesViewType === 'list'
								? 'text-orange'
								: 'text-gray-light'
						}`}
						onClick={(event) => handleViewClick(event, 'list')}
					>
						<i className="fas fa-list-ul" />
					</a>

					<a
						href="#"
						onClick={(event) => handleCreateMentee(event)}
						className="btn btn-orange text-white rounded-pill px-4 py-2 ms-md-3 ms-0 flex-md-grow-0 flex-fill"
					>
						Yeni
						<i className="fas fa-plus ms-2" />
					</a>
				</div>
			</div>
		</div>
	);
};

// const AdminMenteesPagination = () => (
// 	<div className="d-flex justify-content-center mt-8">
// 		<ul className="pagination">
// 			<li className="page-item">
// 				<a
// 					className="p-3 bg-gray-lightest text-gray text-decoration-none rounded rounded-2 mx-1"
// 					href="#"
// 				>
// 					&laquo;
// 				</a>
// 			</li>
// 			<li className="page-item">
// 				<a
// 					className="p-3 bg-orange text-white text-decoration-none rounded rounded-2 mx-1"
// 					href="#"
// 				>
// 					1
// 				</a>
// 			</li>
// 			<li className="page-item">
// 				<a
// 					className="p-3 bg-gray-lightest text-gray text-decoration-none rounded rounded-2 mx-1"
// 					href="#"
// 				>
// 					2
// 				</a>
// 			</li>
// 			<li className="page-item">
// 				<a
// 					className="p-3 bg-gray-lightest text-gray text-decoration-none rounded rounded-2 mx-1"
// 					href="#"
// 				>
// 					3
// 				</a>
// 			</li>
// 			<li className="page-item">
// 				<a
// 					className="p-3 bg-gray-lightest text-gray text-decoration-none rounded rounded-2 mx-1"
// 					href="#"
// 				>
// 					&raquo;
// 				</a>
// 			</li>
// 		</ul>
// 	</div>
// );

const AdminMenteesViewGrid = () => {
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		showLoading: false,
	});

	const getPrograms = (listContext) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp && resp.content) {
					// Context
					setContext({
						...context,
						...listContext,
						dataProgramList: resp.content,
					});
				}

				// Loader Hide
				setState({
					...state,
					showLoading: false,
				});
			});
	};

	const getUserById = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/getUser/${id}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					setState({ ...state, showLoading: true });
					fetch(
						`${constants.API_BASE_URL}/admin/getAllMentor?size=9999`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${context.dataLogin.token}`,
							},
						}
					)
						.then((mentor) => mentor.json())
						// eslint-disable-next-line consistent-return
						.then((mentor) => {
							if (mentor && mentor.data && mentor.data.content) {
								if (
									response?.data?.educationInfoDTO
										?.universityDTO?.id &&
									!response?.data?.educationInfoDTO?.faculty
										?.id &&
									response.data.educationInfoDTO.universityDTO
										.id
								) {
									// Get Universities
									fetch(
										`${constants.API_BASE_URL}/public/getUniversityNameAll`,
										{
											method: 'GET',
											headers: {
												'Content-Type':
													'application/json',
												Authorization: `Bearer ${context.dataLogin.token}`,
											},
										}
									)
										.then((university) => university.json())
										.then((university) => {
											if (university.data) {
												setState({
													...state,
													showLoading: true,
												});

												// Get Faculties
												fetch(
													`${constants.API_BASE_URL}/public/getFacultyAll/${response.data.educationInfoDTO.universityDTO.id}`,
													{
														method: 'GET',
														headers: {
															'Content-Type':
																'application/json',
															Authorization: `Bearer ${context.dataLogin.token}`,
														},
													}
												)
													.then(
														(responseFaculty) =>
															responseFaculty.json()
														// eslint-disable-next-line function-paren-newline
													)
													.then((responseFaculty) => {
														if (
															responseFaculty.data
														) {
															setState({
																...state,
																showLoading: false,
															});

															getPrograms({
																...context,
																modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
																modalData:
																	response.data,
																dataMentorList:
																	mentor.data
																		.content,
																showModal: true,
																universityList:
																	university.data,
																universityFacultyList:
																	responseFaculty.data,
																universityDepartmentList:
																	undefined,
															});
														}
													});
											}
										});
								} else if (
									response?.data?.educationInfoDTO
										?.universityDTO?.id &&
									response?.data?.educationInfoDTO?.faculty
										?.id &&
									response.data.educationInfoDTO.faculty.id
								) {
									// Get Universities
									fetch(
										`${constants.API_BASE_URL}/public/getUniversityNameAll`,
										{
											method: 'GET',
											headers: {
												'Content-Type':
													'application/json',
												Authorization: `Bearer ${context.dataLogin.token}`,
											},
										}
									)
										.then((university) => university.json())
										.then((university) => {
											if (university.data) {
												setState({
													...state,
													showLoading: true,
												});

												// Get Faculties
												fetch(
													`${constants.API_BASE_URL}/public/getFacultyAll/${response.data.educationInfoDTO.universityDTO.id}`,
													{
														method: 'GET',
														headers: {
															'Content-Type':
																'application/json',
															Authorization: `Bearer ${context.dataLogin.token}`,
														},
													}
												)
													.then(
														(responseFaculty) =>
															responseFaculty.json()
														// eslint-disable-next-line function-paren-newline
													)
													.then((responseFaculty) => {
														if (
															responseFaculty.data
														) {
															setState({
																...state,
																showLoading: true,
															});

															// Get Departments
															fetch(
																`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${response.data.educationInfoDTO.faculty.id}`,
																{
																	method: 'GET',
																	headers: {
																		'Content-Type':
																			'application/json',
																		Authorization: `Bearer ${context.dataLogin.token}`,
																	},
																}
															)
																.then(
																	(
																		responseDepartment
																	) =>
																		responseDepartment.json()
																)
																.then(
																	(
																		responseDepartment
																	) => {
																		if (
																			responseDepartment.data
																		) {
																			setState(
																				{
																					...state,
																					showLoading: false,
																				}
																			);

																			getPrograms(
																				{
																					...context,
																					modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
																					modalData:
																						response.data,
																					dataMentorList:
																						mentor
																							.data
																							.content,
																					showModal: true,
																					universityList:
																						university.data,
																					universityFacultyList:
																						responseFaculty.data,
																					universityDepartmentList:
																						responseDepartment.data,
																				}
																			);
																		} else {
																			setState(
																				{
																					...state,
																					showLoading: false,
																				}
																			);
																			getPrograms(
																				{
																					...context,
																					modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
																					modalData:
																						response.data,
																					dataMentorList:
																						mentor
																							.data
																							.content,
																					showModal: true,
																					universityList:
																						university.data,
																					universityFacultyList:
																						responseFaculty.data,
																					universityDepartmentList:
																						undefined,
																				}
																			);
																		}
																	}
																);
														}
													});
											}
										});
								} else {
									// Get Universities
									fetch(
										`${constants.API_BASE_URL}/public/getUniversityNameAll`,
										{
											method: 'GET',
											headers: {
												'Content-Type':
													'application/json',
												Authorization: `Bearer ${context.dataLogin.token}`,
											},
										}
									)
										.then((university) => university.json())
										.then((university) => {
											if (university.data) {
												setState({
													...state,
													showLoading: false,
												});

												getPrograms({
													...context,
													modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
													modalData: response.data,
													dataMentorList:
														mentor.data.content,
													showModal: true,
													universityList:
														university.data,
													universityFacultyList:
														undefined,
													universityDepartmentList:
														undefined,
												});
											}
										});
								}

								// Loader Hide
								setState({
									...state,
									showLoading: false,
								});
							} else {
								getPrograms({
									...context,
									modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
									modalData: response.data,
									showModal: true,
								});
							}
						})
						.catch((err) => err);
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	const handleShowProfile = (event, id) => {
		event.preventDefault();

		getUserById(id);

		setContext({
			...context,
			showModal: true,
		});
	};

	return (
		<div className="row g-4">
			{!context?.dataMentees && (
				<div className="text-center">
					Gösterilecek Öğrenci Bulunamadı
				</div>
			)}
			{context?.dataMentees &&
				context.dataMentees.length > 0 &&
				context.dataMentees.map((item) => (
					<div className="col-lg-4 col-md-6" key={item.id && item.id}>
						<a
							href="#"
							onClick={(event) =>
								handleShowProfile(event, item.id)
							}
							className={
								item.menteeType &&
								item.menteeType === 'ACTIVEMENTEE'
									? 'd-block bg-gray-lightest rounded-2 p-4 text-reset text-decoration-none h-100 position-relative'
									: 'd-block bg-red-lightest rounded-2 p-4 text-reset text-decoration-none h-100 position-relative'
							}
						>
							<div className="d-flex align-items-center">
								{item?.profileImage ? (
									<div
										className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
										style={{
											width: 80,
											height: 80,
											zIndex: 1,
										}}
									>
										<img
											src={`https://${item.profileImage}`}
											alt={
												item.name && item.surname
													? item.name + item.surname
													: 'Avatar'
											}
											className="rounded-circle"
											style={{
												width: 80,
												height: 80,
											}}
										/>
									</div>
								) : (
									<>
										{item.name && item.surname && (
											<div
												className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
												style={{
													width: 80,
													height: 80,
													cursor: 'pointer',
												}}
											>
												{item.name.substring(0, 1) +
													item.surname.substring(
														0,
														1
													)}
											</div>
										)}
									</>
								)}
								<div className="flex-grow-1">
									<h5 className="mb-0 fs-6 text-blue text-truncate w-75">
										{item.name && item.name}{' '}
										{item.surname && item.surname}
									</h5>
									<span className="fs-8 text-gray-light">
										{item.birthday && item.birthday}
									</span>
								</div>
							</div>

							{item.menteeType &&
							item.menteeType === 'ACTIVEMENTEE' ? (
								<span
									className="position-absolute bg-green d-block top-0 end-0 m-3 rounded-circle"
									style={{ width: 8, height: 8 }}
								/>
							) : (
								<span
									className="position-absolute bg-red d-block top-0 end-0 m-3 rounded-circle"
									style={{ width: 8, height: 8 }}
								/>
							)}
						</a>
					</div>
				))}
		</div>
	);
};

const AdminMenteesViewList = () => {
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		showLoading: false,
	});

	const getPrograms = (listContext) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp && resp.content) {
					// Context
					setContext({
						...context,
						...listContext,
						dataProgramList: resp.content,
					});
				}

				// Loader Hide
				setState({
					...state,
					showLoading: false,
				});
			});
	};

	const getUserById = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/getUser/${id}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					setState({ ...state, showLoading: true });
					fetch(
						`${constants.API_BASE_URL}/admin/getAllMentor?size=9999`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${context.dataLogin.token}`,
							},
						}
					)
						.then((mentor) => mentor.json())
						// eslint-disable-next-line consistent-return
						.then((mentor) => {
							if (mentor && mentor.data && mentor.data.content) {
								if (
									response?.data?.educationInfoDTO
										?.universityDTO?.id &&
									!response?.data?.educationInfoDTO?.faculty
										?.id &&
									response.data.educationInfoDTO.universityDTO
										.id
								) {
									// Get Universities
									fetch(
										`${constants.API_BASE_URL}/public/getUniversityNameAll`,
										{
											method: 'GET',
											headers: {
												'Content-Type':
													'application/json',
												Authorization: `Bearer ${context.dataLogin.token}`,
											},
										}
									)
										.then((university) => university.json())
										.then((university) => {
											if (university.data) {
												setState({
													...state,
													showLoading: true,
												});

												// Get Faculties
												fetch(
													`${constants.API_BASE_URL}/public/getFacultyAll/${response.data.educationInfoDTO.universityDTO.id}`,
													{
														method: 'GET',
														headers: {
															'Content-Type':
																'application/json',
															Authorization: `Bearer ${context.dataLogin.token}`,
														},
													}
												)
													.then(
														(responseFaculty) =>
															responseFaculty.json()
														// eslint-disable-next-line function-paren-newline
													)
													.then((responseFaculty) => {
														if (
															responseFaculty.data
														) {
															setState({
																...state,
																showLoading: false,
															});

															getPrograms({
																...context,
																modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
																modalData:
																	response.data,
																dataMentorList:
																	mentor.data
																		.content,
																showModal: true,
																universityList:
																	university.data,
																universityFacultyList:
																	responseFaculty.data,
																universityDepartmentList:
																	undefined,
															});
														}
													});
											}
										});
								} else if (
									response?.data?.educationInfoDTO
										?.universityDTO?.id &&
									response?.data?.educationInfoDTO?.faculty
										?.id &&
									response.data.educationInfoDTO.faculty.id
								) {
									// Get Universities
									fetch(
										`${constants.API_BASE_URL}/public/getUniversityNameAll`,
										{
											method: 'GET',
											headers: {
												'Content-Type':
													'application/json',
												Authorization: `Bearer ${context.dataLogin.token}`,
											},
										}
									)
										.then((university) => university.json())
										.then((university) => {
											if (university.data) {
												setState({
													...state,
													showLoading: true,
												});

												// Get Faculties
												fetch(
													`${constants.API_BASE_URL}/public/getFacultyAll/${response.data.educationInfoDTO.universityDTO.id}`,
													{
														method: 'GET',
														headers: {
															'Content-Type':
																'application/json',
															Authorization: `Bearer ${context.dataLogin.token}`,
														},
													}
												)
													.then(
														(responseFaculty) =>
															responseFaculty.json()
														// eslint-disable-next-line function-paren-newline
													)
													.then((responseFaculty) => {
														if (
															responseFaculty.data
														) {
															setState({
																...state,
																showLoading: true,
															});

															// Get Departments
															fetch(
																`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${response.data.educationInfoDTO.faculty.id}`,
																{
																	method: 'GET',
																	headers: {
																		'Content-Type':
																			'application/json',
																		Authorization: `Bearer ${context.dataLogin.token}`,
																	},
																}
															)
																.then(
																	(
																		responseDepartment
																	) =>
																		responseDepartment.json()
																)
																.then(
																	(
																		responseDepartment
																	) => {
																		if (
																			responseDepartment.data
																		) {
																			setState(
																				{
																					...state,
																					showLoading: false,
																				}
																			);

																			getPrograms(
																				{
																					...context,
																					modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
																					modalData:
																						response.data,
																					dataMentorList:
																						mentor
																							.data
																							.content,
																					showModal: true,
																					universityList:
																						university.data,
																					universityFacultyList:
																						responseFaculty.data,
																					universityDepartmentList:
																						responseDepartment.data,
																				}
																			);
																		} else {
																			setState(
																				{
																					...state,
																					showLoading: false,
																				}
																			);
																			getPrograms(
																				{
																					...context,
																					modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
																					modalData:
																						response.data,
																					dataMentorList:
																						mentor
																							.data
																							.content,
																					showModal: true,
																					universityList:
																						university.data,
																					universityFacultyList:
																						responseFaculty.data,
																					universityDepartmentList:
																						undefined,
																				}
																			);
																		}
																	}
																);
														}
													});
											}
										});
								} else {
									// Get Universities
									fetch(
										`${constants.API_BASE_URL}/public/getUniversityNameAll`,
										{
											method: 'GET',
											headers: {
												'Content-Type':
													'application/json',
												Authorization: `Bearer ${context.dataLogin.token}`,
											},
										}
									)
										.then((university) => university.json())
										.then((university) => {
											if (university.data) {
												setState({
													...state,
													showLoading: false,
												});

												getPrograms({
													...context,
													modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
													modalData: response.data,
													dataMentorList:
														mentor.data.content,
													showModal: true,
													universityList:
														university.data,
													universityFacultyList:
														undefined,
													universityDepartmentList:
														undefined,
												});
											}
										});
								}

								// Loader Hide
								setState({
									...state,
									showLoading: false,
								});
							} else {
								getPrograms({
									...context,
									modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
									modalData: response.data,
									showModal: true,
								});
							}
						})
						.catch((err) => err);
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	const handleShowProfile = (event, id) => {
		event.preventDefault();

		getUserById(id);

		setContext({
			...context,
			showModal: true,
		});
	};

	return (
		<div className="table-responsive">
			<table className="table table-striped">
				<thead>
					<tr className="fs-7">
						<th
							scope="col"
							className="align-middle text-gray text-center"
							width="60"
						>
							ID
						</th>
						<th scope="col" className="align-middle text-gray">
							İsim
						</th>
						<th scope="col" className="align-middle text-gray">
							Soyisim
						</th>
						<th scope="col" className="align-middle text-gray">
							E-Posta
						</th>
						<th scope="col" className="align-middle text-gray">
							Telefon
						</th>
						<th
							scope="col"
							className="align-middle text-gray text-center"
							style={{ width: 170 }}
						>
							İşlem
						</th>
					</tr>
				</thead>
				<tbody className="fs-7">
					{context?.dataMentees &&
						context.dataMentees.length > 0 &&
						context?.dataMentees
							.filter(
								(item) =>
									(item.name + item.surname)
										.toLowerCase()
										.search(state?.search?.toLowerCase()) >
									-1
							)
							.map((item) => (
								<tr key={item.id}>
									<td className="align-middle text-gray-light text-center fw-medium fs-8">
										{item.id}
									</td>
									<td className="align-middle text-gray">
										<div className="d-flex align-items-center">
											{item.menteeType ===
											'ACTIVEMENTEE' ? (
												<span
													className="bg-green d-block me-2 rounded-circle"
													style={{
														width: 8,
														height: 8,
													}}
												/>
											) : (
												<span
													className="bg-red d-block me-2 rounded-circle"
													style={{
														width: 8,
														height: 8,
													}}
												/>
											)}
											{item.name}
										</div>
									</td>
									<td className="align-middle text-gray">
										{item.surname}
									</td>
									<td className="align-middle text-gray">
										{item.email}
									</td>
									<td className="align-middle text-gray">
										{item.phoneNumber}
									</td>
									<td className="align-middle text-gray text-center">
										<button
											type="button"
											className="btn border border-2 border-orange text-orange bg-white rounded-pill px-3 py-1 fs-7 mx-1"
											onClick={(event) =>
												handleShowProfile(
													event,
													item.id
												)
											}
										>
											Düzenle
										</button>
									</td>
								</tr>
							))}
				</tbody>
			</table>
		</div>
	);
};

const AdminMentees = () => {
	// eslint-disable-next-line no-unused-vars
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		showLoading: false,
	});
	const navigate = useNavigate();

	const [dataProvinces, setDataProvinces] = React.useState(null);

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const getAllProvince = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/getAllProvince`, {
			method: 'GET',
			headers: constants.API_HEADERS_PUBLIC,
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data) {
					setDataProvinces(response.data);
				}

				// Loader Hide
				setState({
					...state,
					showLoading: false,
				});
			});
	};

	const getAllMentee = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/getAllMentee?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data && response.data.content) {
					if (context.adminMenteesViewType === 'list') {
						setContext({
							...context,
							adminMenteesViewType: 'list',
							dataMentees: response.data.content,
							defaultMentees: response.data.content,
						});
					} else {
						setContext({
							...context,
							adminMenteesViewType: 'grid',
							dataMentees: response.data.content,
							defaultMentees: response.data.content,
						});
					}

					// Loader Hide
					setState({
						...state,
						showLoading: false,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						adminMenteesViewType: 'grid',
					});
				}
			});
	};

	const handleSaveClick = async (event) => {
		event.preventDefault();

		let contextCurrent = { ...context };
		contextCurrent = {
			...context.modalData,
			userType: 'MENTEE',
		};

		if (contextCurrent.id) {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/updateUser`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					...contextCurrent,
					...(context?.NewCertificateDocument ||
					context?.modalData?.certificateDocument
						? {
								certificateDocument:
									context?.modalData?.certificateDocument &&
									!context?.NewCertificateDocument
										? context?.modalData
												?.certificateDocument
										: await toBase64(
												context?.NewCertificateDocument
										  ),
						  }
						: {}),
					...(context?.NewTranscriptDocument
						? {
								transcriptDocument: await toBase64(
									context?.NewTranscriptDocument
								),
						  }
						: {}),
					...(context?.NewStkDocument
						? {
								stkDocument: await toBase64(
									context?.NewStkDocument
								),
								stkStatement: context?.NewStkDocument.name,
						  }
						: {}),
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'success',
								message: 'Öğrenci Kaydedildi.',
							},
						});
						setInterval(() => {
							window.location.reload();
						}, 1500);
					} else {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'error',
								message: response.message,
							},
						});
					}
				})
				.catch((err) => {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: err.error,
						},
					});
				});
		} else {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/loginCard/register`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					...contextCurrent,
					...(context?.NewCertificateDocument ||
					context?.modalData?.certificateDocument
						? {
								certificateDocument:
									context?.modalData?.certificateDocument &&
									!context?.NewCertificateDocument
										? context?.modalData
												?.certificateDocument
										: await toBase64(
												context?.NewCertificateDocument
										  ),
						  }
						: {}),
					...(context?.NewTranscriptDocument
						? {
								transcriptDocument: await toBase64(
									context?.NewTranscriptDocument
								),
						  }
						: {}),
					...(context?.NewStkDocument
						? {
								stkDocument: await toBase64(
									context?.NewStkDocument
								),
						  }
						: {}),
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						fetch(`${constants.API_BASE_URL}/admin/welcomeMail`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${context.dataLogin.token}`,
							},
							body: JSON.stringify({ userId: response.data }),
						})
							.then((res) => res.json())
							.then((res) => {
								if (res) {
									// Loader Hide
									setState({
										...state,
										showLoading: false,
										showModal: false,
										toast: {
											type: 'success',
											message:
												'Öğrenci Kaydedildi ve Öğrencinin Giriş Bilgileri Mail Olarak Gönderildi!',
										},
									});

									setInterval(() => {
										window.location.reload();
									}, 1500);
								} else {
									// Loader Hide
									setState({
										...state,
										showLoading: false,
									});
								}
							});
					} else {
						setState({
							...state,
							showLoading: false,
							showModal: false,
							toast: {
								type: 'error',
								message: response.message,
							},
						});
					}
				})
				.catch((err) => {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: err.message,
						},
					});
				});
		}
	};

	// Faculty
	const getUniversityFaculty = (universityId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getFacultyAll/${universityId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								universityDTO: {
									...context?.modalData?.educationInfoDTO
										?.universityDTO,
									id: universityId,
								},
							},
						},
						universityFacultyList: response.data,
						universityDepartmentList: undefined,
					});
				} else {
					setState({ ...state, showLoading: false });
				}
			});
	};

	// Department
	const getUniversityDepartment = (facultyId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${facultyId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								faculty: {
									...context?.modalData?.educationInfoDTO
										?.faculty,
									id: facultyId,
								},
							},
						},
						universityDepartmentList: response.data,
					});
				} else {
					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								faculty: {
									...context?.modalData?.educationInfoDTO
										?.faculty,
									id: facultyId,
								},
							},
						},
					});
					setState({ ...state, showLoading: false });
				}
			});
	};

	const handleGetAllUniversityFaculty = (e, universityId) => {
		if (universityId !== '') {
			getUniversityFaculty(universityId);
		} else {
			setContext({
				...context,
				universityFacultyList: undefined,
				universityDepartmentList: undefined,
			});
		}
	};

	const handleGetAllUniversityDepartment = (e, facultyId) => {
		e.preventDefault();
		if (facultyId !== '') {
			getUniversityDepartment(facultyId);
		} else {
			setContext({
				...context,
				universityDepartmentList: undefined,
			});
		}
	};

	const handleActivation = (e, type) => {
		e.preventDefault();

		if (type) {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/welcomeMail`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					userId: context.modalData.id,
					welcomeType: type,
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						let message = '';
						if (type === 'mail') {
							message = 'Mail';
						} else {
							message = 'SMS';
						}

						setState({
							...state,
							showLoading: false,
							toast: {
								type: 'success',
								message: `${message} gönderildi`,
							},
						});

						setInterval(() => {
							window.location.reload();
						}, 1500);
					} else {
						setState({
							...state,
							showLoading: false,
							toast: {
								type: 'error',
								message: response.message,
							},
						});
					}
				});
		}
	};

	// useEffect
	React.useEffect(() => {
		// ALL MENTEES
		getAllMentee();

		// ALL PROVINCES
		getAllProvince();
	}, []);

	return (
		<>
			<WidgetAdminTitleBar title="Öğrenciler" />
			<AdminMenteesStats />
			<AdminMenteesFilters />
			{context.adminMenteesViewType === 'list' ? (
				<AdminMenteesViewList />
			) : (
				<AdminMenteesViewGrid />
			)}
			<Modal
				showModal={context.showModal}
				onClose={() =>
					setContext({
						...context,
						showModal: false,
						modalDataHeader: undefined,
						modalData: undefined,
					})
				}
				header={
					context.modalDataHeader
						? context.modalDataHeader
						: 'Öğrenci Oluştur'
				}
				size="lg"
			>
				<div className="modal-body p-4">
					<div className="accordion" id="accordionProfile">
						{/* Kişisel Bilgiler  */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-1"
								>
									<h5 className="text-orange mb-0">
										Kişisel Bilgiler
									</h5>
								</button>
							</h2>
							<div
								id="group-1"
								className="accordion-collapse collapse show"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									{context?.modalData?.profileImage && (
										<div className="mb-3">
											<div className="row align-items-center">
												<div className="col-4">
													<h6 className="mb-0">
														Avatar
													</h6>
												</div>
												<div className="col-8">
													<img
														src={`https://${context?.modalData?.profileImage}`}
														className="rounded-circle"
														alt="Aktif Avatar"
														style={{
															width: '120px',
															height: '120px',
														}}
													/>
												</div>
											</div>
										</div>
									)}

									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">İsim</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.name &&
														context.modalData.name
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																name: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Soyisim
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.surname &&
														context.modalData
															.surname
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																surname:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">TCKN</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.identityNo &&
														context.modalData
															.identityNo
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																identityNo:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Doğum Tarihi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="date"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.birthday &&
														context.modalData
															.birthday
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																birthday:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Cinsiyet
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.gender &&
														context.modalData.gender
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																gender: event
																	.target
																	.value,
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="MAN">
														Erkek
													</option>
													<option value="WOMAN">
														Kadın
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Iban</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.iban &&
														context.modalData.iban
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																iban: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İletişim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-2"
								>
									<h5 className="text-orange mb-0">
										İletişim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-2"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Telefon Numarası
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.phoneNumber &&
														context.modalData
															.phoneNumber
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																phoneNumber:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													E-Posta Adresi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.email &&
														context.modalData.email
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																email: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Aile Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-3"
								>
									<h5 className="text-orange mb-0">
										Aile Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-3"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Aile Durumu
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.familyInfoDTO
															?.familyStatus &&
														context.modalData
															.familyInfoDTO
															.familyStatus
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																familyInfoDTO: {
																	...context
																		?.modalData
																		?.familyInfoDTO,
																	familyStatus:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="MRFR">
														Anne Sağ, Baba Sağ
													</option>
													<option value="MRFN">
														Anne Sağ, Baba Yok
													</option>
													<option value="MNFR">
														Anne Yok, Baba Sağ
													</option>
													<option value="MNFN">
														Anne Yok, Baba Yok
													</option>
													<option value="DIVORCED">
														Boşanmışlar
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Aile Şehri
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.familyInfoDTO
															?.familyCity?.id &&
														context.modalData
															.familyInfoDTO
															.familyCity.id
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																familyInfoDTO: {
																	...context
																		?.modalData
																		?.familyInfoDTO,
																	familyCity:
																		{
																			...context
																				?.modalData
																				?.familyInfoDTO
																				?.familyCity,
																			id: event
																				.target
																				.value,
																		},
																},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{dataProvinces &&
														dataProvinces.length >
															0 &&
														dataProvinces.map(
															(province) => (
																<option
																	key={
																		province.id &&
																		province.id
																	}
																	value={
																		province.id &&
																		province.id
																	}
																>
																	{province.name &&
																		province.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													İletişim Kurulacak Kişi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.familyInfoDTO
															?.parentComName &&
														context.modalData
															.familyInfoDTO
															.parentComName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																familyInfoDTO: {
																	...context
																		?.modalData
																		?.familyInfoDTO,
																	parentComName:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													İletişim Kurulacak Kişi
													Telefon
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.familyInfoDTO
															?.parentComTel &&
														context.modalData
															.familyInfoDTO
															.parentComTel
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																familyInfoDTO: {
																	...context
																		?.modalData
																		?.familyInfoDTO,
																	parentComTel:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Eğitim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-4"
								>
									<h5 className="text-orange mb-0">
										Eğitim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-4"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Lise Adı
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.highSchoolName &&
														context.modalData
															.educationInfoDTO
															.highSchoolName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		highSchoolName:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Üniversite Adı
												</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityDTO
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityDTO.id
													}
													onChange={(event) => {
														handleGetAllUniversityFaculty(
															event,
															event.target.value
														);
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityList &&
														context.universityList
															.length > 0 &&
														context.universityList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Fakülte
												</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.faculty?.id &&
														context.modalData
															.educationInfoDTO
															.faculty.id
													}
													onChange={(event) => {
														handleGetAllUniversityDepartment(
															event,
															event.target.value
														);
													}}
													disabled={
														!context.universityFacultyList
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityFacultyList &&
														context
															.universityFacultyList
															.length > 0 &&
														context.universityFacultyList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Bölüm</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityDepartment
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityDepartment
															.id
													}
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityDepartment:
																			{
																				...context
																					?.modalData
																					?.educationInfoDTO
																					?.universityDepartment,
																				id: event
																					.target
																					.value,
																			},
																	},
															},
														});
													}}
													disabled={
														!context.universityDepartmentList
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityDepartmentList &&
														context
															.universityDepartmentList
															.length > 0 &&
														context.universityDepartmentList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Sınıf</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityClass &&
														context.modalData
															.educationInfoDTO
															.universityClass
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityClass:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="PRE">
														Hazırlık
													</option>
													<option value="ONE">
														1.Sınıf
													</option>
													<option value="TWO">
														2.Sınıf
													</option>
													<option value="THREE">
														3.Sınıf
													</option>
													<option value="FOUR">
														4.Sınıf
													</option>
													<option value="FIVE">
														5.Sınıf
													</option>
													<option value="SIX">
														6.Sınıf
													</option>
													<option value="GRADUATE">
														Mezun
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Başlangıç Yılı
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityStartDate &&
														context.modalData
															.educationInfoDTO
															?.universityStartDate
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityStartDate:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Üniversite Şehri
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityCity
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityCity.id
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityCity:
																			{
																				...context
																					?.modalData
																					?.educationInfoDTO
																					?.universityCity,
																				id: event
																					.target
																					.value,
																			},
																	},
															},
														});
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{dataProvinces?.length >
														0 &&
														dataProvinces.map(
															(province) => (
																<option
																	key={
																		province.id &&
																		province.id
																	}
																	value={
																		province.id &&
																		province.id
																	}
																>
																	{province.name &&
																		province.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Kaldığı Yer
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.educationInfoDTO
															?.accommodationStatus &&
														context.modalData
															.educationInfoDTO
															.accommodationStatus
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		accommodationStatus:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="HOME">
														Ev
													</option>
													<option value="FAMILY">
														Aile
													</option>
													<option value="RELATIVE">
														Akraba
													</option>
													<option value="GOVDORM">
														Devlet Yurdu
													</option>
													<option value="PRIVDORM">
														Özel Yurt
													</option>
													<option value="UNIDORM">
														Üniversite Yurdu
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Transkript Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-5"
								>
									<h5 className="text-orange mb-0">
										Transkript Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-5"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									{/* Genel */}
									<div className="mb-5">
										<h5 className="text-blue-light mb-4">
											Genel
										</h5>
										<div className="mb-3">
											<div className="row align-items-center">
												<div className="col-4">
													<h6 className="mb-0">
														Genel Ortalama
													</h6>
												</div>
												<div className="col-8">
													<input
														type="text"
														className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
														placeholder="Lütfen giriniz"
														value={
															context?.modalData
																?.transcriptDTO
																?.endOfYearAverage &&
															context.modalData
																.transcriptDTO
																.endOfYearAverage
														}
														onChange={(event) =>
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																	transcriptDTO:
																		{
																			...context
																				?.modalData
																				?.transcriptDTO,
																			endOfYearAverage:
																				event
																					.target
																					.value,
																		},
																},
															})
														}
													/>
												</div>
											</div>
										</div>
										<div className="mb-3">
											<div className="row align-items-center">
												<div className="col-4">
													<h6 className="mb-0">
														Genel Kalan Ders
													</h6>
												</div>
												<div className="col-8">
													<input
														type="text"
														className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
														placeholder="Lütfen giriniz"
														value={
															context?.modalData
																?.transcriptDTO
																?.endOfYearFailedLesson &&
															context.modalData
																.transcriptDTO
																.endOfYearFailedLesson
														}
														onChange={(event) =>
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																	transcriptDTO:
																		{
																			...context
																				?.modalData
																				?.transcriptDTO,
																			endOfYearFailedLesson:
																				event
																					.target
																					.value,
																		},
																},
															})
														}
													/>
												</div>
											</div>
										</div>
									</div>

									{/* Hazırlık */}
									{[
										'PRE',
										'ONE',
										'TWO',
										'THREE',
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												Hazırlık
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Hazırlık Okudu mu?
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.preparetoryInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.preparetoryInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				preparetoryInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																Evet
															</option>
															<option value="NO">
																Hayır
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Hazırlıktan Geçti
															mi?
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.preparetoryResultInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.preparetoryResultInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				preparetoryResultInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																Evet
															</option>
															<option value="NO">
																Hayır
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Hazırlık Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="text"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.preparetoryAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.preparetoryAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				preparetoryAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 1. Sınıf */}
									{[
										'ONE',
										'TWO',
										'THREE',
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												1. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															min={0}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.firstTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				firstTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.firstTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.secondTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.secondTermFailedLesson
															}
															onChange={(
																event
															) => {
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																});
															}}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.firstSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.firstSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.firstSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.firstYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.firstYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 2. Sınıf */}
									{[
										'TWO',
										'THREE',
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												2. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				thirdTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.secondSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.secondSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.secondSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.secondYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.secondYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 3. Sınıf */}
									{[
										'THREE',
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												3. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				fifthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 4. Sınıf */}
									{[
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												4. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.seventhTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.seventhTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				seventhTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.seventhTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.seventhTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				seventhTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.eighthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.eighthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				eighthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.eighthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.eighthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				eighthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 5. Sınıf */}
									{['FIVE', 'SIX', 'GRADUATE'].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												5. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.ninethTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.ninethTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				ninethTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.ninethTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.ninethTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				ninethTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.tenthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.tenthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				tenthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.tenthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.tenthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				tenthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 6. Sınıf */}
									{['SIX', 'GRADUATE'].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div>
											<h5 className="text-blue-light mb-4">
												6. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.eleventhTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.eleventhTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				eleventhTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.eleventhTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.eleventhTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				eleventhTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.twelfthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.twelfthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				twelfthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.twelfthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.twelfthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				twelfthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>

						{/* Belgeler */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-6"
								>
									<h5 className="text-orange mb-0">
										Belgeler
									</h5>
								</button>
							</h2>
							<div
								id="group-6"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<span className="fs-7 text-red mb-4 d-block">
										Dosyalar PDF formatında ve en fazla 4MB
										boyutunda olmalıdır.
									</span>
									<div className="mb-3">
										<div className="row align-items-start">
											<div className="col-4">
												<h6 className="mb-0">
													Öğrenci Belgesi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="file"
													accept="application/pdf"
													name="NewCertificateDocument"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																},
																NewCertificateDocument:
																	event.target
																		.files[0],
															});
														}
													}}
												/>

												<div className="row d-flex align-items-center">
													<div className="col-12 col-md-6">
														{context?.modalData
															?.certificateDocument &&
															!context.NewCertificateDocument && (
																<a
																	href={`https://${context.modalData.certificateDocument}`}
																	target="_blank"
																	rel="noreferrer"
																	className="my-3 text-decoration-none fs-7 text-green d-block"
																>
																	Öğrenci
																	Belgesi{' '}
																	<i
																		className="fas fa-check-circle ms-2"
																		style={{
																			width: 16,
																		}}
																	/>
																</a>
															)}
													</div>

													<div className="col-12 col-md-6">
														{context?.modalData
															?.certificateDocumentUpdateDate &&
															!context.NewCertificateDocument && (
																<p className="m-0 p-0">
																	{moment(
																		context
																			?.modalData
																			?.certificateDocumentUpdateDate
																	).format(
																		'DD.MM.YYYY HH:mm'
																	)}
																</p>
															)}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-start">
											<div className="col-4">
												<h6 className="mb-0">
													Transkript Belgesi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="file"
													accept="application/pdf"
													name="NewTranscriptDocument"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																},
																NewTranscriptDocument:
																	event.target
																		.files[0],
															});
														}
													}}
												/>
												<div className="row d-flex align-items-center">
													<div className="col-12 col-md-6">
														{context?.modalData
															?.transcriptDocumentList
															?.length > 0 &&
															context?.modalData?.transcriptDocumentList.map(
																(
																	item,
																	index
																) => (
																	<a
																		key={
																			index
																		}
																		href={`https://${item.baseAddress}`}
																		target="_blank"
																		rel="noreferrer"
																		className="my-3 text-decoration-none fs-7 text-green d-block"
																	>
																		Transkript
																		Belgesi{' '}
																		<i
																			className="fas fa-check-circle ms-2"
																			style={{
																				width: 16,
																			}}
																		/>
																	</a>
																)
															)}
													</div>

													<div className="col-12 col-md-6">
														{context?.modalData
															?.transcriptDocumentList
															?.length > 0 &&
															context?.modalData?.transcriptDocumentList.map(
																(
																	item,
																	index
																) => (
																	<p
																		className="m-0 p-0 my-3"
																		key={
																			index
																		}
																	>
																		{moment(
																			item.documentUpdateDate
																		).format(
																			'DD.MM.YYYY HH:mm'
																		)}
																	</p>
																)
															)}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-start">
											<div className="col-4">
												<h6 className="mb-0">
													STK Belgesi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="file"
													accept="application/pdf"
													name="NewStkDocument"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																},
																NewStkDocument:
																	event.target
																		.files[0],
															});
														}
													}}
												/>

												<div className="row d-flex align-items-center">
													<div className="col-12 col-md-6">
														{context?.modalData
															?.stkDocumentList
															?.length > 0 &&
															context?.modalData?.stkDocumentList.map(
																(
																	item,
																	itemIndex
																) => (
																	<div
																		key={
																			itemIndex
																		}
																		className="pt-3"
																	>
																		<input
																			type="text"
																			className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg text-decoration-none fs-7 d-inline-block w-[80%]"
																			value={
																				item.originalFileName
																			}
																			style={{
																				width: '80%',
																			}}
																			onChange={(
																				event
																			) => {
																				const updatedList =
																					context.modalData.stkDocumentList.map(
																						(
																							doc,
																							index
																						) => {
																							if (
																								index ===
																								itemIndex
																							) {
																								return {
																									...doc,
																									originalFileName:
																										event
																											.target
																											.value,
																								};
																							}
																							return doc;
																						}
																					);

																				setContext(
																					{
																						...context,
																						modalData:
																							{
																								...context.modalData,
																								stkDocumentList:
																									updatedList,
																							},
																					}
																				);
																			}}
																		/>

																		<a
																			key={
																				itemIndex
																			}
																			href={`https://${item.baseAddress}`}
																			target="_blank"
																			rel="noreferrer"
																			className="my-3 text-decoration-none fs-7 text-green inline-block"
																		>
																			<i
																				className="fas fa-link ms-2"
																				style={{
																					width: 16,
																				}}
																			/>
																		</a>
																	</div>
																)
															)}
													</div>

													<div className="col-12 col-md-6">
														{context?.modalData
															?.stkDocumentList
															?.length > 0 &&
															context?.modalData?.stkDocumentList.map(
																(
																	item,
																	index
																) => (
																	<p
																		className="m-0 p-0 py-0 mt-3 fs-7"
																		key={
																			index
																		}
																		style={{
																			minHeight:
																				'48px',
																			display:
																				'flex',
																			alignItems:
																				'center',
																		}}
																	>
																		{moment(
																			item.documentUpdateDate
																		).format(
																			'DD.MM.YYYY HH:mm'
																		)}
																	</p>
																)
															)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İlgi Alanları */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-7"
								>
									<h5 className="text-orange mb-0">
										İlgi Alanları
									</h5>
								</button>
							</h2>
							<div
								id="group-7"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-12">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.interest &&
														context.modalData
															.interest
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																interest:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Mentor */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-8"
								>
									<h5 className="text-orange mb-0">Mentor</h5>
								</button>
							</h2>
							<div
								id="group-8"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="container">
										{context?.modalData?.matchedUser ? (
											<div className="row g-3 mb-3">
												<div className="col-xl-6 col-lg-6 col-md-6">
													<a
														className="d-block bg-gray-lightest rounded-2 p-4 text-reset
															text-decoration-none h-100 position-relative overflow-hidden"
														onClick={(event) =>
															event.preventDefault()
														}
													>
														<div className="d-flex align-items-center">
															{context?.modalData
																?.matchedUser
																?.profileImage ? (
																<div
																	className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
																	style={{
																		width: 80,
																		height: 80,
																		zIndex: 1,
																	}}
																>
																	<img
																		src={`https://${context.modalData.matchedUser.profileImage}`}
																		alt={
																			context
																				.modalData
																				.matchedUser
																				.name &&
																			context
																				.modalData
																				.matchedUser
																				.surname
																				? context
																						.modalData
																						.matchedUser
																						.name +
																				  context
																						.modalData
																						.matchedUser
																						.surname
																				: 'Avatar'
																		}
																		className="rounded-circle"
																		style={{
																			width: 80,
																			height: 80,
																		}}
																	/>
																</div>
															) : (
																<>
																	{context
																		.modalData
																		.matchedUser
																		.name &&
																		context
																			.modalData
																			.matchedUser
																			.surname && (
																			<div
																				className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
																				style={{
																					width: 80,
																					height: 80,
																					cursor: 'pointer',
																				}}
																			>
																				{context.modalData.matchedUser.name.substring(
																					0,
																					1
																				) +
																					context.modalData.matchedUser.surname.substring(
																						0,
																						1
																					)}
																			</div>
																		)}
																</>
															)}

															<div className="flex-grow-1">
																<h5 className="mb-0 fs-6 text-blue text-truncate w-75">
																	{
																		context
																			?.modalData
																			?.matchedUser
																			.name
																	}{' '}
																	{
																		context
																			?.modalData
																			?.matchedUser
																			.surname
																	}
																</h5>
																<span className="fs-8 text-gray-light">
																	{
																		context
																			?.modalData
																			?.matchedUser
																			.birthday
																	}
																</span>
															</div>
														</div>
													</a>
												</div>
											</div>
										) : (
											<div className="flex-fill bg-gray-lightest rounded-2 d-flex flex-column justify-content-center align-items-center text-green text-center p-5">
												<i className="fas fa-chalkboard-teacher h1 mb-3 fw-bold" />

												{context?.modalData
													?.oldMentorDTOList?.length >
												0 ? (
													<h5 className="mb-0 lh-base">
														Aktif bir mentorunuz{' '}
														<br />
														bulunmuyor.
													</h5>
												) : (
													<h5 className="mb-0 lh-base">
														Henüz bir mentorunuz{' '}
														<br />
														bulunmuyor.
													</h5>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						{/* Eski Mentorlar */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-9"
								>
									<h5 className="text-orange mb-0">
										Eski Mentorlar
									</h5>
								</button>
							</h2>
							<div
								id="group-9"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="container">
										{context?.modalData
											?.oldMentorDTOList ? (
											<div className="row g-3">
												{context?.modalData?.oldMentorDTOList?.map(
													(item) => (
														<div
															className="col-xl-6 col-lg-6 col-md-6"
															key={item.id}
														>
															<a
																className="d-block bg-gray-lightest rounded-2 p-4 text-reset
										text-decoration-none h-100 position-relative overflow-hidden"
																onClick={(
																	event
																) =>
																	event.preventDefault()
																}
															>
																<div className="d-flex align-items-center">
																	{item?.profileImage ? (
																		<div
																			className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
																			style={{
																				width: 80,
																				height: 80,
																				zIndex: 1,
																			}}
																		>
																			<img
																				src={`https://${item.profileImage}`}
																				alt={
																					item.name &&
																					item.surname
																						? item.name +
																						  item.surname
																						: 'Avatar'
																				}
																				className="rounded-circle"
																				style={{
																					width: 80,
																					height: 80,
																				}}
																			/>
																		</div>
																	) : (
																		<>
																			{item.name &&
																				item.surname && (
																					<div
																						className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
																						style={{
																							width: 80,
																							height: 80,
																							cursor: 'pointer',
																						}}
																					>
																						{item.name.substring(
																							0,
																							1
																						) +
																							item.surname.substring(
																								0,
																								1
																							)}
																					</div>
																				)}
																		</>
																	)}
																	<div className="flex-grow-1">
																		<h5 className="mb-0 fs-6 text-blue text-truncate w-75">
																			{
																				item.name
																			}{' '}
																			{
																				item.surname
																			}
																		</h5>
																		<span className="fs-8 text-gray-light">
																			{
																				item.birthday
																			}
																		</span>
																	</div>
																</div>
															</a>
														</div>
													)
												)}
											</div>
										) : (
											<div className="flex-fill bg-gray-lightest rounded-2 d-flex flex-column justify-content-center align-items-center text-green text-center p-5">
												<i className="fas fa-chalkboard-teacher h1 mb-3 fw-bold" />

												{context?.modalData
													?.oldMentorDTOList?.length >
												0 ? (
													<h5 className="mb-0 lh-base">
														Aktif bir mentorunuz{' '}
														<br />
														bulunmuyor.
													</h5>
												) : (
													<h5 className="mb-0 lh-base">
														Henüz bir mentorunuz{' '}
														<br />
														bulunmuyor.
													</h5>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						{/* Diğer Bilgiler  */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-0"
								>
									<h5 className="text-orange mb-0">
										Diğer Bilgiler
									</h5>
								</button>
							</h2>
							<div
								id="group-0"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Durum</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.menteeType &&
														context.modalData
															.menteeType
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																menteeType:
																	event.target
																		.value,
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="ACTIVEMENTEE">
														Aktif Öğrenci
													</option>
													<option value="PASSIVEMENTEE">
														Pasif Öğrenci
													</option>
													<option value="HANGING">
														Askıda
													</option>
													<option value="CONTINUING">
														Devam Eden
													</option>
													<option value="CUTOFF">
														Kesildi
													</option>
													<option value="GRADUATE">
														Mezun
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Program
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.programDTO?.id &&
														context.modalData
															.programDTO.id
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																programDTO: {
																	...context.modalData,
																	id: event
																		.target
																		.value,
																},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context?.dataProgramList &&
														context.dataProgramList &&
														context.dataProgramList
															.length > 0 &&
														context.dataProgramList.map(
															(program) => (
																<option
																	key={
																		program?.id
																	}
																	value={
																		program?.id
																	}
																>
																	{
																		program?.name
																	}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Mentor</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.matchedUser?.id &&
														context.modalData
															.matchedUser.id
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																matchedUser: {
																	...context
																		?.modalData
																		?.matchedUser,
																	id: event
																		.target
																		.value,
																},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context?.dataMentorList &&
														context.dataMentorList &&
														context.dataMentorList
															.length > 0 &&
														context.dataMentorList
															.filter(
																(mentor) =>
																	mentor.mentorStatus ===
																	'ACTIVE'
															)
															.map((mentor) => (
																<option
																	key={
																		mentor.id
																	}
																	value={
																		mentor.id
																	}
																>
																	{`${mentor.name} ${mentor.surname}`}
																</option>
															))}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Not</h6>
											</div>
											<div className="col-8">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.menteeNote &&
														context.modalData
															.menteeNote
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																menteeNote:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn bg-white text-orange border border-2 border-orange text-white rounded-pill px-4 py-2 me-2"
						onClick={(e) => handleActivation(e, 'mail')}
					>
						<i className="fas fa-envelope me-2" /> Mail
					</button>
					<button
						type="button"
						className="btn bg-white text-orange border border-2 border-orange text-white rounded-pill px-4 py-2 me-2"
						onClick={(e) => handleActivation(e, 'sms')}
					>
						<i className="fas fa-sms me-2" /> SMS
					</button>
					<button
						type="button"
						className="btn bg-white text-orange border border-2 border-orange text-white rounded-pill px-4 py-2 me-2"
						onClick={() =>
							navigate('/admin/iletisim', {
								state: {
									receiver: {
										id:
											context?.modalData?.id &&
											context.modalData.id,
										name:
											context?.modalData?.name &&
											context.modalData.name,
										surname:
											context?.modalData?.surname &&
											context.modalData.surname,
									},
								},
							})
						}
					>
						<i className="fas fa-envelope me-2" /> Mesaj Gönder
					</button>
					<button
						type="button"
						className="btn btn-orange border border-2 border-orange text-white rounded-pill px-4 py-2"
						onClick={(event) => handleSaveClick(event)}
					>
						Güncelle <i className="fas fa-arrow-right ms-2" />
					</button>
				</div>
			</Modal>
			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminMentees;
