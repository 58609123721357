/* eslint-disable react/no-array-index-key */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../stores/Context/Context';
import constants from '../../constants';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import apiService from '../../services/apiService';

const Mentees = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		showLoading: false,
		showModal: false,
	});
	const [modalList, setModalList] = React.useState(null);
	const navigate = useNavigate();
	const [universities, setUniversities] = React.useState(null);
	const [faculties, setFaculties] = React.useState(null);
	const [departments, setDepartments] = React.useState(null);
	const [activeProfile, setActiveProfile] = React.useState(null);

	const [customModalData, setCustomModalData] = React.useState(null);

	// Department
	const getUniversityDepartment = (facultyId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${facultyId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setDepartments(response.data);
				} else {
					setDepartments('');

					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	// Faculty
	const getUniversityFaculty = (universityId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getFacultyAll/${universityId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								universityDTO: {
									...context?.modalData?.educationInfoDTO
										?.universityDTO,
									id: universityId,
								},
							},
						},
						universityFacultyList: response.data,
						universityDepartmentList: undefined,
					});

					setFaculties(response.data);
					setDepartments('');
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setFaculties('');
					setDepartments('');
				}
			});
	};

	// Universities
	const getUniversities = () => {
		// Loader
		setState({ ...state, showLoading: true });

		// Get Universities
		fetch(`${constants.API_BASE_URL}/public/getUniversityNameAll`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((university) => university.json())
			.then((university) => {
				if (university.data) {
					setState({
						...state,
						showLoading: false,
					});

					setUniversities(university.data);
				} else {
					setUniversities('');
				}
			});
	};

	// Faculty & Department
	const getUniversityEditItems = (universityId, facultyId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getFacultyAll/${universityId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								universityDTO: {
									...context?.modalData?.educationInfoDTO
										?.universityDTO,
									id: universityId,
								},
							},
						},
						dataUser: customModalData,
						universityFacultyList: response.data,
						universityDepartmentList: undefined,
					});

					setFaculties(response.data);
					setDepartments('');

					fetch(
						`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${facultyId}`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${context.dataLogin.token}`,
							},
						}
					)
						.then((responseDepartment) => responseDepartment.json())
						.then((responseDepartment) => {
							if (responseDepartment.data) {
								setState({
									...state,
									showLoading: false,
								});

								setDepartments(responseDepartment.data);
							} else {
								setDepartments('');

								setState({
									...state,
									showLoading: false,
								});
							}
						});
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setFaculties('');
					setDepartments('');
				}
			});
	};

	// Get User By Id
	const getUserById = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/getUser/${id}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					setState({
						...state,
						showLoading: false,
						modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
					});

					setCustomModalData(response.data);

					setContext({
						...context,
						showModal: true,
						modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
						modalData: response?.data,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	const handleGetAllUniversityFaculty = (e, universityId) => {
		if (universityId !== '') {
			getUniversityFaculty(universityId);
		} else {
			setContext({
				...context,
				universityFacultyList: undefined,
				universityDepartmentList: undefined,
			});
		}
	};

	const handleGetAllUniversityDepartment = (e, facultyId) => {
		e.preventDefault();

		if (facultyId !== '') {
			getUniversityDepartment(facultyId);
		} else {
			setContext({
				...context,
				universityDepartmentList: undefined,
			});
		}
	};

	// Handle Show Profile - Open Modal
	const handleShowProfile = (event, id) => {
		event.preventDefault();

		setActiveProfile(id);
		getUserById(id);
	};

	const handleGetUserData = async () => {
		try {
			await apiService.getLoginUserData(context, setContext);
			// Handle the rest of the login logic
		} catch (error) {
			// Handle errors
		}
	};

	// useEffect
	React.useEffect(() => {
		// ALL UNIVERSITIES - Trigger
		getUniversities();

		// Get Login User Data
		handleGetUserData();
	}, []);

	React.useEffect(() => {
		if (
			context?.dataUser?.menteeDTOList?.length > 0 &&
			context?.dataUser?.oldMenteeDTOList?.length > 0
		) {
			const mergedArray = [
				...context?.dataUser?.menteeDTOList,
				...context?.dataUser?.oldMenteeDTOList.filter(
					(itemOld) =>
						!context?.dataUser?.menteeDTOList.some(
							(itemMentee) => itemMentee.id === itemOld.id
						)
				),
			];
			setModalList(mergedArray);
		} else if (context?.dataUser?.menteeDTOList?.length > 0) {
			setModalList(context?.dataUser?.menteeDTOList);
		} else if (context?.dataUser?.oldMenteeDTOList?.length > 0) {
			setModalList(context?.dataUser?.menteeDTOList);
		} else {
			setModalList(null);
		}
	}, [context]);

	React.useEffect(() => {
		if (customModalData) {
			if (activeProfile) {
				if (context?.dataUser?.menteeDTOList) {
					customModalData.menteeDTOList
						.filter((item) => item.id === activeProfile)
						.forEach((element) => {
							if (
								element?.educationInfoDTO?.universityDTO?.id &&
								element?.educationInfoDTO?.faculty?.id
							) {
								getUniversityEditItems(
									element.educationInfoDTO.universityDTO.id,
									element.educationInfoDTO.faculty.id
								);
							}
						});
				}
			}
		}
	}, [customModalData]);

	return (
		<main>
			<div className="container">
				<div className="d-flex align-items-center mb-5">
					<h1 className="mb-0 text-blue-light">Öğrenciler</h1>
				</div>
				{context?.dataUser?.menteeDTOList ? (
					<div className="row g-3">
						{context?.dataUser?.menteeDTOList?.map(
							(item, index) => (
								<div
									className="col-xl-3 col-lg-4 col-md-6"
									key={index}
								>
									<a
										className="d-block bg-gray-lightest rounded-2 p-4 text-reset
									text-decoration-none h-100 position-relative overflow-hidden"
										onClick={(event) =>
											handleShowProfile(event, item.id)
										}
									>
										<div className="d-flex align-items-center">
											{item?.profileImage ? (
												<div
													className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
													style={{
														width: 80,
														height: 80,
														zIndex: 1,
													}}
												>
													<img
														src={`https://${item.profileImage}`}
														alt={
															item.name &&
															item.surname
																? item.name +
																  item.surname
																: 'Avatar'
														}
														className="rounded-circle"
														style={{
															width: 80,
															height: 80,
														}}
													/>
												</div>
											) : (
												<>
													{item.name && item.surname && (
														<div
															className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
															style={{
																width: 80,
																height: 80,
																cursor: 'pointer',
															}}
														>
															{item.name.substring(
																0,
																1
															) +
																item.surname.substring(
																	0,
																	1
																)}
														</div>
													)}
												</>
											)}
											<div className="flex-grow-1">
												<h5 className="mb-0 fs-6 text-blue text-truncate w-75">
													{item.name} {item.surname}
												</h5>
												<span className="fs-8 text-gray-light">
													{item.birthday}
												</span>
											</div>
										</div>
									</a>
								</div>
							)
						)}
					</div>
				) : (
					<div className="flex-fill bg-gray-lightest rounded-2 d-flex flex-column justify-content-center align-items-center text-green text-center p-5">
						<i className="fas fa-chalkboard-teacher h1 mb-3 fw-bold" />

						{context?.dataUser?.oldMenteeDTOList?.length > 0 ? (
							<h5 className="mb-0 lh-base">
								Aktif bir öğrenciniz <br />
								bulunmuyor.
							</h5>
						) : (
							<h5 className="mb-0 lh-base">
								Henüz bir öğrenciniz <br />
								bulunmuyor.
							</h5>
						)}
					</div>
				)}

				{context?.dataUser?.oldMenteeDTOList && (
					<>
						<div className="d-flex align-items-center mt-5 mb-5">
							<h1 className="mb-0 text-blue-light">
								Eski Öğrenciler
							</h1>
						</div>

						<div className="row g-3 grayscale">
							{context?.dataUser?.oldMenteeDTOList?.map(
								(item, index) => (
									<div
										className="col-xl-3 col-lg-4 col-md-6"
										key={index}
									>
										<a
											className="d-block bg-gray-lightest rounded-2 p-4 text-reset
									text-decoration-none h-100 position-relative overflow-hidden"
											onClick={(event) =>
												handleShowProfile(
													event,
													item.id
												)
											}
										>
											<div className="d-flex align-items-center">
												{item?.profileImage ? (
													<div
														className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
														style={{
															width: 80,
															height: 80,
															zIndex: 1,
														}}
													>
														<img
															src={`https://${item.profileImage}`}
															alt={
																item.name &&
																item.surname
																	? item.name +
																	  item.surname
																	: 'Avatar'
															}
															className="rounded-circle"
															style={{
																width: 80,
																height: 80,
															}}
														/>
													</div>
												) : (
													<>
														{item.name &&
															item.surname && (
																<div
																	className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
																	style={{
																		width: 80,
																		height: 80,
																		cursor: 'pointer',
																	}}
																>
																	{item.name.substring(
																		0,
																		1
																	) +
																		item.surname.substring(
																			0,
																			1
																		)}
																</div>
															)}
													</>
												)}
												<div className="flex-grow-1">
													<h5 className="mb-0 fs-6 text-blue text-truncate w-75">
														{item.name}{' '}
														{item.surname}
													</h5>
													<span className="fs-8 text-gray-light">
														{item.birthday}
													</span>
												</div>
											</div>
										</a>
									</div>
								)
							)}
						</div>
					</>
				)}
			</div>

			{modalList &&
				modalList.length > 0 &&
				modalList
					.filter((item) => item.id === activeProfile)
					.map((item, index) => (
						<Modal
							key={index}
							showModal={context.showModal}
							onClose={() =>
								setContext({
									...context,
									showModal: false,
									modalDataHeader: undefined,
									modalData: null,
								})
							}
							header={
								item.name && item.surname
									? `${item.name} ${item.surname}`
									: 'Öğrenciler'
							}
							size="lg"
						>
							<style>
								{`
						.accordion input, .accordion select, .accordion textarea {
							border: none !important;
							pointer-events: none;
							background: none !important;
							appearance: none;
						}
						.accordion-body > div {
							border-bottom: 2px solid #FAFAFA;
							padding-bottom: 8px;
							margin-bottom: 8px !important;
						}
						`}
							</style>

							<div className="modal-body p-4">
								<div
									className="accordion"
									id="accordionProfile"
								>
									{/* Kişisel Bilgiler  */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-1"
											>
												<h5 className="text-orange mb-0">
													Kişisel Bilgiler
												</h5>
											</button>
										</h2>
										<div
											id="group-1"
											className="accordion-collapse collapse show"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												{context?.modalData
													?.profileImage && (
													<div className="mb-3">
														<div className="row align-items-center">
															<div className="col-4">
																<h6 className="mb-0">
																	Avatar
																</h6>
															</div>
															<div className="col-8">
																<img
																	src={`https://${item.profileImage}`}
																	className="rounded-circle"
																	alt="Aktif Avatar"
																	style={{
																		width: '120px',
																		height: '120px',
																	}}
																/>
															</div>
														</div>
													</div>
												)}

												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																İsim
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	item.name &&
																	item.name
																}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Soyisim
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	item.surname &&
																	item.surname
																}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Doğum Tarihi
															</h6>
														</div>
														<div className="col-8">
															<input
																type="date"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																value={
																	item.birthday &&
																	item.birthday
																}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Cinsiyet
															</h6>
														</div>
														<div className="col-8">
															<select
																value={
																	item.gender &&
																	item.gender
																}
																className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
																disabled
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																<option value="MAN">
																	Erkek
																</option>
																<option value="WOMAN">
																	Kadın
																</option>
															</select>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* İletişim Bilgileri */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-2"
											>
												<h5 className="text-orange mb-0">
													İletişim Bilgileri
												</h5>
											</button>
										</h2>
										<div
											id="group-2"
											className="accordion-collapse collapse"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Telefon Numarası
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	item.phoneNumber &&
																	item.phoneNumber
																}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																E-Posta Adresi
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	item.email &&
																	item.email
																}
																disabled
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* Eğitim Bilgileri */}
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed bg-transparent p-4"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#group-3"
											>
												<h5 className="text-orange mb-0">
													Eğitim Bilgileri
												</h5>
											</button>
										</h2>
										<div
											id="group-3"
											className="accordion-collapse collapse"
											data-bs-parent="#accordionProfile"
										>
											<div className="accordion-body p-4">
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Lise Adı
															</h6>
														</div>
														<div className="col-8">
															<input
																type="text"
																className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
																placeholder="Lütfen giriniz"
																value={
																	item
																		?.educationInfoDTO
																		?.highSchoolName &&
																	item
																		.educationInfoDTO
																		.highSchoolName
																}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Üniversite Adı
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2
																border-gray-lighter py-2 px-3 lh-lg"
																value={
																	item
																		?.educationInfoDTO
																		?.universityDTO
																		?.id &&
																	item
																		.educationInfoDTO
																		.universityDTO
																		.id
																}
																onChange={(
																	event
																) => {
																	handleGetAllUniversityFaculty(
																		event,
																		event
																			.target
																			.value
																	);
																}}
																disabled
															>
																<option value="">
																	Lütfen
																	seçiniz
																</option>
																{universities &&
																	universities.length >
																		0 &&
																	universities.map(
																		(
																			university
																		) => (
																			<option
																				key={
																					university.id &&
																					university.id
																				}
																				value={
																					university.id &&
																					university.id
																				}
																			>
																				{university.name &&
																					university.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Fakülte
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2
																border-gray-lighter py-2 px-3 lh-lg"
																value={
																	item
																		?.educationInfoDTO
																		?.faculty
																		?.id &&
																	item
																		.educationInfoDTO
																		.faculty
																		.id
																}
																onChange={(
																	event
																) => {
																	handleGetAllUniversityDepartment(
																		event,
																		event
																			.target
																			.value
																	);
																}}
																disabled
															>
																{faculties &&
																	faculties.length >
																		0 &&
																	faculties.map(
																		(
																			university
																		) => (
																			<option
																				key={
																					university.id &&
																					university.id
																				}
																				value={
																					university.id &&
																					university.id
																				}
																			>
																				{university.name &&
																					university.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="row align-items-center">
														<div className="col-4">
															<h6 className="mb-0">
																Bölüm
															</h6>
														</div>
														<div className="col-8">
															<select
																className="form-select border border-2
																border-gray-lighter py-2 px-3 lh-lg"
																value={
																	item
																		?.educationInfoDTO
																		?.universityDepartment
																		?.id &&
																	item
																		.educationInfoDTO
																		.universityDepartment
																		.id
																}
																disabled
															>
																{departments &&
																	departments.length >
																		0 &&
																	departments.map(
																		(
																			department
																		) => (
																			<option
																				key={
																					department.id &&
																					department.id
																				}
																				value={
																					department.id &&
																					department.id
																				}
																			>
																				{department.name &&
																					department.name}
																			</option>
																		)
																	)}
															</select>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer justify-content-center">
									<button
										type="button"
										className="btn bg-white text-orange border border-2 border-orange text-white rounded-pill px-4 py-2 me-2"
										onClick={() =>
											navigate('/iletisim', {
												state: {
													receiver: {
														id: item.id && item.id,
														name:
															item.name &&
															item.name,
														surname:
															item.surname &&
															item.surname,
													},
												},
											})
										}
									>
										<i className="fas fa-envelope me-2" />{' '}
										Mesaj Gönder
									</button>
								</div>
							</div>
						</Modal>
					))}

			<Loading showLoading={state.showLoading} />
		</main>
	);
};

export default Mentees;
