import React from 'react';

import { Tooltip } from 'bootstrap';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

const AdminContentsUniversities = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		header: 'Üniversiteler',
		name: '',
	});

	const [universities, setUniversities] = React.useState(null);
	const [faculties, setFaculties] = React.useState(null);
	const [departments, setDepartments] = React.useState(null);

	// Default University Informations
	const [activeUniversity, setActiveUniversity] = React.useState(1);
	const [activeFaculty, setActiveFaculty] = React.useState(1);
	const [updateState, setUpdateState] = React.useState(null);

	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/getUniversityNameAll`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					fetch(
						`${constants.API_BASE_URL}/public/getFacultyAll/${activeUniversity}`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${context.dataLogin.token}`,
							},
						}
					)
						.then((responseFaculty) => responseFaculty.json())
						.then((responseFaculty) => {
							if (responseFaculty?.data) {
								fetch(
									`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${activeFaculty}`,
									{
										method: 'GET',
										headers: {
											'Content-Type': 'application/json',
											Authorization: `Bearer ${context.dataLogin.token}`,
										},
									}
								)
									.then((responseDep) => responseDep.json())
									.then((responseDep) => {
										if (responseDep?.data) {
											setUniversities(response.data);
											setFaculties(responseFaculty.data);
											setDepartments(responseDep.data);
											setState({
												...state,
												showLoading: false,
											});
										} else {
											setDepartments(null);
											setState({
												...state,
												showLoading: false,
											});
										}
									})
									.catch((error) => {
										setState({
											...state,
											toast: {
												type: 'error',
												message: error.message,
											},
											showLoading: false,
										});
									});
							} else {
								setState({
									...state,
									showLoading: false,
								});

								setFaculties(null);
								setDepartments(null);
							}
						})
						.catch((error) => {
							setState({
								...state,
								toast: {
									type: 'error',
									message: error.message,
								},
								showLoading: false,
							});
						});
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setFaculties(null);
					setDepartments(null);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	}, [updateState]);

	React.useEffect(() => {
		/* eslint-disable */
		const tooltipTriggerList = document.querySelectorAll(
			'[data-bs-toggle="tooltip"]'
		);
		const tooltipList = [...tooltipTriggerList].map(
			(tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
		);
		/* eslint-enable */
	}, [state]);

	// University
	const handleEditClickUniversity = (e, item) => {
		e.preventDefault();
		if (item) {
			setState({
				...state,
				showModalUniversity: true,
				header: `#${item.id} Düzenle`,
				universityId: item.id,
				universityName: item.name,
			});
		} else {
			setState({
				...state,
				showModalUniversity: true,
				header: 'Yeni',
				universityId: undefined,
				universityName: '',
			});
		}
	};

	const handleRemoveClickUniversity = (e, id) => {
		e.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/deleteUniversity`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Üniversite silindi.',
						},
					});

					setUpdateState(new Date());
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.message,
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleSaveClickUniversity = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/setUniversity`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...(state.universityId && { id: state.universityId }),
				name: state.universityName,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModalUniversity: false,
						toast: {
							type: 'success',
							message: 'Üniversite kaydedildi.',
						},
					});

					setUpdateState(new Date());
				} else {
					setState({
						...state,
						showLoading: false,
						showModalUniversity: false,
						toast: {
							type: 'error',
							message: response.message,
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					showModalUniversity: false,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// Faculty
	const handleEditClickFaculty = (e, item) => {
		e.preventDefault();
		if (item) {
			setState({
				...state,
				showModalFaculty: true,
				header: `#${item.id} Düzenle`,
				facultyId: item.id,
				facultyName: item.name,
			});
		} else {
			setState({
				...state,
				showModalFaculty: true,
				header: 'Yeni',
				facultyName: '',
			});
		}
	};

	const handleRemoveClickFaculty = (e, id) => {
		e.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/deleteFaculty`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Fakülte silindi.',
						},
					});
					setUpdateState(new Date());
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.message,
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleSaveClickFaculty = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/setFaculty`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...(state.facultyId && { id: state.facultyId }),
				name: state.facultyName,
				universityDTO: {
					id: activeUniversity,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModalFaculty: false,
						toast: {
							type: 'success',
							message: 'Fakülte kaydedildi.',
						},
					});
					setUpdateState(new Date());
				} else {
					setState({
						...state,
						showLoading: false,
						showModalFaculty: false,
						toast: {
							type: 'error',
							message: response.message,
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					showModalFaculty: false,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// Department
	const handleEditClickDepartment = (e, item) => {
		e.preventDefault();

		if (item) {
			setState({
				...state,
				showModalDepartment: true,
				header: `#${item.id} Düzenle`,
				departmentId: item.id,
				departmentName: item.name,
			});
		} else {
			setState({
				...state,
				showModalDepartment: true,
				header: 'Yeni',
				departmentName: '',
			});
		}
	};

	const handleSaveClickDepartment = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/setUniversityDepartment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...(state.departmentId && { id: state.departmentId }),
				name: state.departmentName,
				universityDTO: {
					id: activeUniversity,
				},
				facultyDTO: {
					id: activeFaculty,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModalDepartment: false,
						toast: {
							type: 'success',
							message: 'Departman kaydedildi.',
						},
					});
					setUpdateState(new Date());
				} else {
					setState({
						...state,
						showLoading: false,
						showModalDepartment: false,
						toast: {
							type: 'error',
							message: response.message,
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					showModalDepartment: false,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleRemoveClickDepartment = (e, id) => {
		e.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/deleteUniversityDepartment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Bölüm silindi.',
						},
					});
					setUpdateState(new Date());
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.message,
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};
	// Change University
	const handleClickChangeUniversity = (e, item) => {
		e.preventDefault();
		setActiveUniversity(item.id);

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/getFacultyAll/${item.id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setFaculties(response.data);
					setActiveFaculty(response.data[0]?.id);
					fetch(
						`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${response.data[0]?.id}`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${context.dataLogin.token}`,
							},
						}
					)
						.then((responseDep) => responseDep.json())
						.then((responseDep) => {
							if (responseDep.data) {
								setState({
									...state,
									showLoading: false,
								});

								setFaculties(response.data);
								setDepartments(responseDep.data);
							} else {
								setState({
									...state,
									showLoading: false,
								});

								setDepartments(null);
							}
						})
						.catch((error) => {
							setState({
								...state,
								toast: {
									type: 'error',
									message:
										error.message === 'Failed to fetch'
											? 'Sunucu ile bağlantı kurulamadı.'
											: error.message,
								},
								showLoading: false,
							});
						});
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setFaculties(null);
					setDepartments(null);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	// Change Faculty
	const handleClickChangeFaculty = (e, item) => {
		e.preventDefault();
		setActiveFaculty(item.id);

		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${item.id}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setDepartments(response.data);
				} else {
					setDepartments(null);

					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<div className="university-page">
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-4">
						<div className="wrapper-university-header">
							<h3>Üniversiteler</h3>
							<button
								type="button"
								className="btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 btn-orange text-white"
								onClick={(e) => handleEditClickUniversity(e)}
							>
								Ekle
								<i className="fas fa-plus ms-2" />
							</button>
						</div>

						<div className="wrapper-university-content rounded-2 mb-lg-0 mb-5">
							<ul>
								{universities &&
									universities.length > 0 &&
									// eslint-disable-next-line array-callback-return
									universities.map((item) => (
										<li
											key={item.id}
											className={
												activeUniversity === item.id
													? 'active'
													: ''
											}
										>
											<a
												className="text-gray fw-medium"
												href="#"
												onClick={(e) =>
													handleClickChangeUniversity(
														e,
														item
													)
												}
											>
												{item.name}
											</a>

											<div>
												<a
													className="action text-gray fw-medium"
													href="#"
													onClick={(e) =>
														handleEditClickUniversity(
															e,
															item
														)
													}
												>
													<i className="fas fa-edit ms-2" />
												</a>

												<a
													className="action text-gray fw-medium"
													href="#"
													onClick={(e) =>
														handleRemoveClickUniversity(
															e,
															item.id
														)
													}
												>
													<i className="fas fa-trash ms-2" />
												</a>
											</div>
										</li>
									))}
							</ul>
						</div>
					</div>

					<div className="col-12 col-md-4">
						<div className="wrapper-university-header">
							<h3>Fakülteler</h3>
							<button
								type="button"
								className="btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 btn-orange text-white"
								onClick={(e) => handleEditClickFaculty(e)}
							>
								Ekle
								<i className="fas fa-plus ms-2" />
							</button>
						</div>

						<div className="wrapper-university-content rounded-2 mb-lg-0 mb-5">
							<ul>
								{faculties &&
									faculties.length > 0 &&
									// eslint-disable-next-line array-callback-return
									faculties.map((item) => (
										<li
											key={item.id}
											className={
												activeFaculty === item.id
													? 'active'
													: ''
											}
										>
											<a
												className="text-gray fw-medium"
												href="#"
												onClick={(e) =>
													handleClickChangeFaculty(
														e,
														item
													)
												}
											>
												{item.name}
											</a>

											<div>
												<a
													className="action text-gray fw-medium"
													href="#"
													onClick={(e) =>
														handleEditClickFaculty(
															e,
															item
														)
													}
												>
													<i className="fas fa-edit ms-2" />
												</a>

												<a
													className="action text-gray fw-medium"
													href="#"
													onClick={(e) =>
														handleRemoveClickFaculty(
															e,
															item.id
														)
													}
												>
													<i className="fas fa-trash ms-2" />
												</a>
											</div>
										</li>
									))}
							</ul>
						</div>
					</div>

					<div className="col-12 col-md-4">
						<div className="wrapper-university-header">
							<h3>Bölümler</h3>
							<button
								type="button"
								className="btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 btn-orange text-white"
								onClick={(e) => handleEditClickDepartment(e)}
							>
								Ekle
								<i className="fas fa-plus ms-2" />
							</button>
						</div>
						<div className="wrapper-university-content rounded-2 mb-lg-0 mb-5">
							<ul>
								{departments &&
									departments.length > 0 &&
									// eslint-disable-next-line array-callback-return
									departments.map((item) => (
										<li key={item.id}>
											<a
												className="text-gray fw-medium"
												href="#"
												onClick={(e) =>
													handleEditClickDepartment(
														e,
														item
													)
												}
											>
												{item.name}
											</a>
											<div>
												<a
													className="action text-gray fw-medium"
													href="#"
													onClick={(e) =>
														handleEditClickDepartment(
															e,
															item
														)
													}
												>
													<i className="fas fa-edit ms-2" />
												</a>

												<a
													className="action text-gray fw-medium"
													href="#"
													onClick={(e) =>
														handleRemoveClickDepartment(
															e,
															item.id
														)
													}
												>
													<i className="fas fa-trash ms-2" />
												</a>
											</div>
										</li>
									))}
							</ul>
						</div>
					</div>
				</div>
			</div>

			{/* Üniversite Ekle */}
			<Modal
				showModal={state.showModalUniversity}
				onClose={() =>
					setState({
						...state,
						showModalUniversity: false,
						id: undefined,
						universityName: undefined,
					})
				}
				header={state.header}
			>
				<div className="modal-body p-4">
					<form>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Üniversite Adı
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 255 karakter uzunluğunda bir üniversite ismi giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="name"
									value={state.universityName}
									onChange={(event) => {
										setState({
											...state,
											universityName: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={255}
								/>
							</div>
						</div>
					</form>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() => handleSaveClickUniversity()}
					>
						Kaydet
					</button>
				</div>
			</Modal>

			{/* Fakülte Ekle */}
			<Modal
				showModal={state.showModalFaculty}
				onClose={() =>
					setState({
						...state,
						showModalFaculty: false,
						id: undefined,
						facultyName: undefined,
					})
				}
				header={state.header}
			>
				<div className="modal-body p-4">
					<form>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Fakülte Adı
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 255 karakter uzunluğunda bir fakülte adı giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="name"
									value={state.facultyName}
									onChange={(event) => {
										setState({
											...state,
											facultyName: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={255}
								/>
							</div>
						</div>
					</form>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() => handleSaveClickFaculty()}
					>
						Kaydet
					</button>
				</div>
			</Modal>

			{/* Bölüm Ekle */}
			<Modal
				showModal={state.showModalDepartment}
				onClose={() =>
					setState({
						...state,
						showModalDepartment: false,
						id: undefined,
						departmentName: undefined,
					})
				}
				header={state.header}
			>
				<div className="modal-body p-4">
					<form>
						<div className="row g-4 align-items-center mb-3">
							<div className="row g-4 align-items-center mb-3">
								<div className="col-5">
									<label className="col-form-label fw-medium">
										Bölüm Adı
										<i
											className="fas fa-question-circle ms-2 opacity-75"
											data-bs-toggle="tooltip"
											data-bs-title="En fazla 255 karakter uzunluğunda bir bölüm adı giriniz"
										/>
									</label>
								</div>
								<div className="col-7">
									<input
										type="text"
										name="name"
										value={state.departmentName}
										onChange={(event) => {
											setState({
												...state,
												departmentName:
													event.target.value,
											});
										}}
										className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
										required
										maxLength={255}
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() => handleSaveClickDepartment()}
					>
						Kaydet
					</button>
				</div>
			</Modal>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</div>
	);
};

export default AdminContentsUniversities;
